import React, { useState } from 'react';
import './AuditorAuditHistory.scss';
import { I18n } from 'react-redux-i18n';
import AuditHistoryTable from './components/AuditHistoryTable';
import BreadCrumbs from '../../../common/components/BreadCrumbs/BreadCrumbs';

const AuditorAuditHistory = ({ patientHistorial, patientDetail, role }) => {
    const brcrums = [
        {
            label: role === 'O' ? 'Auditoria' : I18n.t('auditor.brcrum.transactions'),
            link: role === 'O' ? '/auditTransactions' : '/auditorTransactions',
        },
        {
            label: patientDetail.paciente.nombre,
            link: role === 'O' ? '/auditTransactions' : '/auditIncident',
        },
        { label: I18n.t('auditor.incident.auditHistoryButton'), link: '/auditHistory' },
    ];

    return (
        <div className='i-container'>
            <BreadCrumbs links={brcrums} />
            <br />
            <AuditHistoryTable patientHistorial={patientHistorial} patientDetail={patientDetail} />
        </div>
    );
};

export default AuditorAuditHistory;
