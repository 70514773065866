import { connect } from 'react-redux';
import RocheTransactionsScreen from '../components/RocheTransactionsScreen/';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    transactions: state.transaction?.filteredResults,

    total: state.transaction.total,
    totalPages: state.transaction.totalPages,
    currentPage: state.transaction.current,

    allTransactions: state.transaction.results,
    filteredTransactionsBySearch: state.transaction.filteredResultsBySearch,

    loading: state.transaction.getTransactionsStatus.loading,
    downloadStatus: state.transaction.downloadTransactionsStatus,

    validatePaymentsStatus: state.transaction.validatePaymentsStatus,
    sendNexStatus: state.transaction.sendNexStatus,

    payStatus: state.transaction.payStatus,
    payResults: state.transaction.lastPayResults, //Lista de transacciones que fueron exitosas/errores
    trackPaymentsErrorsStatus: state.transaction.trackPaymentsErrorsStatus, //Lista de track Payments que fueron erronoeas/canceladas

    userIsPayer: state.profile.user.groups?.includes('pagadores'),
    userType: state.profile.user.type,
    userPrivateKey: state.profile.user.privateKey,

    walletDetails: state.wallet.data,
});

const mapDispatchToProps = (dispatch) => ({
    getTransactions: (filter, page, search, startDate, endDate, order) =>
        dispatch(
            actions.transaction.getTransactionsByFilters(
                filter,
                page,
                search,
                startDate,
                endDate,
                order,
            ),
        ),

    updateFilterValues: (filter) => dispatch(actions.transaction.updateFilterValues(filter)),
    getWalletDetails: (username) => dispatch(actions.wallet.getWalletDetails()),

    updatePage: (pageNumber) => dispatch(actions.transaction.updatePage(pageNumber)),
    updatePageSize: (pageSize) => dispatch(actions.transaction.updatePageSize(pageSize)),

    searchTransactions: (search) => dispatch(actions.transaction.searchTransactions(search)),

    updateFilteredTransactionsByDateRange: (newResults) =>
        dispatch(actions.transaction.updateFilteredTransactionsByDateRange(newResults)),
    clearDateRangeSelection: (textToSearch) =>
        dispatch(actions.transaction.clearDateRangeSelection(textToSearch)),

    downloadTransactions: (transactionType, sortedFilteredResults) =>
        dispatch(
            actions.transaction.downloadRocheTransactions(transactionType, sortedFilteredResults),
        ),

    validatePayments: (transactionsIds) =>
        dispatch(actions.transaction.validatePayments(transactionsIds)),

    pay: (transactions) => dispatch(actions.transaction.pay(transactions)),

    sendMultipleNex: (transactions) => dispatch(actions.transaction.sendMultipleNex(transactions)),

    trackPaymentsErrors: (transactionsIds) =>
        dispatch(actions.transaction.trackPaymentsErrors(transactionsIds)),

    clearUI: () => dispatch(actions.transaction.clearUI()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RocheTransactionsScreen);
