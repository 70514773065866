import React, { useState } from 'react';
import { zeroPad } from '../../../utils/various';

import Card from '../../../common/components/Card/Card';
import { IndStatusModal } from '../../../indication/components/IndicationsScreen/IndicationsScreen';

import Button from '../../../common/components/Button';

import BreadCrumbs from '../../../common/components/BreadCrumbs/BreadCrumbs';

import { connect } from 'react-redux';
import actions from '../../../actions';
import { UserTypes } from '../../../constants';

import { I18n } from 'react-redux-i18n';
import service from '../../patient.services';
import EvidencesTable from './EvidencesTable/EvidencesTable';
import './PatientEvidenceScreen.scss';
import '../EditPatientScreen/EditPatientScreen.scss';
import './PatientEvidenceScreen.scss';

export const ScreenDetails = ({ patient, contract, selectedVersion: _selectedVersion }) => {
    return (
        <>
            <div className='grey-row big-row'>
                <div className='grey-header'>{I18n.t('patient.evidence.patient.patient')}</div>
                <div className='grey-col'>
                    <div>{I18n.t('patient.evidence.patient.id')}</div>
                    <div>{patient?.id}</div>
                </div>
                <div className='grey-col'>
                    <div>{I18n.t('patient.evidence.patient.nya')}</div>
                    <div>{patient?.name}</div>
                </div>
                <div className='grey-col'>
                    <div>{I18n.t('patient.evidence.patient.dni')}</div>
                    <div>{patient?.dni}</div>
                </div>
                <div className='grey-col'>
                    <div>{I18n.t('patient.evidence.patient.affiliate')}</div>
                    <div>{patient?.affiliate}</div>
                </div>
                <div className='grey-col'>
                    <div>{I18n.t('patient.evidence.patient.sex')}</div>
                    <div>
                        {I18n.t('patient.editPatient.patientCard.sexes.' + patient?.sex ?? 'N')}
                    </div>
                </div>
                <div className='grey-col'>
                    <div>{I18n.t('patient.evidence.patient.birthDate')}</div>
                    {/* <div>{patient?.birthDate}</div> */}
                </div>
                <div className='grey-col'>
                    <div>{I18n.t('patient.evidence.patient.weight')}</div>
                    <div>{patient?.weight?.[patient.weight?.length - 1]?.peso} kg</div>
                </div>
            </div>
            <br />
            <div className='i-row'>
                <div className='grey-row'>
                    <div className='grey-header'>
                        Contrato: {zeroPad(contract.contrato, 3)} | {contract.descripcion}
                    </div>
                    <div className='grey-col'>{contract.descripcion_tipo}</div>
                    <div className='grey-col'>{contract.subtipo}</div>
                    <div className='grey-col'>{contract.indicacion_descr}</div>
                </div>
                <div className='btn-det ml1em'>
                    {I18n.t('editPatient.evidences.currentCycle')}: {contract.ciclo_actual}
                </div>
            </div>
        </>
    );
};

const PatientEvidenceScreen = ({
    patient,
    loadPatientData,
    editPatient,
    postPatient,
    editPatientStatus,
    contract: _contract,
    breadcrumbs,
    userType,
    privateKey,
    publicKey,
    getFileToUploadUrl,
    patientDetail,
    auditGroupData,
    getEvidenceType,
    evidenceTypes,
    clearPatientDetails,
}) => {
    const contract = {
        ..._contract,
        ciclo: _contract.evidencia[0]?.aplicacion ?? 0,
    };
    const [showState, setShowState] = useState(false);
    const [patientAux, setPatientAux] = useState(patient);

    React.useEffect(() => {
        getEvidenceType();
    }, []);

    React.useEffect(() => {
        if (userType === 'AUDITOR') {
            setPatientAux({
                ...patient,
                affiliate: patientDetail._original.paciente.afiliado,
                dni: patientDetail._original.paciente.dni,
                id: patientDetail._original.paciente.id,
                name: patientDetail._original.paciente.nombre,
                birthDate: patientDetail._original.paciente.fecha_nacimiento,
                sex: patientDetail._original.paciente.sexo,
                weight: [
                    {
                        effdt: null,
                        id: 1,
                        peso: patientDetail._original.paciente.peso?.toString(),
                        _id: 1,
                    },
                ],
                cse: patientDetail._original.paciente.cse,
                osId: patientDetail._original.obra_social.id,
                contracts: [
                    {
                        contrato: patientDetail._original.contrato.id,
                        descripcion: patientDetail._original.contrato.descripcion,
                        id: patientDetail._original.paciente_contrato,
                        indicacion_descr: patientDetail._original.contrato.indicacion_descr,
                        label: patientDetail._original.contrato.descripcion,
                        subtipo: patientDetail._original.contrato.indicacion_descr,
                        tipo_contrato: patientDetail._original.contrato.tipo_contrato,
                        _id: patientDetail._original.paciente_contrato,
                        evidencia: patientDetail._original.contrato.evidencia,
                    },
                ],
            });
        }
    }, [patientDetail]);

    const [evidences, setEvidences] = useState(contract.evidencia);

    const [disabled, setDisabled] = useState(false);

    //agrega las evidencias a la tabla
    const addEvidence = (ev) => {
        const newEvidence = {
            aplicacion:
                evidences?.reduce(
                    (prev, current) => (prev.numero > current.numero ? prev : current),
                    { numero: 0 },
                ).aplicacion + 1,
            requerida: false,
        };
        setEvidences([...evidences, newEvidence]);
        setDisabled(true);
    };

    const isEditable = userType === UserTypes.OS_USER || UserTypes.DRUGSTORE;

    const updatedPatient = () => {
        return {
            ...patientAux,
            contracts: patientAux?.contracts?.map((c) => {
                if (c.contrato === contract.contrato) {
                    return {
                        ...c,
                        evidencia: evidences,
                    };
                }
                return c;
            }),
        };
    };
    const lastWill = () => {
        if (!isEditable) return;
        loadPatientData(updatedPatient());
    };

    return (
        <div className='i-container'>
            <div className='bread-crum'>
                <BreadCrumbs
                    links={breadcrumbs?.map((b) => ({ ...b, lastWill }))}
                    extraLinks={[{ label: I18n.t('editPatient.brcm.evidences') }]}
                />
            </div>
            <Card title={I18n.t('patient.evidence.title')} className='side-padding-m'>
                <br />
                <ScreenDetails patient={patientAux} contract={contract} />
                <br />
                <br />
                <EvidencesTable
                    evidences={evidences}
                    setEvidences={setEvidences}
                    cycle={contract.ciclo_actual}
                    privateKey={privateKey}
                    publicKey={publicKey}
                    id={contract.id}
                    getFileToUploadUrl={getFileToUploadUrl}
                    patientDetail={patientDetail}
                    auditGroupData={auditGroupData}
                    userType={userType}
                    evidenceTypes={evidenceTypes}
                    patientCse={patientAux.cse}
                    disabled={setDisabled}
                />
                {userType !== 'AUDITOR' && (
                    <div className='wrapper-accessLink'>
                        <div
                            className={`card-rlink ${disabled ? 'disabled' : ''}`}
                            onClick={() => !disabled && addEvidence()}
                        >
                            {I18n.t('editPatient.evidences.addEvidence')}
                        </div>
                    </div>
                )}
            </Card>

            {isEditable && userType !== 'AUDITOR' && (
                <Button
                    type='button'
                    className={`primary small f-right ${disabled ? 'disabled' : ''}`}
                    text={
                        patient?.id
                            ? I18n.t('contract.buttons.save')
                            : I18n.t('contract.buttons.new')
                    }
                    onClick={() => {
                        setShowState(true);
                        patientAux?.id
                            ? editPatient(patientAux.id, updatedPatient(), patientAux.osId)
                            : postPatient(updatedPatient());
                    }}
                />
            )}
            <IndStatusModal
                setShow={setShowState}
                show={showState}
                status={editPatientStatus}
                setShowErrors={(error) => (error ? clearPatientDetails() : '')}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    patient: state.patient.editionDetails,
    editPatientStatus: state.patient.editPatientStatus,

    contract: state.contract.selectedContract,

    breadcrumbs: state.common.breadcrumbs,
    userType: state.profile.user.type,
    privateKey: state.profile.user?.privateKey,
    publicKey: state.session.os_publicKey || state.profile.user?.audit_group?.pubkey,

    patientDetail: state.transaction.auditorPatientDetail,
    auditGroupData: state.profile.user.audit_group,
    evidenceTypes: state.indication.evidenceTypes,
});

const mapDispatchToProps = (dispatch) => ({
    loadPatientData: (patient) => dispatch(actions.patient.setPatientDetails(patient)),
    postPatient: (patient) => dispatch(actions.patient.createNewPatient(patient)),
    editPatient: (id, patient, osId) => dispatch(actions.patient.editPatient(id, patient)),
    editAuditorPatient: (id, patient, osId) =>
        dispatch(actions.patient.editAuditorPatient(id, patient, osId)),
    getFileToUploadUrl: (id, name) => service.getFileToUploadUrl(id, name),
    getEvidenceType: () => dispatch(actions.indication.getEvidenceType()),
    clearPatientDetails: () => dispatch(actions.patient.clearPatientDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientEvidenceScreen);
