import {
    LOGIN_REQUEST,
    LOGIN_RESPONSE,
    LOGIN_RESPONSE_ERROR,
    LOGOUT,
    RESTORE_PASSWORD,
    RESTORE_PASSWORD_RESPONSE,
    RESTORE_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_RESPONSE,
    RESET_PASSWORD_ERROR,
    SAVE_REDIRECT_ROUTE,
    RESET_SESSION_UI,
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_RESPONSE,
    GET_NOTIFICATIONS_ERROR,
    GET_IS_WHITE_LISTER,
    GET_IS_WHITE_LISTER_RESPONSE,
    GET_IS_WHITE_LISTER_ERROR,
    UPDATE_MIGRATED_STATUS_RESPONSE,
} from './session.actions';
import { REHYDRATE } from 'redux-persist';

const initialState = {
    token: undefined,
    os_id: undefined, // obra social id
    os_name: undefined,
    redirectRoute: undefined,
    isLoggedIn: false,
    loginStatus: { error: false, loading: false, success: false, errorMessage: '' },
    restorePasswordStatus: { error: false, loading: false, success: false, errorMessage: '' },
    resetPasswordStatus: { error: false, loading: false, success: false, errorMessage: '' },
    notifications: undefined,
    notificationsStatus: { error: false, loading: false, success: false, errorMessage: '' },
    encKey: '',
    pk_backup: undefined,
    email_recover_key: '',
    migrated: false,
    directPayment: false,
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isLoggedIn: false,
                loginStatus: { error: false, loading: true, success: false, errorMessage: '' },
            };
        case LOGIN_RESPONSE:
            return {
                ...state,
                drug_id: action.response.drug_id,
                drug_name: action.response.drug_name,
                drug_publicKey: action.response.drug_publicKey,
                os_id: action.response.os_id,
                os_name: action.response.os_name,
                os_publicKey: action.response.os_publicKey,
                encKey: action.response.encKey,
                pk_backup: action.response.pk_backup,
                email_recover_key: action.response.email_recover_key,
                isLoggedIn: true,
                redirectRoute: undefined,
                loginStatus: { error: false, loading: false, success: true, errorMessage: '' },
                migrated: action.response.migrated,
                directPayment: action.response.directPayment,
            };
        case LOGIN_RESPONSE_ERROR:
            return {
                ...state,
                loginStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case SAVE_REDIRECT_ROUTE:
            return { ...state, redirectRoute: action.path };
        case RESTORE_PASSWORD:
        case RESTORE_PASSWORD_RESPONSE:
        case RESTORE_PASSWORD_ERROR:
            return {
                ...state,
                restorePasswordStatus: {
                    loading: action.type === RESTORE_PASSWORD,
                    success: action.type === RESTORE_PASSWORD_RESPONSE,
                    error: action.type === RESTORE_PASSWORD_ERROR,
                    errorMessage: action.type === RESTORE_PASSWORD_ERROR ? action.error : '',
                },
            };
        case GET_NOTIFICATIONS:
        case GET_NOTIFICATIONS_RESPONSE:
        case GET_NOTIFICATIONS_ERROR:
            return {
                ...state,
                notifications:
                    action.type === GET_NOTIFICATIONS_RESPONSE
                        ? action.response
                        : state.notifications,
                notificationsStatus: {
                    loading: action.type === GET_NOTIFICATIONS,
                    success: action.type === GET_NOTIFICATIONS_RESPONSE,
                    error: action.type === GET_NOTIFICATIONS_ERROR,
                    errorMessage: action.type === GET_NOTIFICATIONS_ERROR ? action.error : '',
                },
            };
        case RESET_PASSWORD:
        case RESET_PASSWORD_RESPONSE:
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordStatus: {
                    loading: action.type === RESET_PASSWORD,
                    success: action.type === RESET_PASSWORD_RESPONSE,
                    error: action.type === RESET_PASSWORD_ERROR,
                    errorMessage: action.type === RESET_PASSWORD_ERROR ? action.error : '',
                },
            };
        case RESET_SESSION_UI:
            return {
                ...state,
                loginStatus: initialState.loginStatus,
                restorePasswordStatus: initialState.restorePasswordStatus,
                resetPasswordStatus: initialState.resetPasswordStatus,
            };
        case UPDATE_MIGRATED_STATUS_RESPONSE:
            return {
                ...state,
                migrated: true,
            };
        case GET_IS_WHITE_LISTER:
        case GET_IS_WHITE_LISTER_RESPONSE:
        case GET_IS_WHITE_LISTER_ERROR:
            return {
                ...state,
                isWhiteLister:
                    action.type === GET_IS_WHITE_LISTER
                        ? state.isWhiteLister
                        : action.type === GET_IS_WHITE_LISTER_RESPONSE,
            };
        case LOGOUT:
            return { ...initialState };
        case REHYDRATE:
            let incoming = action.payload;
            if (incoming) {
                return {
                    ...incoming.session,
                    loginStatus: initialState.loginStatus,
                    isLoggedIn: localStorage['token'] ? incoming.session.isLoggedIn : false,
                    restorePasswordStatus: initialState.restorePasswordStatus,
                    resetPasswordStatus: initialState.resetPasswordStatus,
                    encKey: incoming.session.encKey || incoming.session.os_encKey || '', // moved from os_encKey to encKey
                };
            } else {
                return state;
            }
        default:
            return state;
    }
};

export default sessionReducer;
