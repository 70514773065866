import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import PropTypes from 'prop-types';
import { UserTypes } from '../../constants';
import { useHistory } from 'react-router-dom';

const hasAccess = (userRol, routeEnableRoles, path, userGroups) => {
    const walletPaths = [
        '/wallet/shippingAddress',
        '/wallet/sendNex',
        '/wallet/contact',
        '/wallet',
    ];

    if (
        (path === '/registration' || path === '/auditTransactions') &&
        (userRol === UserTypes.OS_USER ||
            userRol === UserTypes.DRUGSTORE ||
            UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY)
    ) {
        return routeEnableRoles.includes(userRol) && userGroups?.includes('registradores');
    } else if (
        path === '/grantingMonitor' &&
        (userRol === UserTypes.ROCHE_USER || userRol === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY)
    ) {
        return (
            routeEnableRoles.includes(userRol) &&
            (userGroups?.includes('granters') || userGroups?.includes('pagadores')) &&
            userGroups?.includes('whitelister')
        );
    } else if (
        path === '/sharedRisk' &&
        (userRol === UserTypes.ROCHE_USER || userRol === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY)
    ) {
        return routeEnableRoles.includes(userRol) && userGroups?.includes('medical_expert');
    } else if (
        path === '/wallet/monetaryBase' &&
        (userRol === UserTypes.ROCHE_USER || userRol === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY)
    ) {
        return routeEnableRoles.includes(userRol) && userGroups?.includes('nex_owner');
    } else if (
        walletPaths.includes(path) &&
        (userRol === UserTypes.DRUGSTORE ||
            userRol === UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY ||
            userRol === UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY)
    ) {
        return routeEnableRoles.includes(userRol) && userGroups?.includes('wallet');
    } else {
        return routeEnableRoles.includes(userRol);
    }
};

const RoleSpecificRoute = ({ component, ...rest }) => {
    const initialRouting = {
        patients: '/patients',
    };
    const history = useHistory();
    useEffect(() => {
        if (rest.redirect) {
            let location = window.location.pathname.split('/')[1];
            location = initialRouting[location] ?? initialRouting['default'] ?? '/';
            history.replace(location);
        }
    }, []);
    if (hasAccess(rest.userRol, rest.enableRoles, rest.path, rest.userGroups)) {
        return <Route {...rest} component={component} />;
    } else {
        return <Redirect to='/' />;
    }
};

RoleSpecificRoute.propTypes = {
    enableRoles: PropTypes.array.isRequired,
    path: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    userRol: state.profile.user.type,
    userGroups: state.profile.user?.groups,
});

export default connect(mapStateToProps)(RoleSpecificRoute);
