import React from 'react';
import { I18n } from 'react-redux-i18n';
import '../PatientEvidenceScreen.scss';
import EvidenceRow from './EvidenceRow';

const EvidencesTable = ({
    evidences,
    setEvidences,
    privateKey,
    publicKey,
    id,
    getFileToUploadUrl,
    patientDetail,
    auditGroupData,
    userType,
    evidenceTypes,
    patientCse,
    disabled,
}) => {
    const modifyEvidence = (evidence) => {
        setEvidences(
            evidences?.map((t) => (t.id === evidence.id || !t.tipo_evidencia ? evidence : t)),
        );
        disabled(false);
    };

    const deleteEvidence = (evidence) => {
        setEvidences(evidences?.filter((t) => t.id != evidence.id));
        disabled(false);
    };

    const types = evidenceTypes
        ?.map((v) => ({ value: v.id, label: v.descripcion }))
        ?.sort((a, b) => (a.label > b.label ? 1 : -1));

    return (
        <div className='container-table'>
            <table className='GreenTable coloreven EvidencesTable'>
                <thead>
                    <tr>
                        <th>{I18n.t('editPatient.evidences.cycle')}</th>
                        <th>{I18n.t('editPatient.evidences.desc')}</th>
                        <th>{I18n.t('editPatient.evidences.details')}</th>
                        <th>{I18n.t('editPatient.evidences.evReq')}</th>
                        <th>{I18n.t('editPatient.evidences.adj')}</th>
                        {userType !== 'AUDITOR' && (
                            <th>{I18n.t('editPatient.evidences.action')}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {evidences
                        ?.sort((a, b) => (a.aplicacion > b.aplicacion ? 1 : -1))
                        ?.map((ev, i) => {
                            return (
                                <EvidenceRow
                                    evidence={ev}
                                    key={ev.id}
                                    modifyEvidence={modifyEvidence}
                                    deleteEvidence={deleteEvidence}
                                    types={types}
                                    privateKey={privateKey}
                                    publicKey={publicKey}
                                    userType={userType}
                                    id={id}
                                    getFileToUploadUrl={getFileToUploadUrl}
                                    patientDetail={patientDetail}
                                    auditGroupData={auditGroupData}
                                    evidenceTypes={evidenceTypes}
                                    patientCse={patientCse}
                                    disabled={disabled}
                                    evidences={evidences}
                                />
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};
export default EvidencesTable;
