import React from 'react';
import './Card.scss';

const Card = ({
    title,
    children,
    className,
    classHead,
    classBody,
    detail,
    abilityBody = true,
    ...props
}) => {
    return (
        <div className={'card ' + className}>
            <div className={`card-head card-head-props ${classHead}`}>
                <div>{title}</div>
                {detail && <div>{detail}</div>}
            </div>
            {abilityBody ? (
                <div className={`card-body ${classBody}`}>{children}</div>
            ) : (
                <div>{children}</div>
            )}
        </div>
    );
};

export default Card;
