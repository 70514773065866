import TempInput from '../../../common/components/TempInput/TempInput';
import { Doses } from '../../../patient/components/PatientDoseScreen/PatientDoseScreen';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { I18n } from 'react-redux-i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

const ExpandableLabel = ({ show, onShow, hideDoses, text, children, className }) => {
    return (
        <div className={`ExpandableLabel ${className ?? ''}`} onBlur={hideDoses} tabIndex='0'>
            <div
                data-tip={I18n.t('patient.table.buttonTooltips.msgCycle')}
                data-html={true}
                data-for='dateTooltip'
                onClick={onShow}
            >
                {text}
            </div>
            <ReactTooltip
                id='dateTooltip'
                backgroundColor='#ffffff'
                effect='solid'
                className='transaction-tooltip paragraph'
            />

            {show && <div className='ExpandablePopup'>{children}</div>}
        </div>
    );
};

const CycleCell = ({
    row: { id, contract, aplication: ciclo, _editTransaction, status, ...rest },
    updTransaction,
    putTransactionsAplications,
    showDosesId,
    setShowDosesId,
    getProgramedDoses,
    clearProgrammedDoses,
    programedDoses,
}) => {
    const [cycleValue, setCycleValue] = useState(ciclo);
    const setCycle = (cycle) => {
        updTransaction({ id, aplication: cycle });
    };
    const availableStatuses = ['pending', 'validationError'];

    return _editTransaction ? (
        <div className='action-container'>
            <TempInput
                key={id}
                tempObj={{ cycleValue }}
                setAtt={(obj) => setCycleValue(obj.ciclo)}
                submit={() => {
                    updTransaction({ id, _editTransaction: false });
                }}
                att='ciclo'
                type='uint'
            />
            <FontAwesomeIcon
                icon={faSave}
                onClick={() => {
                    if (
                        availableStatuses.find((s) => s === status) &&
                        contract.tipo_contrato_tipo !== 'S'
                    ) {
                        updTransaction({
                            id,
                            _editTransaction: !_editTransaction,
                            aplication: cycleValue,
                        });
                    }
                    putTransactionsAplications([{ id: id, aplicacion: cycleValue }]);
                }}
            />
        </div>
    ) : (
        <div className='action-container'>
            <ExpandableLabel
                text={cycleValue}
                className='cycle-container'
                onShow={() => {
                    setShowDosesId(showDosesId === id ? null : id);
                    if (rest.patient) getProgramedDoses(rest.contractPatientId);
                    else clearProgrammedDoses();
                }}
                show={showDosesId === id}
                hideDoses={() => setShowDosesId(null)}
            >
                <div className='cycle-dropdown'>
                    <Doses
                        programedDoses={programedDoses}
                        cycle={cycleValue}
                        //id={rest.patient.contratos.find(x => x.contrato === contract.id)?.id}
                        // setCycle={setCycle}
                        // I prefer this way of editing, picking from the list
                    />
                </div>
            </ExpandableLabel>
            {availableStatuses.find((s) => s === status) && contract.tipo_contrato_tipo !== 'S' && (
                <i
                    className='icon-pencil'
                    onClick={() => {
                        updTransaction({ id, _editTransaction: !_editTransaction });
                    }}
                />
            )}
        </div>
    );
};

export default CycleCell;
