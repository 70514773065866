import React, { useState } from 'react';
import './OSAuditTransactionsScreen.scss';
import SearchBar from '../../../common/components/SearchBar';
import { I18n } from 'react-redux-i18n';
import Loader from '../../../common/components/Loader';
import ReactTable from 'react-table';
import TablePagination from '../../../common/components/TablePagination';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DetailModal from './DetailModal';
import SelectMultiple from '../../../common/components/SelectMultiple';

const auditStates = [
    { value: 'P', label: 'auditPending' },
    { value: 'R', label: 'auditRejected' },
    { value: 'A', label: 'auditApproved' },
    { value: 'C', label: 'auditConsumptionRejected' },
    { value: 'B', label: 'cleanedEvidence' },
    { value: 'E', label: 'endSuccessfulTreatment' },
    { value: 'F', label: 'endTreatmentProgression' },
    { value: '#', label: 'noAuditor' },
];

const defaultStatusSelected = [
    { id: 'P', checked: true },
    { id: 'R', checked: true },
    { id: 'A', checked: false },
    { id: 'C', checked: true },
    { id: 'B', checked: true },
    { id: 'E', checked: false },
    { id: 'F', checked: false },
];

const auditorsGroup = [{ value: 1, label: 'Grupo Auditores S.A.' }];

const OSAuditTransactionsScreen = (props) => {
    const [textToSearch, setTextToSearch] = useState('');
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [detailModalInformation, setDetailModalInformation] = useState({});
    const [statusFilter, setStatusFilter] = useState(defaultStatusSelected);
    const [transactions, setTransactions] = useState([]);

    React.useEffect(() => {
        props.getAuditTransactions(props.osID);
    }, []);

    React.useEffect(() => {
        const filters = statusFilter.filter((x) => x.checked) ?? [];
        if (filters.length > 0) {
            setTransactions(
                props.transactions.filter((x) => filters.some((y) => y.id === x.estado)),
            );
        }
        // eslint-disable-next-line
    }, [props.transactions]);

    React.useEffect(() => {
        const filters = statusFilter.filter((x) => x.checked) ?? [];
        if (filters.length > 0) {
            setTransactions(
                props.transactions.filter((x) => filters.some((y) => y.id === x.estado)),
            );
        }
        // eslint-disable-next-line
    }, [statusFilter]);

    // Function que formatea la columna de estado
    const renderStateColumn = (value) => {
        const [new_value] = auditStates.filter((state) => state.value === value);
        return (
            <span className={`transactions-table-row ${new_value?.label}`}>
                {I18n.t(`transaction.table.status.${new_value?.label}`)}
            </span>
        );
    };

    const renderTableFooter = (paginationProps) => (
        <div className='transaction-table-footer'>
            <TablePagination
                {...paginationProps}
                total={transactions?.length}
                totalPages={transactions?.length / 10}
                pagedMessageTranslation='transaction.table.pagedMessage'
                pagedMessageEmptyTranslation='transaction.table.pagedMessageEmpty'
            />
        </div>
    );

    const handleStatusFilterChange = (statusId) => {
        const otherStatus = statusFilter.filter((status) => status.id !== statusId);
        const selectedStatus = statusFilter.filter((status) => status.id === statusId)[0];
        setStatusFilter([...otherStatus, { id: statusId, checked: !selectedStatus?.checked }]);
    };

    const infoModal = () => {
        return (
            <>
                <h5>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{
                            color: '#008e99',
                            fontSize: '2.25rem',
                            margin: '0 1.75rem',
                        }}
                    />
                    {I18n.t('transaction.table.status.definition')}
                </h5>
                <hr />
                <ul className='list required'>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditPending')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditApproved')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditRejected')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditConsumptionRejected')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.cleanedEvidence')}</h5>
                    </li>
                </ul>
            </>
        );
    };
    const formatHeader = (text, id, infoIcon = false, modal) => {
        return (
            <div className='nexus-header-arrows space-between'>
                <h5>
                    {text}&nbsp;
                    {infoIcon && <FontAwesomeIcon data-tip data-for={id} icon={faInfoCircle} />}
                </h5>
                <ReactTooltip
                    id={id}
                    place='bottom'
                    type='light'
                    effect='solid'
                    className='info-modal'
                >
                    <>{modal}</>
                </ReactTooltip>
                <div className='arrows'>
                    <span>▲</span>
                    <span>▼</span>
                </div>
            </div>
        );
    };

    const getTableColumns = () => {
        // here could be some logic to show certain columns depending on user role.
        return [
            {
                id: 'numero', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transaction.table.headers.number')}</h5>,
                accessor: (d) => d.id, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 100,
            },
            {
                id: 'paciente', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(
                        I18n.t('transaction.table.headers.patientId'),
                        'header_patient_id',
                    ),
                accessor: (d) => d.paciente?.id, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 150,
            },
            {
                id: 'nombre', // Required because our accessor is not a string
                Header: () => formatHeader(I18n.t('transaction.table.headers.name', 'header_name')),
                accessor: (d) => d.paciente.name, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header space-between',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'dni', // Required because our accessor is not a string
                Header: () => formatHeader(I18n.t('transaction.table.headers.dni'), 'header_dni'),
                accessor: (d) => d.paciente.dni, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 150,
            },
            {
                id: 'incidente', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('transaction.table.headers.incident'), 'header_incident'),
                accessor: (d) => d.descripcion, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'estado', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(
                        I18n.t('transaction.table.headers.status'),
                        'header_status',
                        true,
                        infoModal(),
                    ),
                accessor: (d) => d.estado, // Custom value accessors!
                Cell: (props) => renderStateColumn(props.value),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'grupo_auditores', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(
                        I18n.t('transaction.table.headers.auditorGroup'),
                        'header_auditor_group',
                    ),
                accessor: (d) => d.grupo_auditor_descr, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'detalle', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transaction.table.headers.detail')}</h5>,
                Cell: (prop) => (
                    <button
                        className='detail-button'
                        onClick={() => {
                            setShowDetailModal(true);
                            setDetailModalInformation({
                                ...prop.row._original,
                                stateLabel: auditStates
                                    .filter((state) => state.value === prop.row._original.estado)
                                    ?.map((state) => state.label)[0],
                            });
                        }}
                    >
                        <span className='text transactions-table-row details'>Ver detalle</span>
                    </button>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 150,
            },
        ];
    };

    return (
        <div className='patients-screen'>
            <div className='header'>
                <span className='title'>{I18n.t('transaction.transactionToAuditTitle')}</span>
                <SearchBar
                    textToSearch={textToSearch}
                    onSearch={(text) => {
                        setTextToSearch(text);
                        props.searchTransactions(text);
                    }}
                />
                <SelectMultiple
                    values={statusFilter}
                    onChange={handleStatusFilterChange}
                    className='rounded'
                    placeholder={I18n.t('transaction.table.headers.status')}
                    options={[
                        { value: 'P', label: I18n.t('transaction.table.status.auditPending') },
                        { value: 'A', label: I18n.t('transaction.table.status.auditApproved') },
                        { value: 'R', label: I18n.t('transaction.table.status.auditRejected') },
                        {
                            value: 'C',
                            label: I18n.t('transaction.table.status.auditConsumptionRejected'),
                        },
                        { value: 'B', label: I18n.t('transaction.table.status.cleanedEvidence') },
                        {
                            value: 'E',
                            label: I18n.t('transaction.table.status.endSuccessfulTreatment'),
                        },
                        {
                            value: 'F',
                            label: I18n.t('transaction.table.status.endTreatmentProgression'),
                        },
                    ]}
                />
            </div>
            <div className='table-container'>
                {props.loading ? (
                    <>
                        <div className='header' />
                        <div className='transaction-table-container'>
                            <Loader />
                        </div>
                    </>
                ) : (
                    <ReactTable
                        //className={(props.transactions.length > 0 ? "nexus-table"  : "nexus-table empty")}
                        className='nexus-table'
                        PaginationComponent={(paginationProps) =>
                            renderTableFooter(paginationProps)
                        }
                        minRows={0}
                        pageSize={10}
                        data={transactions}
                        noDataText={I18n.t('transaction.table.noRows')}
                        onSortedChange={(newSorted, column, shiftKey) => {
                            // props.updateFilterValues({ordering: (newSorted[0].desc ? "-" : "") + column.id});
                            // props.getTransactions();
                        }}
                        // todo check if this prop is necessary
                        getTdProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                },
                            };
                        }}
                        columns={getTableColumns()}
                        defaultSorted={[{ id: 'dates', desc: true }]}
                    />
                )}
            </div>
            <DetailModal
                show={showDetailModal}
                data={detailModalInformation}
                close={() => setShowDetailModal(false)}
                postAuditorTransactions={props.postAuditorTransactions}
                setAuditorPatientDetail={props.setAuditorPatientDetail}
            />
        </div>
    );
};

export default OSAuditTransactionsScreen;
