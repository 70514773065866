import { webApi, get, post, put } from '../utils/http';

export default {
    getPatients: () => get(webApi + `api/paciente/`),
    newPatient: (body) => post(webApi + `api/paciente/`, body),
    editPatient: (patientId, body) => put(webApi + `api/paciente/${patientId}/`, body),
    getProgramedDose: (id) => get(`${webApi}api/pacientecontrato/${id}/dosisprogramadas`),
    getAuditorProgramedDose: (id) =>
        get(`${webApi}api/auditoria/pacientecontrato/${id}/dosisprogramadas`),
    getFileToUploadUrl: (id, name) =>
        get(webApi + `api/pacientecontrato/${id}/adjunto/${name}/uploadurl`),
    uploadFileToS3: (url, body) => put(url, body, { headers: { 'Content-Type': body.type } }, true),
    getFileToS3: (url) => get(url, true),
    getBasicPatients: () => get(webApi + 'api/paciente/basico/'),
};
