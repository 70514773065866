import {
    VALIDATE_PRODUCT,
    REGISTER_PRODUCTS,
    DOWNLOAD_REGISTER_TRANSACTIONS,
    GET_ALL_PRODUCTS_BY_ID,
} from './registration.actions';
import actions from '../actions';
import service from './registration.services';
import { I18n } from 'react-redux-i18n';
import { encryptString, decryptString, getSharedSecret } from '../utils/encryption';
import { parseToCsvAndDownload } from '../utils';
import { parseDate, parseTrace } from '../utils/parser';
import { UserTypes } from '../constants';

const registrationMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        let sharedSecret;
        const privateKey = getState().profile.user?.privateKey;
        switch (action.type) {
            case VALIDATE_PRODUCT:
                service
                    .validateProduct(createValidateProductBody(action))
                    .then((response) => {
                        dispatch(actions.registration.validateProductResponse(response));
                        if (action.successCallback) action.successCallback();
                    })
                    .catch((error) => {
                        dispatch(
                            actions.registration.validateProductError(
                                parseValidateError(error.data),
                            ),
                        );
                    });
                break;
            case REGISTER_PRODUCTS:
                service
                    .registerProducts(createRegisterProductsBody(action))
                    .then((response) => {
                        dispatch(actions.registration.registerProductsResponse(response));
                        if (action.successCallback) action.successCallback();
                    })
                    .catch((error) => {
                        dispatch(
                            actions.registration.registerProductsError(
                                parseRegisterProductsError(error.data),
                            ),
                        );
                    });
                break;
            case DOWNLOAD_REGISTER_TRANSACTIONS:
                service
                    .getRegisterTransactions()
                    .then((response) => {
                        const decryptedTransactions = decryptTransactions(
                            response,
                            getState().profile.user.type === UserTypes.OS_USER
                                ? getState().session.os_publicKey
                                : getState().registrar.branch.publicKey,
                        );
                        parseToCsvAndDownload(
                            decryptedTransactions,
                            'Historial de Transacciones.csv',
                        );
                        dispatch(
                            actions.registration.downloadRegisterTransactionsResponse(response),
                        );
                    })
                    .catch((error) => {
                        dispatch(actions.registration.downloadRegisterTransactionsError(error));
                    });
                break;
            case GET_ALL_PRODUCTS_BY_ID:
                service
                    .getAllProductsById(action.patientId)
                    .then((response) => {
                        const productsWithContract = response.flatMap((contract) =>
                            contract.productos.map((product) => {
                                return {
                                    contractId: contract.id,
                                    codigo_ean: product.codigo_ean,
                                    denominacion: product.denominacion,
                                    cantidad: product.cantidad,
                                    unidad_medida_descr: product.unidad_medida_descr,
                                };
                            }),
                        );
                        dispatch(
                            actions.registration.getAllProductsByIdResponse(productsWithContract),
                        );
                    })
                    .catch((error) => {
                        dispatch(actions.registration.getAllProductsByIdError(error));
                    });
                break;
        }
    };

/*
 * This method decrypts all the encrypted data on 'registrador' transactions. Remember that transactions could be from
 * different OSs so the share secret will be different (that is why we need the register publicKey and privateKey)
 * */
const decryptTransactions = (transactions, registerPublicKey) => {
    return transactions.map((transaction) => {
        let patientId = decryptString(transaction.shared_paciente_id, registerPublicKey);
        const trace = parseTrace(transaction.traza);
        return {
            'Id Obra Social': transaction.obra_social,
            'Nombre Obra Social': transaction.os_razon_social,
            Paciente: patientId,
            GTIN: trace.gtin,
            SN: trace.sn,
            VEN: trace.ven,
            LOT: trace.lot,
            'Fecha de Scaneo': parseDate(new Date(transaction.fechas[0].created_at)),
            Producto: transaction.denominacion || '',
        };
    });
};

const createValidateProductBody = (action) => {
    return {
        obra_social: action.os,
        paciente: action.patientId,
        traza: action.product.trace,
        gtin: action.product.gtin,
        serial_number: action.product.sn,
    };
};

const createRegisterProductsBody = (action) => {
    return [
        ...action.traces.map((trace) => ({
            obra_social: action.os,
            paciente: action.patientId,
            traza: trace.trace,
            gtin: trace.gtin,
            serial_number: trace.sn,
            aplicacion: trace.cycle,
        })),
    ];
};

const parseValidateError = (error) => {
    const knownErrorStrings = ['Registrador no fue aun validado por la OS'];
    if (typeof error === 'string') {
        if (knownErrorStrings.includes(error)) return error;
        else return I18n.t('errors.generic');
    }

    const knownErrorsKeys = ['non_field_errors'];
    const firstErrorKey = Object.keys(error)[0];
    if (knownErrorsKeys.includes(firstErrorKey)) return error[`${firstErrorKey}`][0];
    return I18n.t('errors.generic');
};

// todo ask for the errors keys of this route.
const parseRegisterProductsError = (error) => {
    return I18n.t('error.generic');
};

export default registrationMiddleware;
