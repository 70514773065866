import React from 'react';
import './IncidentCard.scss';
import { I18n } from 'react-redux-i18n';
import Button from '../../../../../common/components/Button';
import TextArea from '../../../../../common/components/TextArea/TextArea';
import Card from '../../../../../common/components/Card/Card';
import { Link, useHistory } from 'react-router-dom';
import { decryptString, encryptString, getSharedSecret } from '../../../../../utils/encryption';
import { ConfirmationModalWithText } from '../../../../../common/components/Modal/Modal';

const IncidentCard = ({
    comments,
    postAuditorTransactions,
    isPending = false,
    auditType,
    contractType,
    transactionStatus,
    auditorId,
    id,
    cse,
    osPublicKey,
    auditorName,
    osName,
    instructions,
}) => {
    const [comment, setComment] = React.useState('');
    const [error, setError] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [rocheComment, setRocheComment] = React.useState('');
    const history = useHistory();

    const changeComment = (text) => {
        setRocheComment(text);
    };

    React.useEffect(() => {
        transactionStatus.success &&
            (comment.length > 0 || rocheComment.length > 0) &&
            history.push('/auditorTransactions');
        transactionStatus.error &&
            (comment.length > 0 || rocheComment.length > 0) &&
            setError(true);
    }, [transactionStatus]);

    return (
        <Card title={I18n.t('auditor.incident.title')} className='side-padding-l min-height'>
            <br />
            {error && (
                <>
                    <div className='error-box show'>
                        <i className='icon-exclamation' />
                        <div className='error-msj'>
                            {comment
                                ? I18n.t('auditor.incident.shortComment')
                                : I18n.t('auditor.incident.noComment')}
                        </div>
                    </div>
                    <br />
                </>
            )}
            <div>
                <label className='input-label big'>{I18n.t('auditIncident.instructions')}</label>
                <p className='input-label'>• {instructions}</p>
            </div>
            <br />
            <div key='input-label' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label className='input-label big'>{I18n.t('auditor.incident.comments')}</label>
                <Link to='/auditHistory' className='audit-history-button'>
                    <i className='icon-eye' />
                    &nbsp;{I18n.t('auditor.incident.auditHistoryButton')}
                </Link>
            </div>
            {/* if it's pending, don't show the textarea neither the buttons */}
            {/* We need to change the way we show previous comments */}
            {comments
                .filter((comment) => comment?.comentario_enc && comment.comentario_enc !== '')
                .map((comment, index) => (
                    <div key={index} className='comment-container'>
                        {comment?.usuario_auditor && (
                            <label>{auditorName || 'No identificado'}:</label>
                        )}
                        {comment?.usuario_os && <label>{osName || 'No identificado'}:</label>}
                        <p>{comment.comentario_enc}</p>
                    </div>
                ))}
            {isPending && (
                <>
                    <label className='input-label big'>{I18n.t('auditor.incident.comment')}</label>
                    <TextArea
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value);
                            if (e.target.value.length >= 2) setError(false);
                        }}
                        // error={showErrors && !indication?.descripcion}
                    />
                    <div className='card-subrow center'>
                        <Button
                            type='button'
                            className='primary small'
                            text={I18n.t('auditor.incident.accept')}
                            onClick={() => {
                                comment.length < 2
                                    ? setError(true)
                                    : postAuditorTransactions(
                                          {
                                              usuario_os: osPublicKey,
                                              comentario_enc: encryptString(comment, cse),
                                              usuario_auditor: auditorId,
                                              estado: 'A',
                                          },
                                          id,
                                      );
                            }}
                        />
                        <Button
                            type='button'
                            className='error small'
                            text={I18n.t('auditor.incident.deny')}
                            onClick={() => {
                                comment.length < 2
                                    ? setError(true)
                                    : postAuditorTransactions(
                                          {
                                              usuario_os: osPublicKey,
                                              comentario_enc: encryptString(comment, cse),
                                              usuario_auditor: auditorId,
                                              estado: 'R',
                                          },
                                          id,
                                      );
                            }}
                        />
                        {auditType === 'E' && contractType === 'R' && (
                            <>
                                <Button
                                    type='button'
                                    className='hollow error small'
                                    text={I18n.t('auditor.incident.endContract')}
                                    onClick={() => {
                                        setShow(true);
                                    }}
                                />
                                <ConfirmationModalWithText
                                    icon={<i className='icon-exclamation red' />}
                                    close={() => {
                                        setShow(false);
                                    }}
                                    show={show}
                                    title={I18n.t('auditor.incident.endContractMsg')}
                                    noBody={true}
                                    cancelText={I18n.t('auditor.incident.progression')}
                                    submitText={I18n.t('auditor.incident.success')}
                                    cancel={() => {
                                        rocheComment.length < 2
                                            ? setError(true)
                                            : postAuditorTransactions(
                                                  {
                                                      usuario_os: osPublicKey,
                                                      comentario_plain_roche: rocheComment,
                                                      comentario_enc: encryptString(comment, cse),
                                                      usuario_auditor: auditorId,
                                                      estado: 'F',
                                                  },
                                                  id,
                                              );
                                    }}
                                    submit={() => {
                                        rocheComment.length < 2
                                            ? setError(true)
                                            : postAuditorTransactions(
                                                  {
                                                      usuario_os: osPublicKey,
                                                      comentario_plain_roche: rocheComment,
                                                      comentario_enc: encryptString(comment, cse),
                                                      usuario_auditor: auditorId,
                                                      estado: 'E',
                                                  },
                                                  id,
                                              );
                                    }}
                                    setComment={changeComment}
                                    comment={rocheComment}
                                />
                            </>
                        )}
                    </div>
                </>
            )}
        </Card>
    );
};

export default IncidentCard;
