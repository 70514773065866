import { connect } from 'react-redux';
import actions from '../../actions';
import { ReviewModal } from '../components/SharedRisk/Modals';

const mapStateToProps = (state) => ({
    sharedRiskTransactions: state.transaction.filteredSharedRiskTransactions,
});

const mapDispatchToProps = (dispatch) => ({
    updateSharedRiskTransactions: (body, id) =>
        dispatch(actions.transaction.updateSharedRiskTransactions(body, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewModal);
