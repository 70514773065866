import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import Login from './session/containers/Login';
import Header from './common/components/Header';
import ReverseAuthRoute from './common/components/ReverseAuthRoute';
import SideBarScreen from './common/components/SideBarScreen';
import Profile from './profile/containers/Profile';
import CreatePrivateKey from './profile/containers/CreatePrivateKey';
import ImportPrivateKey from './profile/containers/ImportPrivateKey';
import RecoverPassword from './session/containers/RecoverPassword';
import ResetPassword from './session/containers/ResetPassword';
import Patients from './patient/containers/Patients';
import EditPatientScreen from './patient/containers/EditPatientScreen';
import PatientEvidenceScreen from './patient/components/PatientEvidenceScreen/PatientEvidenceScreen';
import PatientDoseScreen from './patient/components/PatientDoseScreen/PatientDoseScreen';
import Registration from './registration/containers/Registration';
import RegistrarSignUp from './registrar/containers/RegistrarSignUp';
import RegistrarNewAccount from './registrar/containers/RegistrarNewAccount';
import RegistrarProfileScreen from './profile/containers/RegistrarProfile';
import OSTransactions from './transaction/containers/OSTransactions';
import ContractList from './contract/containers/ContractList';
import Contract from './contract/containers/Contract';
import RocheTransactions from './transaction/containers/RocheTransactions';
import NexMonitor from './transaction/containers/NexMonitor';
import GrantingMonitor from './transaction/containers/GrantingMonitor';
import RegistrarAccountActivation from './registrar/containers/RegistrarAccountActivation';
import AuthRoute from './common/components/AuthRoute';
import RoleSpecificRoute from './common/components/RoleRoute';
import { UserTypes } from './constants';
import IndicationsList from './indication/containers/IndicationsList';
import IndicationsScreen from './indication/containers/IndicationsScreen';
import AuditorTransactions from './transaction/containers/AuditorTransactions';
import AuditIncident from './transaction/containers/AuditIncident';
import OSAuditTransactionsScreen from './transaction/containers/OSAuditTransactionsScreen';
import AuditorAuditHistory from './transaction/containers/AuditorAuditHistory';
import TransactionMonitor from './transaction/containers/TransactionMonitor';
import SharedRiskMonitor from './transaction/containers/SharedRiskMonitor';
import ShippingAddressContainer from './wallet/containers/ShippingAddressContainer';
import WalletHomeMonitor from './wallet/containers/walletHome/WalletHomeMonitor';
import MonetaryBaseMonitor from './wallet/containers/monetaryBase/MonetaryBaseMonitor';
import SendNexContainer from './wallet/containers/SendNexContainer';
import AddContactContainer from './wallet/containers/AddUser/AddContactContainer';
import AccessContainer from './roleAccess/Containers/AccessContainer';
import BonificationScreen from './bonification/components/BonificationScreen';
import Dashboard from './dashboard/containers/Dashboard';
// import { Dashboard } from './dashboard/Dashboard';

const style = {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
};

const routes = () => {
    return (
        <div style={style}>
            <Header />
            <Notifications />
            <Switch>
                <ReverseAuthRoute exact path='/' component={Login} />
                <Route exact path='/registrarSignUp' component={() => <RegistrarSignUp />} />
                <Route exact path='/recover-password' component={() => <RecoverPassword />} />
                <Route path='/reset/:uid/:token' render={(props) => <ResetPassword {...props} />} />
                <Route
                    path='/registrar/activate/:uid/:token'
                    render={(props) => <RegistrarAccountActivation {...props} />}
                />
                <AuthRoute
                    path='/'
                    component={() => (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flex: 1,
                                alignSelf: 'stretch',
                                height: `calc(100% - 101px)`,
                            }}
                        >
                            <SideBarScreen />
                            <Switch>
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.OS_USER,
                                        UserTypes.OS_USER_WITHOUT_PUBLIC_KEY,
                                        UserTypes.OS_USER_WITHOUT_PRIVATE_KEY,
                                        UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY,
                                        UserTypes.AUDITOR,
                                        UserTypes.AUDITOR_WITHOUT_PUBLIC_KEY,
                                        UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY,
                                        UserTypes.AUDITOR_WITHOUT_OLD_PRIVATE_KEY,
                                        UserTypes.DRUGSTORE,
                                        UserTypes.DRUGSTORE_WITHOUT_PUBLIC_KEY,
                                        UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/profile'
                                    component={() => <Profile />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.OS_USER_WITHOUT_PUBLIC_KEY,
                                        UserTypes.REGISTER_USER_WITHOUT_PUBLIC_KEY,
                                        UserTypes.AUDITOR_WITHOUT_PUBLIC_KEY,
                                        UserTypes.DRUGSTORE_WITHOUT_PUBLIC_KEY,
                                    ]}
                                    path='/createPrivateKey'
                                    component={() => <CreatePrivateKey />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.OS_USER_WITHOUT_PRIVATE_KEY,
                                        UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY,
                                        UserTypes.REGISTER_USER_WITHOUT_PRIVATE_KEY,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                        UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY,
                                        UserTypes.AUDITOR_WITHOUT_OLD_PRIVATE_KEY,
                                        UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/importPrivateKey'
                                    component={() => <ImportPrivateKey />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[UserTypes.OS_USER, UserTypes.DRUGSTORE]}
                                    path='/patients'
                                    exact
                                    component={() => <Patients />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.OS_USER,
                                        UserTypes.AUDITOR,
                                        UserTypes.DRUGSTORE,
                                    ]}
                                    path='/patients/edit'
                                    redirect={true}
                                    component={() => <EditPatientScreen />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[UserTypes.OS_USER, UserTypes.AUDITOR]}
                                    path='/patients/access'
                                    redirect={true}
                                    component={() => <AccessContainer />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.OS_USER,
                                        UserTypes.AUDITOR,
                                        UserTypes.DRUGSTORE,
                                    ]}
                                    path='/patients/evidence'
                                    redirect={true}
                                    component={() => <PatientEvidenceScreen />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[UserTypes.OS_USER, UserTypes.AUDITOR]}
                                    path='/patients/doses'
                                    redirect={true}
                                    component={() => <PatientDoseScreen />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[UserTypes.OS_USER, UserTypes.DRUGSTORE]}
                                    path='/dashboard'
                                    component={() => <Dashboard />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.OS_USER,
                                        UserTypes.DRUGSTORE,
                                        UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY,
                                        UserTypes.DRUGSTORE,
                                    ]}
                                    path='/transactions'
                                    component={() => <OSTransactions />}
                                />
                                {/* todo change the user type for Roche */}
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.OS_USER,
                                        UserTypes.DRUGSTORE,
                                        UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/auditTransactions'
                                    component={() => <OSAuditTransactionsScreen />}
                                />
                                {/* hasta aca */}
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.ROCHE_USER,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/rocheTransactions'
                                    component={() => <RocheTransactions />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.ROCHE_USER,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/nexMonitor'
                                    component={() => <NexMonitor />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.ROCHE_USER,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/grantingMonitor'
                                    component={() => <GrantingMonitor />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.ROCHE_USER,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/bonifications'
                                    component={() => <BonificationScreen />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.REGISTER_USER,
                                        UserTypes.OS_USER,
                                        UserTypes.DRUGSTORE,
                                        UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY,
                                        UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/registration'
                                    component={() => <Registration />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[UserTypes.USER]}
                                    path='/registrarNewAccount'
                                    component={() => <RegistrarNewAccount />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[UserTypes.USER, UserTypes.AUDITOR]}
                                    path='/auditorTransactions'
                                    component={() => <AuditorTransactions />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.AUDITOR,
                                        UserTypes.DRUGSTORE,
                                        UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/auditIncident'
                                    component={() => <AuditIncident />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[UserTypes.AUDITOR, UserTypes.OS_USER]}
                                    path='/auditHistory'
                                    component={() => <AuditorAuditHistory />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.REGISTER_USER,
                                        UserTypes.REGISTER_USER_WITHOUT_PUBLIC_KEY,
                                        UserTypes.REGISTER_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/registrarProfile'
                                    component={() => <RegistrarProfileScreen />}
                                />

                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.ROCHE_USER,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/indications/details'
                                    component={() => <IndicationsScreen />}
                                />

                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.ROCHE_USER,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/indications/list'
                                    component={() => <IndicationsList />}
                                />

                                <RoleSpecificRoute
                                    exact
                                    enableRoles={[
                                        UserTypes.ROCHE_USER,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/contracts/list'
                                    component={() => <ContractList />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.ROCHE_USER,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/contracts/detail'
                                    component={() => <Contract />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.ROCHE_USER,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/transactionsMonitor'
                                    component={() => <TransactionMonitor />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.ROCHE_USER,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/sharedRisk'
                                    component={() => <SharedRiskMonitor />}
                                />

                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.DRUGSTORE,
                                        UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY,
                                        UserTypes.OS_USER,
                                    ]}
                                    path='/roleAccess'
                                    component={() => <AccessContainer />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.DRUGSTORE,
                                        UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY,
                                        UserTypes.OS_USER,
                                    ]}
                                    path='/wallet/shippingAddress'
                                    component={() => <ShippingAddressContainer />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.DRUGSTORE,
                                        UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY,
                                        UserTypes.OS_USER,
                                    ]}
                                    path='/wallet/sendNex'
                                    component={() => <SendNexContainer />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.DRUGSTORE,
                                        UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY,
                                        UserTypes.OS_USER,
                                    ]}
                                    path='/wallet/contact'
                                    component={() => <AddContactContainer />}
                                />

                                <RoleSpecificRoute
                                    enableRoles={[
                                        UserTypes.ROCHE_USER,
                                        UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY,
                                    ]}
                                    path='/wallet/monetaryBase'
                                    component={() => <MonetaryBaseMonitor />}
                                />
                                <RoleSpecificRoute
                                    enableRoles={[UserTypes.DRUGSTORE, UserTypes.OS_USER]}
                                    path='/wallet'
                                    component={() => <WalletHomeMonitor />}
                                />
                            </Switch>
                        </div>
                    )}
                />
            </Switch>
        </div>
    );
};

export default routes;
