import React, { useEffect } from 'react';
import './ProgramedDoses.scss';
import '../../../patient/components/PatientDoseScreen/PatientDoseScreen.scss';
import Loader from '../../../common/components/Loader';
import { I18n } from 'react-redux-i18n';
import { todayStr } from '../../../utils/date';

const ProgramedDoses = ({
    doses,
    cycle,
    setCycle,
    id,
    products,
    getProgramedDoses,
    programedDoses,
    programmedDosesStatus,
    emptyPage,
}) => {
    const [dosesAux, setDosesAux] = React.useState(doses ? doses : []);

    useEffect(() => {
        //getProgramedDoses(id);
    }, [id]);

    useEffect(() => {
        setDosesAux(doses);
    }, [programedDoses]);

    /*useEffect(() => {
        if(products.length && products[products.length - 1].cycle) {
            setDosesAux(dosesAux.map(dose => {
                const dosisReal = products.reduce((acc, product) => {
                    if (product.cycle === dose.ciclo) {
                        return acc + parseFloat(product.product.cantidad);
                    }
                    return acc;
                }, 0);
                return { ...dose,
                    fecha_registracion: products.some(x => x.edited && x.cycle === dose.ciclo) ? todayStr : dosisReal ? dose.fecha_registracion : programedDoses.dosis.find(x => x.ciclo = dose.ciclo).fecha_registracion,
                    dosis_real: programedDoses.dosis.find(x => x.ciclo = dose.ciclo).dosis_real + dosisReal
                }
            }))
        }
    }, [products])
*/

    // Function que calcula la diferencia de dias
    const getDateOffset = (date1, date2) => {
        const difference = Math.abs(date1 - date2);
        const days = difference / (1000 * 3600 * 24);
        return `${days}`;
    };

    // Function que calcula la variacion
    const getVariations = (dosis_programada, dosis_real) => {
        if (dosis_real && dosis_programada > 0) {
            const porcentage = ((dosis_real - dosis_programada) * 100) / dosis_programada;
            return porcentage.toFixed(2) + '%';
        } else if (dosis_real < dosis_programada && dosis_programada > 0 && dosis_real > 0) {
            const porcentage = ((dosis_programada - dosis_real) * 100) / dosis_programada;
            return `-${porcentage.toFixed(2)}%`;
        } else {
            return '';
        }
    };

    return (
        <>
            <div className='scrollable'>
                {programmedDosesStatus.loading ? (
                    <Loader />
                ) : !doses || doses?.length === 0 ? (
                    !emptyPage && <div className='emptyTable-row'>No hay datos para mostrar</div>
                ) : (
                    <>
                        <table className='dosesTable'>
                            <thead>
                                <tr>
                                    <th colSpan={4}>{I18n.t('editPatient.doses.doses')}</th>
                                    <th rowSpan={2}>{I18n.t('editPatient.doses.mgReg')}</th>
                                    <th rowSpan={2}>
                                        {I18n.t('editPatient.doses.dateOf')}{' '}
                                        {I18n.t('editPatient.doses.registration')}
                                    </th>
                                    <th rowSpan={2}>{I18n.t('editPatient.doses.var')}</th>
                                    <th rowSpan={2}>Diferencia de dias</th>
                                </tr>
                                <tr>
                                    <th>{I18n.t('editPatient.doses.cycle')}</th>
                                    <th>{I18n.t('editPatient.doses.day')}</th>
                                    <th>{I18n.t('editPatient.doses.estDate')}</th>
                                    <th>{I18n.t('editPatient.doses.mgTotal')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {doses?.map((dose, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            className={dose.ciclo === cycle ? 'selected' : ''}
                                            onClick={() => setCycle && setCycle(dose.ciclo)}
                                        >
                                            <td>{dose.ciclo}</td>
                                            <td>{dose.dia}</td>
                                            {/* <td>{parseVnDate(dose.fecha_estimada)}</td> */}
                                            <td>{dose.fecha ? dose.fecha : '-'}</td>
                                            <td>{dose.dosis_programada}</td>
                                            <td>{dose.dosis_real}</td>
                                            {/* <td>{parseVnDate(dose.fecha_registro)}</td> */}
                                            <td>
                                                {dose.fecha_registracion
                                                    ? dose.fecha_registracion
                                                    : '-'}
                                            </td>
                                            <td>
                                                {getVariations(
                                                    dose.dosis_programada,
                                                    dose.dosis_real,
                                                )}
                                            </td>
                                            <td>
                                                {dose.fecha_registracion && dose.fecha
                                                    ? getDateOffset(
                                                          new Date(dose.fecha_registracion),
                                                          new Date(dose.fecha),
                                                      )
                                                    : '-'}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </>
    );
};

export default ProgramedDoses;
