export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_RESPONSE = 'GET_TRANSACTIONS_RESPONSE';
export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR';

export const GET_TRANSACTIONS_BY_FILTERS = 'GET_TRANSACTIONS_BY_FILTERS';
export const GET_TRANSACTIONS_BY_FILTERS_RESPONSE = 'GET_TRANSACTIONS_BY_FILTERS_RESPONSE';
export const GET_TRANSACTIONS_BY_FILTERS_ERROR = 'GET_TRANSACTIONS_BY_FILTERS_ERROR';

export const PUT_TRANSACTIONS_APLICATIONS = 'PUT_TRANSACTIONS_APLICATIONS';
export const PUT_TRANSACTIONS_APLICATIONS_RESPONSE = 'PUT_TRANSACTIONS_APLICATIONS_RESPONSE';
export const PUT_TRANSACTIONS_APLICATIONS_ERROR = 'PUT_TRANSACTIONS_APLICATIONS_ERROR';

export const GET_PROGRAMMED_DOSES = 'GET_PROGRAMMED_DOSES';
export const GET_PROGRAMMED_DOSES_RESPONSE = 'GET_PROGRAMMED_DOSES_RESPONSE';
export const GET_PROGRAMMED_DOSES_ERROR = 'GET_PROGRAMMED_DOSES_ERROR';

export const GET_OS_AUDIT_TRANSACTIONS = 'GET_OS_AUDIT_TRANSACTIONS';
export const GET_OS_AUDIT_TRANSACTIONS_RESPONSE = 'GET_OS_AUDIT_TRANSACTIONS_RESPONSE';
export const GET_OS_AUDIT_TRANSACTIONS_ERROR = 'GET_OS_AUDIT_TRANSACTIONS_ERROR';

export const GET_AUDITOR_TRANSACTIONS = 'GET_AUDITOR_TRANSACTIONS';
export const GET_AUDITOR_TRANSACTIONS_RESPONSE = 'GET_AUDITOR_TRANSACTIONS_RESPONSE';
export const GET_AUDITOR_TRANSACTIONS_ERROR = 'GET_AUDITOR_TRANSACTIONS_ERROR';

export const POST_AUDITOR_TRANSACTIONS = 'POST_AUDITOR_TRANSACTIONS';
export const POST_AUDITOR_TRANSACTIONS_RESPONSE = 'POST_AUDITOR_TRANSACTIONS_RESPONSE';
export const POST_AUDITOR_TRANSACTIONS_ERROR = 'POST_AUDITOR_TRANSACTIONS_ERROR';

export const GET_SHARED_RISK_TRANSACTIONS = 'GET_SHARED_RISK_TRANSACTIONS';
export const GET_SHARED_RISK_TRANSACTIONS_RESPONSE = 'GET_SHARED_RISK_TRANSACTIONS_RESPONSE';
export const GET_SHARED_RISK_TRANSACTIONS_ERROR = 'GET_SHARED_RISK_TRANSACTIONS_ERROR';

export const UPDATE_SHARED_RISK_TRANSACTIONS = 'UPDATE_SHARED_RISK_TRANSACTIONS';
export const UPDATE_SHARED_RISK_TRANSACTIONS_RESPONSE = 'UPDATE_SHARED_RISK_TRANSACTIONS_RESPONSE';
export const UPDATE_SHARED_RISK_TRANSACTIONS_ERROR = 'UPDATE_SHARED_RISK_TRANSACTIONS_ERROR';

export const SET_AUDITOR_PATIENT_DETAIL = 'SET_AUDITOR_PATIENT_DETAIL';

export const GET_NEX_MONITOR_TRANSACTIONS = 'GET_NEX_MONITOR_TRANSACTIONS';
export const GET_NEX_MONITOR_TRANSACTIONS_RESPONSE = 'GET_NEX_MONITOR_TRANSACTIONS_RESPONSE';
export const GET_NEX_MONITOR_TRANSACTIONS_ERROR = 'GET_NEX_MONITOR_TRANSACTIONS_ERROR';

export const GET_GRANTING_MONITOR_ADDRESSES = 'GET_GRANTING_MONITOR_ADDRESSES';
export const GET_GRANTING_MONITOR_ADDRESSES_RESPONSE = 'GET_GRANTING_MONITOR_ADDRESSES_RESPONSE';
export const GET_GRANTING_MONITOR_ADDRESSES_ERROR = 'GET_GRANTING_MONITOR_ADDRESSES_ERROR';

export const GET_TRANSACTION_MONITOR_TRANSACTIONS = 'GET_TRANSACTION_MONITOR_TRANSACTIONS';
export const GET_TRANSACTION_MONITOR_TRANSACTIONS_RESPONSE =
    'GET_TRANSACTION_MONITOR_TRANSACTIONS_RESPONSE';
export const GET_TRANSACTION_MONITOR_TRANSACTIONS_ERROR =
    'GET_TRANSACTION_MONITOR_TRANSACTIONS_ERROR';

export const UPDATE_TRANSACTIONS_PAGE = 'UPDATE_TRANSACTIONS_PAGE';
export const UPDATE_TRANSACTIONS_PAGE_SIZE = 'UPDATE_TRANSACTIONS_PAGE_SIZE';

export const UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE = 'UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE';
export const UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE_SIZE =
    'UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE_SIZE';

export const UPDATE_GRANTING_MONITOR_PAGE = 'UPDATE_GRANTING_MONITOR_PAGE';
export const UPDATE_GRANTING_MONITOR_PAGE_SIZE = 'UPDATE_GRANTING_MONITOR_PAGE_SIZE';

export const UPDATE_TRANSACTION_MONITOR_PAGE = 'UPDATE_TRANSACTION_MONITOR_PAGE';
export const UPDATE_TRANSACTION_MONITOR_PAGE_SIZE = 'UPDATE_TRANSACTION_MONITOR_PAGE_SIZE';

export const UPDATE_TRANSACTIONS_FILTER_VALUES = 'UPDATE_TRANSACTIONS_FILTER_VALUES';
export const CLEAR_TRANSACTIONS_FILTERS = 'CLEAR_TRANSACTIONS_FILTERS';

export const UPDATE_NEX_MONITOR_TRANSACTIONS_FILTER_VALUES =
    'UPDATE_NEX_MONITOR_TRANSACTIONS_FILTER_VALUES';

export const UPDATE_GRANTING_MONITOR_ADDRESSES_FILTER_VALUES =
    'UPDATE_GRANTING_MONITOR_ADDRESSES_FILTER_VALUES';
export const UPDATE_TRANSACTION_MONITOR_TRANSACTIONS_FILTER_VALUES =
    'UPDATE_TRANSACTION_MONITOR_TRANSACTIONS_FILTER_VALUES';

export const SEARCH_TRANSACTIONS = 'SEARCH_TRANSACTIONS';

export const SEARCH_OS_AUDIT_TRANSACTIONS = 'SEARCH_OS_AUDIT_TRANSACTIONS';

export const SEARCH_AUDITOR_TRANSACTIONS = 'SEARCH_AUDITOR_TRANSACTIONS';

export const SEARCH_NEX_MONITOR_TRANSACTIONS = 'SEARCH_NEX_MONITOR_TRANSACTIONS';

export const SEARCH_GRANTING_MONITOR_ADDRESSES = 'SEARCH_GRANTING_MONITOR_ADDRESSES';
export const SEARCH_TRANSACTION_MONITOR_TRANSACTIONS = 'SEARCH_TRANSACTION_MONITOR_TRANSACTIONS';

export const SEARCH_SHARED_RISK_TRANSACTIONS = 'SEARCH_SHARED_RISK_TRANSACTIONS';

export const UPDATE_FILTERED_TRANSACTIONS_BY_DATE_RANGE =
    'UPDATE_FILTERED_TRANSACTIONS_BY_DATE_RANGE';
export const CLEAR_DATE_RANGE_SELECTION = 'CLEAR_DATE_RANGE_SELECTION';

export const UPDATE_NEX_MONITOR_FILTERED_TRANSACTIONS_BY_DATE_RANGE =
    'UPDATE_NEX_MONITOR_FILTERED_TRANSACTIONS_BY_DATE_RANGE';
export const CLEAR_NEX_MONITOR_DATE_RANGE_SELECTION = 'CLEAR_NEX_MONITOR_DATE_RANGE_SELECTION';

export const VALIDATE_TRANSACTIONS = 'VALIDATE_TRANSACTIONS';
export const VALIDATE_TRANSACTIONS_RESPONSE = 'VALIDATE_TRANSACTIONS_RESPONSE';
export const VALIDATE_TRANSACTIONS_ERROR = 'VALIDATE_TRANSACTIONS_ERROR';

export const REJECT_TRANSACTIONS = 'REJECT_TRANSACTIONS';
export const REJECT_TRANSACTIONS_RESPONSE = 'REJECT_TRANSACTIONS_RESPONSE';
export const REJECT_TRANSACTIONS_ERROR = 'REJECT_TRANSACTIONS_ERROR';

export const VALIDATE_PAYMENTS = 'VALIDATE_PAYMENTS';
export const VALIDATE_PAYMENTS_RESPONSE = 'VALIDATE_PAYMENTS_RESPONSE';
export const VALIDATE_PAYMENTS_ERROR = 'VALIDATE_PAYMENTS_ERROR';

export const PAY = 'PAY';
export const PAY_RESPONSE = 'PAY_RESPONSE';
export const PAY_ERROR = 'PAY_ERROR';

export const TRACK_PAYMENTS_ERRORS = 'TRACK_PAYMENTS_ERRORS';
export const TRACK_PAYMENTS_ERRORS_RESPONSE = 'TRACK_PAYMENTS_ERRORS_RESPONSE';
export const TRACK_PAYMENTS_ERRORS_ERROR = 'TRACK_PAYMENTS_ERRORS_ERROR';

export const SEND_DATA_TO_SAP = 'SEND_DATA_TO_SAP';
export const SEND_DATA_TO_SAP_RESPONSE = 'SEND_DATA_TO_SAP_RESPONSE';
export const SEND_DATA_TO_SAP_ERROR = 'SEND_DATA_TO_SAP_ERROR';

export const GRANT_ADDRESSES = 'GRANT_ADDRESSES';
export const GRANT_ADDRESSES_RESPONSE = 'GRANT_ADDRESSES_RESPONSE';
export const GRANT_ADDRESSES_ERROR = 'GRANT_ADDRESSES_ERROR';

export const DOWNLOAD_OS_TRANSACTIONS = 'DOWNLOAD_OS_TRANSACTIONS';
export const DOWNLOAD_OS_TRANSACTIONS_RESPONSE = 'DOWNLOAD_OS_TRANSACTIONS_RESPONSE';
export const DOWNLOAD_OS_TRANSACTIONS_ERROR = 'DOWNLOAD_OS_TRANSACTIONS_ERROR';

export const DOWNLOAD_AUDITOR_TRANSACTIONS = 'DOWNLOAD_AUDITOR_TRANSACTIONS';
export const DOWNLOAD_AUDITOR_TRANSACTIONS_RESPONSE = 'DOWNLOAD_AUDITOR_TRANSACTIONS_RESPONSE';
export const DOWNLOAD_AUDITOR_TRANSACTIONS_ERROR = 'DOWNLOAD_AUDITOR_TRANSACTIONS_ERROR';

export const DOWNLOAD_ROCHE_TRANSACTIONS = 'DOWNLOAD_ROCHE_TRANSACTIONS';
export const DOWNLOAD_ROCHE_TRANSACTIONS_RESPONSE = 'DOWNLOAD_ROCHE_TRANSACTIONS_RESPONSE';
export const DOWNLOAD_ROCHE_TRANSACTIONS_ERROR = 'DOWNLOAD_ROCHE_TRANSACTIONS_ERROR';

export const DOWNLOAD_NEX_MONITOR_TRANSACTIONS = 'DOWNLOAD_NEX_MONITOR_TRANSACTIONS';
export const DOWNLOAD_NEX_MONITOR_TRANSACTIONS_RESPONSE =
    'DOWNLOAD_NEX_MONITOR_TRANSACTIONS_RESPONSE';
export const DOWNLOAD_NEX_MONITOR_TRANSACTIONS_ERROR = 'DOWNLOAD_NEX_MONITOR_TRANSACTIONS_ERROR';

export const DOWNLOAD_GRANTING_MONITOR_ADDRESSES = 'DOWNLOAD_GRANTING_MONITOR_ADDRESSES';
export const DOWNLOAD_GRANTING_MONITOR_ADDRESSES_RESPONSE =
    'DOWNLOAD_GRANTING_MONITOR_ADDRESSES_RESPONSE';
export const DOWNLOAD_GRANTING_MONITOR_ADDRESSES_ERROR =
    'DOWNLOAD_GRANTING_MONITOR_ADDRESSES_ERROR';

export const GET_PATIENT_AUDITOR = 'GET_PATIENT_AUDITOR';
export const GET_PATIENT_AUDITOR_RESPONSE = 'GET_PATIENT_AUDITOR_RESPONSE';
export const GET_PATIENT_AUDITOR_ERROR = 'GET_PATIENT_AUDITOR_ERROR';

export const GET_BALANCE_NEX = 'GET_BALANCE_NEX';
export const GET_BALANCE_NEX_RESPONSE = 'GET_BALANCE_NEX_RESPONSE';
export const GET_BALANCE_NEX_ERROR = 'GET_BALANCE_NEX_ERROR';

export const SEND_NEX = 'SEND_NEX';
export const SEND_NEX_RESPONSE = 'SEND_NEX_RESPONSE';
export const SEND_NEX_ERROR = 'SEND_NEX_ERROR';

export const SEND_NEX_MULTIPLE_NEX = 'SEND_NEX_MULTIPLE_NEX';
export const SEND_NEX_MULTIPLE_RESPONSE = 'SEND_NEX_MULTIPLE_RESPONSE';
export const SEND_NEX_MULTIPLE_ERROR = 'SEND_NEX_MULTIPLE_ERROR';

export const GET_WHITE_LISTED_ADDRESSES = 'GET_WHITE_LISTED_ADDRESSES';
export const GET_WHITE_LISTED_ADDRESSES_RESPONSE = 'GET_WHITE_LISTED_ADDRESSES_RESPONSE';
export const GET_WHITE_LISTED_ADDRESSES_ERROR = 'GET_WHITE_LISTED_ADDRESSES_ERROR';

export const CLEAR_TRANSACTIONS_UI = 'CLEAR_TRANSACTIONS_UI';

export const MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS = 'MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS';
export const MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS_RESPONSE =
    'MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS_RESPONSE';
export const MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS_ERROR =
    'MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS_ERROR';

export default {
    getTransactions: (filter) => ({ type: GET_TRANSACTIONS, ...filter }),
    getTransactionsResponse: (response) => ({ type: GET_TRANSACTIONS_RESPONSE, response }),
    getTransactionsError: (error) => ({ type: GET_TRANSACTIONS_ERROR, error }),

    getTransactionsByFilters: (filter, page, search, startDate, endDate, order) => ({
        type: GET_TRANSACTIONS_BY_FILTERS,
        ...filter,
        page,
        search,
        startDate,
        endDate,
        order,
    }),
    getTransactionsByFiltersResponse: (response) => ({
        type: GET_TRANSACTIONS_BY_FILTERS_RESPONSE,
        response,
    }),
    getTransactionsByFiltersError: (error) => ({ type: GET_TRANSACTIONS_BY_FILTERS_ERROR, error }),

    putTransactionsAplications: (body) => ({ type: PUT_TRANSACTIONS_APLICATIONS, body }),
    putTransactionsAplicationsResponse: (response) => ({
        type: PUT_TRANSACTIONS_APLICATIONS_RESPONSE,
        response,
    }),
    putTransactionsAplicationsError: (error) => ({
        type: PUT_TRANSACTIONS_APLICATIONS_ERROR,
        error,
    }),

    getOsAuditTransactions: (id) => ({ type: GET_OS_AUDIT_TRANSACTIONS, id }),
    getOsAuditTransactionsResponse: (response) => ({
        type: GET_OS_AUDIT_TRANSACTIONS_RESPONSE,
        response,
    }),
    getOsAuditTransactionsError: (error) => ({ type: GET_OS_AUDIT_TRANSACTIONS_ERROR, error }),

    getAuditorTransactions: (response) => ({ type: GET_AUDITOR_TRANSACTIONS, response }),
    getAuditorTransactionsResponse: (response) => ({
        type: GET_AUDITOR_TRANSACTIONS_RESPONSE,
        response,
    }),
    getAuditorTransactionsError: (error) => ({ type: GET_AUDITOR_TRANSACTIONS_ERROR, error }),

    getSharedRiskTransactions: (response) => ({ type: GET_SHARED_RISK_TRANSACTIONS, response }),
    getSharedRiskTransactionsResponse: (response) => ({
        type: GET_SHARED_RISK_TRANSACTIONS_RESPONSE,
        response,
    }),
    getSharedRiskTransactionsError: (error) => ({
        type: GET_SHARED_RISK_TRANSACTIONS_ERROR,
        error,
    }),

    postAuditorTransactions: (response, id) => ({ type: POST_AUDITOR_TRANSACTIONS, response, id }),
    postAuditorTransactionsResponse: (response) => ({
        type: POST_AUDITOR_TRANSACTIONS_RESPONSE,
        response,
    }),
    postAuditorTransactionsError: (error) => ({ type: POST_AUDITOR_TRANSACTIONS_ERROR, error }),

    updateSharedRiskTransactions: (response, id) => ({
        type: UPDATE_SHARED_RISK_TRANSACTIONS,
        response,
        id,
    }),
    updateSharedRiskTransactionsResponse: (response) => ({
        type: UPDATE_SHARED_RISK_TRANSACTIONS_RESPONSE,
        response,
    }),
    updateSharedRiskTransactionsError: (error) => ({
        type: UPDATE_SHARED_RISK_TRANSACTIONS_ERROR,
        error,
    }),

    setAuditorPatientDetail: (patient) => ({ type: SET_AUDITOR_PATIENT_DETAIL, patient }),

    getProgrammedDoses: (body) => ({ type: GET_PROGRAMMED_DOSES, body }),
    getProgrammedDosesResponse: (response) => ({ type: GET_PROGRAMMED_DOSES_RESPONSE, response }),
    getProgrammedDosesError: (error) => ({ type: GET_PROGRAMMED_DOSES_ERROR, error }),

    getNexMonitorTransactions: (filter) => ({ type: GET_NEX_MONITOR_TRANSACTIONS, ...filter }),
    getNexMonitorTransactionsResponse: (response) => ({
        type: GET_NEX_MONITOR_TRANSACTIONS_RESPONSE,
        response,
    }),
    getNexMonitorTransactionsError: (error) => ({
        type: GET_NEX_MONITOR_TRANSACTIONS_ERROR,
        error,
    }),

    getGrantingMonitorAddresses: (filter) => ({ type: GET_GRANTING_MONITOR_ADDRESSES, ...filter }),
    getGrantingMonitorAddressesResponse: (response) => ({
        type: GET_GRANTING_MONITOR_ADDRESSES_RESPONSE,
        response,
    }),
    getGrantingMonitorAddressesError: (error) => ({
        type: GET_GRANTING_MONITOR_ADDRESSES_ERROR,
        error,
    }),

    getTransactionMonitorTransactions: (filter) => ({
        type: GET_TRANSACTION_MONITOR_TRANSACTIONS,
        ...filter,
    }),
    getTransactionMonitorTransactionsResponse: (response) => ({
        type: GET_TRANSACTION_MONITOR_TRANSACTIONS_RESPONSE,
        response,
    }),
    getTransactionMonitorTransactionsError: (error) => ({
        type: GET_TRANSACTION_MONITOR_TRANSACTIONS_ERROR,
        error,
    }),

    updatePage: (pageNumber) => ({ type: UPDATE_TRANSACTIONS_PAGE, pageNumber }),
    updatePageSize: (pageSize) => ({ type: UPDATE_TRANSACTIONS_PAGE_SIZE, pageSize }),

    updateNexMonitorPage: (pageNumber) => ({
        type: UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE,
        pageNumber,
    }),
    updateNexMonitorPageSize: (pageSize) => ({
        type: UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE_SIZE,
        pageSize,
    }),

    updateGrantingMonitorPage: (pageNumber) => ({ type: UPDATE_GRANTING_MONITOR_PAGE, pageNumber }),
    updateGrantingMonitorPageSize: (pageSize) => ({
        type: UPDATE_GRANTING_MONITOR_PAGE_SIZE,
        pageSize,
    }),

    updateTransactionMonitorPage: (pageNumber) => ({
        type: UPDATE_TRANSACTION_MONITOR_PAGE,
        pageNumber,
    }),
    updateTransactionMonitorPageSize: (pageSize) => ({
        type: UPDATE_TRANSACTION_MONITOR_PAGE_SIZE,
        pageSize,
    }),

    updateFilterValues: (filter) => ({ type: UPDATE_TRANSACTIONS_FILTER_VALUES, filter }),
    clearFilters: () => ({ type: CLEAR_TRANSACTIONS_FILTERS }),

    updateNexMonitorFilterValues: (filter) => ({
        type: UPDATE_NEX_MONITOR_TRANSACTIONS_FILTER_VALUES,
        filter,
    }),

    updateGrantingMonitorFilterValues: (filter) => ({
        type: UPDATE_GRANTING_MONITOR_ADDRESSES_FILTER_VALUES,
        filter,
    }),

    updateTransactionMonitorFilterValues: (filter) => ({
        type: UPDATE_TRANSACTION_MONITOR_TRANSACTIONS_FILTER_VALUES,
        filter,
    }),

    searchTransactions: (search) => ({ type: SEARCH_TRANSACTIONS, search }),
    searchSharedRiskTransactions: (search) => ({ type: SEARCH_SHARED_RISK_TRANSACTIONS, search }),
    searchOsAuditTransactions: (search) => ({ type: SEARCH_OS_AUDIT_TRANSACTIONS, search }),

    searchAuditorTransactions: (search) => ({ type: SEARCH_AUDITOR_TRANSACTIONS, search }),

    searchNexMonitorTransactions: (search) => ({ type: SEARCH_NEX_MONITOR_TRANSACTIONS, search }),

    searchGrantingMonitorAddresses: (search) => ({
        type: SEARCH_GRANTING_MONITOR_ADDRESSES,
        search,
    }),
    searchTransactionMonitorTransactions: (search) => ({
        type: SEARCH_TRANSACTION_MONITOR_TRANSACTIONS,
        search,
    }),

    updateFilteredTransactionsByDateRange: (newResults) => ({
        type: UPDATE_FILTERED_TRANSACTIONS_BY_DATE_RANGE,
        newResults,
    }),
    clearDateRangeSelection: (textToSearch) => ({ type: CLEAR_DATE_RANGE_SELECTION, textToSearch }),

    updateNexMonitorFilteredTransactionsByDateRange: (newResults) => ({
        type: UPDATE_NEX_MONITOR_FILTERED_TRANSACTIONS_BY_DATE_RANGE,
        newResults,
    }),
    clearNexMonitorDateRangeSelection: (textToSearch) => ({
        type: CLEAR_NEX_MONITOR_DATE_RANGE_SELECTION,
        textToSearch,
    }),

    validateTransactions: (transactions) => ({ type: VALIDATE_TRANSACTIONS, transactions }),
    validateTransactionsResponse: (response) => ({
        type: VALIDATE_TRANSACTIONS_RESPONSE,
        response,
    }),
    /*
     * note: the validate transaction error receives a 'response' because the validation is always successful,
     * the errors are contain on each transaction.
     * */
    validateTransactionsError: (response) => ({ type: VALIDATE_TRANSACTIONS_ERROR, response }),

    rejectTransactions: (transactions) => ({ type: REJECT_TRANSACTIONS, transactions }),
    rejectTransactionsResponse: (response) => ({ type: REJECT_TRANSACTIONS_RESPONSE, response }),
    /*
     * note: the reject transaction error receives a 'response' because the reject is always successful,
     * the errors are contain on each transaction.
     * */
    rejectTransactionsError: (response) => ({ type: REJECT_TRANSACTIONS_ERROR, response }),

    validatePayments: (transactionsIds) => ({ type: VALIDATE_PAYMENTS, transactionsIds }),
    validatePaymentsResponse: (response) => ({ type: VALIDATE_PAYMENTS_RESPONSE, response }),
    validatePaymentsError: (response) => ({ type: VALIDATE_PAYMENTS_ERROR, response }),

    sendMultipleNex: (transactions) => ({ type: SEND_NEX_MULTIPLE_NEX, transactions }),
    sendMultipleNexResponse: (response) => ({ type: SEND_NEX_MULTIPLE_RESPONSE, response }),
    sendMultipleNexError: (error) => ({ type: SEND_NEX_MULTIPLE_ERROR, error }),

    pay: (transactions) => ({ type: PAY, transactions }),
    payResponse: (response) => ({ type: PAY_RESPONSE, response }),
    payError: (response) => ({ type: PAY_ERROR, response }),

    trackPaymentsErrors: (transactionsIds) => ({ type: TRACK_PAYMENTS_ERRORS, transactionsIds }),
    trackPaymentsErrorsResponse: (response) => ({ type: TRACK_PAYMENTS_ERRORS_RESPONSE, response }),
    trackPaymentsErrorsError: (response) => ({ type: TRACK_PAYMENTS_ERRORS_ERROR, response }),

    sendDataToSAP: (transactions, isRetry) => ({ type: SEND_DATA_TO_SAP, transactions, isRetry }),
    sendDataToSAPResponse: (response, isRetry) => ({
        type: SEND_DATA_TO_SAP_RESPONSE,
        response,
        isRetry,
    }),
    sendDataToSAPError: (response, isRetry) => ({
        type: SEND_DATA_TO_SAP_ERROR,
        response,
        isRetry,
    }),

    grantAddresses: (addresses) => ({ type: GRANT_ADDRESSES, addresses }),
    grantAddressesResponse: (response) => ({ type: GRANT_ADDRESSES_RESPONSE, response }),
    grantAddressesError: (response) => ({ type: GRANT_ADDRESSES_ERROR, response }),

    downloadOSTransactions: () => ({ type: DOWNLOAD_OS_TRANSACTIONS }),
    downloadOSrTransactionsResponse: () => ({ type: DOWNLOAD_OS_TRANSACTIONS_RESPONSE }),
    downloadOSTransactionsError: (error) => ({ type: DOWNLOAD_OS_TRANSACTIONS_ERROR, error }),

    downloadAuditorTransactions: () => ({ type: DOWNLOAD_AUDITOR_TRANSACTIONS }),
    downloadAuditorTransactionsResponse: () => ({ type: DOWNLOAD_AUDITOR_TRANSACTIONS_RESPONSE }),
    downloadAuditorTransactionsError: (error) => ({
        type: DOWNLOAD_AUDITOR_TRANSACTIONS_ERROR,
        error,
    }),

    downloadRocheTransactions: (transactionType, sortedFilteredResults) => ({
        type: DOWNLOAD_ROCHE_TRANSACTIONS,
        transactionType,
        sortedFilteredResults,
    }),
    downloadRocheTransactionsResponse: () => ({ type: DOWNLOAD_ROCHE_TRANSACTIONS_RESPONSE }),
    downloadRocheTransactionsError: (error) => ({ type: DOWNLOAD_ROCHE_TRANSACTIONS_ERROR, error }),

    downloadNexMonitorTransactions: () => ({ type: DOWNLOAD_NEX_MONITOR_TRANSACTIONS }),
    downloadNexMonitorTransactionsResponse: () => ({
        type: DOWNLOAD_NEX_MONITOR_TRANSACTIONS_RESPONSE,
    }),
    downloadNexMonitorTransactionsError: (error) => ({
        type: DOWNLOAD_NEX_MONITOR_TRANSACTIONS_ERROR,
        error,
    }),

    downloadGrantingMonitorAddresses: () => ({ type: DOWNLOAD_GRANTING_MONITOR_ADDRESSES }),
    downloadGrantingMonitorAddressesResponse: () => ({
        type: DOWNLOAD_GRANTING_MONITOR_ADDRESSES_RESPONSE,
    }),
    downloadGrantingMonitorAddressesError: (error) => ({
        type: DOWNLOAD_GRANTING_MONITOR_ADDRESSES_ERROR,
        error,
    }),

    getPatientAuditor: (id) => ({ type: GET_PATIENT_AUDITOR, id }),
    getPatientAuditorResponse: (response) => ({ type: GET_PATIENT_AUDITOR_RESPONSE, response }),
    getPatientAuditorError: (error) => ({ type: GET_PATIENT_AUDITOR_ERROR, error }),

    getBalanceNex: (addresses) => ({ type: GET_BALANCE_NEX, addresses }),
    getBalanceNexResponse: (response) => ({ type: GET_BALANCE_NEX_RESPONSE, response }),
    getBalanceNexError: (error) => ({ type: GET_BALANCE_NEX_ERROR, error }),

    getWhiteListedAddresses: () => ({ type: GET_WHITE_LISTED_ADDRESSES }),
    getWhiteListedAddressesResponse: (response) => ({
        type: GET_WHITE_LISTED_ADDRESSES_RESPONSE,
        response,
    }),
    getWhiteListedAddressesError: (error) => ({ type: GET_WHITE_LISTED_ADDRESSES_ERROR, error }),

    migrateAuditorTransactionComments: (payload) => ({
        type: MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS,
        payload,
    }),
    migrateAuditorTransactionCommentsResponse: () => ({
        type: MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS_RESPONSE,
    }),
    migrateAuditorTransactionCommentsError: () => ({
        type: MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS_ERROR,
    }),

    clearUI: () => ({ type: CLEAR_TRANSACTIONS_UI }),
};
