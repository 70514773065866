import React, { useEffect, useState } from 'react';
import Card from '../../../common/components/Card/Card';
import VersionContainer from './Version';
import Input from '../../../common/components/Input/Input';
import CustomSelect from '../../../common/components/CustomSelect/CustomSelect';
import './Contract.scss';
import Button from '../../../common/components/Button';
import { IndStatusModal } from '../../../indication/components/IndicationsScreen/IndicationsScreen';
import { I18n } from 'react-redux-i18n';
import { zeroPad } from '../../../utils/various';

const ContractDetails = ({
    osList,
    indications,
    contract: { id, descripcion, obra_social, tipo_contrato, indicacion },
    updContract,
    contractTypes,
    showErrors,
    isSur,
    contractTypeId,
    setVersion,
}) => {
    const readOnly = setVersion.cant_txns > 0;

    return (
        <Card
            className='side-padding-m'
            title={I18n.t('contract.cardHeaders.titleContract')}
            detail={id ? I18n.t('contract.cardHeaders.titleNumber') + ': ' + zeroPad(id, 2) : ''}
        >
            <div className='card-row'>
                <div className='cb-mt'>
                    <Input
                        disabled={readOnly}
                        className='white'
                        value={descripcion}
                        handleChange={(v) => updContract({ descripcion: v })}
                        label={I18n.t('contract.inputLabels.name')}
                        error={showErrors && !descripcion}
                    />
                </div>
                <div className='cb-mt'>
                    <div key='input-label'>
                        <label className='input-label'>{I18n.t('contract.inputLabels.os')}</label>
                    </div>
                    <CustomSelect
                        disabled={readOnly}
                        options={osList
                            ?.map((os) => ({
                                value: os?.id,
                                label: os?.razon_social,
                                publicKey: os?.pubkey,
                            }))
                            ?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        value={obra_social}
                        onSelect={(v) => updContract({ obra_social: v })}
                        placeholder=''
                        error={showErrors && !obra_social}
                        className='rounded i-screen mxh'
                        searchable={true}
                    />
                </div>
                <div className='cb-mt'>
                    <div key='input-label'>
                        <label className='input-label'>
                            {I18n.t('contract.inputLabels.contractType')}
                        </label>
                    </div>
                    <CustomSelect
                        disabled={readOnly}
                        options={contractTypes
                            ?.map((type) => ({
                                value: type.id,
                                label: type.descripcion,
                                key: type.tipo_contrato,
                            }))
                            ?.sort((a, b) =>
                                a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
                            )}
                        value={contractTypeId}
                        onSelect={(newContractTypeId) => {
                            const foundContract = contractTypes.find(
                                (c) => c.id === newContractTypeId,
                            );
                            if (foundContract) {
                                updContract({
                                    tipo_contrato: foundContract.id,
                                    tipo_contrato_descr: foundContract.descripcion,
                                    tipo_contrato_tipo: foundContract.tipo_contrato,
                                });
                            }
                        }}
                        placeholder=''
                        error={showErrors && !tipo_contrato}
                        className='rounded i-screen'
                    />
                </div>
                {!isSur && (
                    <div className='cb-mt'>
                        <div key='input-label'>
                            <label className='input-label'>
                                {I18n.t('contract.inputLabels.indications')}
                            </label>
                        </div>
                        <CustomSelect
                            options={indications
                                ?.map((indication) => ({
                                    value: indication?.id,
                                    label: indication?.titulo,
                                    publicKey: `key${indication?.id}`,
                                }))
                                ?.sort((a, b) =>
                                    a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
                                )}
                            value={indicacion}
                            placeholder=''
                            onSelect={(v) => updContract({ indicacion: v, contrato_version: [] })}
                            error={showErrors && !indicacion}
                            disabled={id != undefined || readOnly}
                            className='rounded i-screen mxh'
                            searchable={true}
                        />
                    </div>
                )}
            </div>
        </Card>
    );
};

const Contract = ({
    selectedContract,
    setContractStatus,
    putContract,
    postContract,

    osList,
    getOsList,
    indications,
    getIndications,
    auditorGroups,
    getAuditorGroups,

    selectedIndication,

    // lo mio
    getFamilyProducts,
    getProdFamily,
    getContractTypes,
    contractTypes,
    setIndicationSelected,
    cleanContractTypes,
}) => {
    const [contract, setContract] = useState({});
    const updContract = (obj) => setContract({ ...contract, ...obj });
    const [showState, setShowState] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [changeVersion, setChangeVersion] = useState({});

    const isSur = contract.tipo_contrato_tipo === 'S';

    useEffect(() => {
        setContract({
            ...contract,
            ...selectedContract,
            contrato_version:
                selectedContract?.contrato_version?.map((ver) => {
                    const contrato_v_evidencia =
                        ver.contrato_v_evidencia?.map((ev) => ({ ...ev, _id: ev.id })) ?? [];

                    const contrato_v_beneficio = ver.contrato_v_beneficio?.map((ben) => ({
                        ...ben,
                        _id: ben.id,
                        groupId:
                            ben.aplicacion_desde +
                            ' ' +
                            ben.aplicacion_hasta +
                            ' ' +
                            ben.familia_producto,
                    }));

                    contrato_v_beneficio.sort((a, b) => {
                        if (a.aplicacion_desde < b.aplicacion_desde) return -1;
                        if (a.aplicacion_desde > b.aplicacion_desde) return 1;
                        if (a.aplicacion_hasta < b.aplicacion_hasta) return -1;
                        if (a.aplicacion_hasta > b.aplicacion_hasta) return 1;
                        if (a.familia_producto < b.familia_producto) return -1;
                        if (a.familia_producto > b.familia_producto) return 1;
                        if (a.producto < b.producto) return -1;
                        if (a.producto > b.producto) return 1;
                        return 0;
                    });

                    return {
                        ...ver,
                        _id: ver.id,
                        contrato_v_evidencia,
                        contrato_v_beneficio,
                    };
                }) ?? [],
        });
    }, [selectedContract, selectedIndication]);

    useEffect(() => {
        cleanContractTypes();
        setIndicationSelected({});
        getOsList();
        // getIndications();
        getAuditorGroups();

        // Lo mio
        //getFamilyProducts();
        getContractTypes();
        getProdFamily();
    }, []);

    const submitContract = (type) => {
        let vCounter = 0;
        const durationString = I18n.t(
            'indication.details.indication.durationTypes.' + selectedIndication?.tipo_duracion,
        );
        let prevVigencia = null;
        for (const ver of contract?.contrato_version ?? []) {
            vCounter++;
            let gId = 'inv_gid';
            let dateCounter = -1;
            if (prevVigencia && new Date(prevVigencia) >= new Date(ver.vigencia_desde)) {
                type === 'post' && postContract({});
                type === 'put' && putContract({});
                setShowState({
                    message: `Error en las fechas en version ${vCounter}.\n Debe seleccionar una fecha de vigencia mayor a la versión anterior`,
                });
                return;
            }
            prevVigencia = ver.vigencia_desde;
            if (!ver.vigencia_desde) {
                type === 'post' && postContract({});
                type === 'put' && putContract({});
                setShowState({
                    message: `Error en las fechas en version ${vCounter}.\n Debe seleccionar la fecha de inicio de vigencia`,
                });
                return;
            }
            if (!ver.fin_incorp_pacientes) {
                type === 'post' && postContract({});
                type === 'put' && putContract({});
                setShowState({
                    message: `Error en las fechas en version ${vCounter}.\n Debe seleccionar la fecha de fin de alta de pacientes`,
                });
                return;
            }
            if (!ver.vigencia_hasta) {
                type === 'post' && postContract({});
                type === 'put' && putContract({});
                setShowState({
                    message: `Error en las fechas en version ${vCounter}.\n Debe seleccionar la fecha de fin de registracion`,
                });
                return;
            }
            if (!ver.max_pacientes || ver.max_pacientes <= 0) {
                type === 'post' && postContract({});
                type === 'put' && putContract({});
                setShowState({
                    message: `Error en maxima cantidad de pacientes en version ${vCounter}.\n La maxima cantidad de pacientes debe ser mayor a 0`,
                });
                return;
            }
            if (contract.tipo_contrato_tipo !== 'S') {
                if (ver.tolerancia_periodo == null) {
                    type === 'post' && postContract({});
                    type === 'put' && putContract({});
                    setShowState({
                        message: `Error en tolerancia en version ${vCounter}.\n Debe seleccionar una tolerancia (periodo de control)`,
                    });
                    return;
                }
                if (ver.tolerancia_dosis == null) {
                    type === 'post' && postContract({});
                    type === 'put' && putContract({});
                    setShowState({
                        message: `Error en tolerancia en version ${vCounter}.\n Debe seleccionar una tolerancia (dosis mg)`,
                    });
                    return;
                }
                if (ver.tolerancia_dosis > 100) {
                    type === 'post' && postContract({});
                    type === 'put' && putContract({});
                    setShowState({
                        message: `Error en tolerancia en version ${vCounter}.\n La tolerancia no puede ser mayor a 100%`,
                    });
                    return;
                }
                if (!ver.grupo_auditor) {
                    type === 'post' && postContract({});
                    type === 'put' && putContract({});
                    setShowState({
                        message: `Error en auditor en version ${vCounter}.\n Debe seleccionar un grupo de auditores`,
                    });
                    return;
                }
                if (!ver.compartir_nya && !ver.compartir_dni && !ver.compartir_afiliado) {
                    type === 'post' && postContract({});
                    type === 'put' && putContract({});
                    setShowState({
                        message: `Error en auditor en version ${vCounter}.\n Debe seleccionar al menos un campo a compartir con el auditor`,
                    });
                    return;
                }
            }
            for (const ben of ver.contrato_v_beneficio ?? []) {
                if (ben.groupId !== gId) {
                    gId = ben.groupId;
                    if (
                        ben.aplicacion_desde < dateCounter ||
                        ben.aplicacion_hasta < ben.aplicacion_desde
                    ) {
                        type === 'post' && postContract({});
                        type === 'put' && putContract({});
                        setShowState({
                            message: `Error en ${durationString} de productos en version ${vCounter}.\n ${durationString} superpuestas o invalidas`,
                        });
                        return;
                    }
                    if (isNaN(ben.beneficio)) {
                        type === 'post' && postContract({});
                        type === 'put' && putContract({});
                        setShowState({
                            message: `Error en el beneficio de productos en version ${vCounter}.\n Debe ingresar el beneficio`,
                        });
                        return;
                    }
                    if (
                        ben.aplicacion_desde > selectedIndication.duracion ||
                        ben.aplicacion_hasta > selectedIndication.duracion
                    ) {
                        type === 'post' && postContract({});
                        type === 'put' && putContract({});
                        setShowState({
                            message: `Error en el beneficio de productos en version ${vCounter}.\n Fuera de rango`,
                        });
                        return;
                    }
                    dateCounter = ben.aplicacion_hasta;
                }
                if (ben.tipo_beneficio !== 'F' && !ben.producto_ref) {
                    type === 'post' && postContract({});
                    type === 'put' && putContract({});
                    setShowState({
                        message: `Error en el beneficio de productos en version ${vCounter}.\n Debe ingresar producto de referencia`,
                    });
                    return;
                }
            }

            if (ver.contrato_v_evidencia?.some((x) => !x.tipo_evidencia)) {
                type === 'post' && postContract({});
                type === 'put' && putContract({});
                setShowState({
                    message: `Error en evidencias de la version ${vCounter}.\n Error evidencia sin tipo de descripción.\n`,
                });
                return;
            }

            if (ver.contrato_v_evidencia?.some((x) => !x.descripcion)) {
                type === 'post' && postContract({});
                type === 'put' && putContract({});
                setShowState({
                    message: `Error en evidencias de la version ${vCounter}.\n Error evidencia sin descripción.\n`,
                });
                return;
            }

            const search = ver.contrato_v_evidencia?.reduce((acc, ind) => {
                acc[ind.aplicacion] = ++acc[ind.aplicacion] || 0;
                return acc;
            }, {});

            const duplicates = ver.contrato_v_evidencia?.filter((ind) => {
                return search[ind.aplicacion];
            });

            if (duplicates?.length > 0) {
                type === 'post' && postContract({});
                type === 'put' && putContract({});
                setShowState({
                    message: `Error en evidencias de la version ${vCounter}.\n Error evidencias con mismo ciclo.\n`,
                });
                return;
            }
            if (ver.contrato_v_evidencia?.some((x) => x.aplicacion > selectedIndication.duracion)) {
                type === 'post' && postContract({});
                type === 'put' && putContract({});
                setShowState({
                    message: `Error en evidencias de la version ${vCounter}.\n Error evidencia con ciclo fuera de rango. El límite es ${selectedIndication?.duracion}.`,
                });
                return;
            }
        }

        type === 'post' && postContract(contract);
        type === 'put' && putContract(contract);

        setShowState({ message: undefined });
    };

    return (
        <div className='i-container'>
            <ContractDetails
                osList={osList}
                indications={indications}
                contract={contract}
                updContract={updContract}
                showErrors={showErrors}
                contractTypes={contractTypes}
                isSur={isSur}
                contractTypeId={contract.tipo_contrato}
                setVersion={changeVersion}
            />

            <VersionContainer
                indication={contract.indicacion}
                versions={contract.contrato_version}
                updVersions={(vers) => updContract({ contrato_version: vers })}
                auditorGroups={auditorGroups}
                showErrors={showErrors}
                selectedIndication={selectedIndication}
                getIndications={getIndications}
                isSur={isSur}
                lastVersion={contract.version_actual}
                typeContractSelected={contract.tipo_contrato}
                setVersion={(value) => setChangeVersion({ cant_txns: value })}
            />

            {contract?.id ? (
                <Button
                    type='button'
                    className='primary small f-right'
                    text={I18n.t('contract.buttons.save')}
                    onClick={() => submitContract('put')}
                />
            ) : (
                <Button
                    type='button'
                    className='primary small f-right'
                    text={I18n.t('contract.buttons.new')}
                    onClick={() => submitContract('post')}
                />
            )}

            <IndStatusModal
                show={showState}
                setShow={setShowState}
                status={setContractStatus}
                setShowErrors={setShowErrors}
            />
        </div>
    );
};

export default Contract;
