import {
    VALIDATE_PRODUCT,
    VALIDATE_PRODUCT_RESPONSE,
    VALIDATE_PRODUCT_ERROR,
    REGISTER_PRODUCTS,
    REGISTER_PRODUCTS_RESPONSE,
    REGISTER_PRODUCTS_ERROR,
    DOWNLOAD_REGISTER_TRANSACTIONS,
    DOWNLOAD_REGISTER_TRANSACTIONS_RESPONSE,
    DOWNLOAD_REGISTER_TRANSACTIONS_ERROR,
    GET_ALL_PRODUCTS_BY_ID,
    GET_ALL_PRODUCTS_BY_ID_RESPONSE,
    GET_ALL_PRODUCTS_BY_ID_ERROR,
} from './registration.actions';
import { REHYDRATE } from 'redux-persist';
import {
    LOGOUT,
    RESTORE_PASSWORD,
    RESTORE_PASSWORD_ERROR,
    RESTORE_PASSWORD_RESPONSE,
} from '../session/session.actions';

const initialState = {
    validationStatus: { error: false, loading: false, success: false, errorMessage: '' },
    registerProductsStatus: { error: false, loading: false, success: false, errorMessage: '' },
    downloadTransactionsStatus: { error: false, loading: false, success: false, errorMessage: '' },
    getProductsStatus: { error: false, loading: false, success: false, errorMessage: '' },
    getAllProductsStatus: { error: false, loading: false, success: false, errorMessage: '' },
    productsForPatient: {},
    allProducts: [],
};

const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case VALIDATE_PRODUCT:
            return {
                ...state,
                validationStatus: { error: false, loading: true, success: false, errorMessage: '' },
            };
        case VALIDATE_PRODUCT_RESPONSE:
            return {
                ...state,
                validationStatus: { error: false, loading: false, success: true, errorMessage: '' },
            };
        case VALIDATE_PRODUCT_ERROR:
            return {
                ...state,
                validationStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case REGISTER_PRODUCTS:
            return {
                ...state,
                registerProductsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case REGISTER_PRODUCTS_RESPONSE:
            return {
                ...state,
                registerProductsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case REGISTER_PRODUCTS_ERROR:
            return {
                ...state,
                registerProductsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case DOWNLOAD_REGISTER_TRANSACTIONS:
            return {
                ...state,
                downloadTransactionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case DOWNLOAD_REGISTER_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                downloadTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case DOWNLOAD_REGISTER_TRANSACTIONS_ERROR:
            return {
                ...state,
                downloadTransactionsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case GET_ALL_PRODUCTS_BY_ID:
        case GET_ALL_PRODUCTS_BY_ID_RESPONSE:
        case GET_ALL_PRODUCTS_BY_ID_ERROR:
            return {
                ...state,
                allProducts:
                    action.type === GET_ALL_PRODUCTS_BY_ID_RESPONSE
                        ? action.response
                        : state.allProducts,
                getAllProductsStatus: {
                    loading: action.type === GET_ALL_PRODUCTS_BY_ID,
                    success: action.type === GET_ALL_PRODUCTS_BY_ID_RESPONSE,
                    error: action.type === GET_ALL_PRODUCTS_BY_ID_ERROR,
                    errorMessage: action.type === GET_ALL_PRODUCTS_BY_ID_ERROR ? action.error : '',
                },
            };
        case LOGOUT:
            return { ...initialState };
        case REHYDRATE:
            let incoming = action.payload;
            if (incoming) {
                return {
                    ...incoming.registration,
                    validationStatus: initialState.validationStatus,
                    registerProductsStatus: initialState.registerProductsStatus,
                    downloadTransactionsStatus: initialState.downloadTransactionsStatus,
                };
            } else {
                return state;
            }
        default:
            return state;
    }
};

export default registrationReducer;
