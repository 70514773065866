import React from 'react';
import PropTypes from 'prop-types';
import IconDelete from './Delete';
const UserIcon = ({ width, height, color1, color2 }) => (
    <svg
        width={width}
        height={height}
        viewBox='0 0 45 45'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M22.824 25.385h-.309c-4.125-.127-7.213-3.343-7.213-7.304 0-4.033 3.288-7.322 7.322-7.322 4.033 0 7.322 3.289 7.322 7.322-.018 3.98-3.125 7.177-7.068 7.304h-.054zm-.218-11.919a4.602 4.602 0 0 0-4.597 4.597 4.578 4.578 0 0 0 4.415 4.597c.055-.018.236-.018.418 0 2.434-.127 4.342-2.126 4.36-4.597a4.602 4.602 0 0 0-4.596-4.596z'
            fill='url(#nfenrv0wya)'
        />
        <path
            d='M22.606 42.137a19.46 19.46 0 0 1-13.173-5.123 1.372 1.372 0 0 1-.436-1.145c.236-2.162 1.58-4.179 3.816-5.669 5.414-3.597 14.19-3.597 19.586 0 2.234 1.508 3.579 3.507 3.815 5.669.055.436-.109.854-.436 1.145a19.46 19.46 0 0 1-13.172 5.123zM11.85 35.506a16.73 16.73 0 0 0 10.756 3.906c3.942 0 7.74-1.38 10.756-3.906-.327-1.109-1.2-2.18-2.49-3.053-4.47-2.98-12.046-2.98-16.552 0-1.29.872-2.143 1.944-2.47 3.053z'
            fill='url(#bqk6saznib)'
        />
        <path
            d='M22.606 42.137c-10.775 0-19.532-8.757-19.532-19.531 0-10.775 8.757-19.532 19.532-19.532 10.774 0 19.531 8.757 19.531 19.532 0 10.774-8.757 19.531-19.531 19.531zm0-36.338c-9.267 0-16.807 7.54-16.807 16.807 0 9.266 7.54 16.806 16.807 16.806 9.266 0 16.806-7.54 16.806-16.806 0-9.267-7.54-16.807-16.806-16.807z'
            fill='url(#tyur0mqykc)'
        />
        <defs>
            <linearGradient
                id='nfenrv0wya'
                x1='15.302'
                y1='10.759'
                x2='15.302'
                y2='25.385'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor={color1} />
                <stop offset='1' stopColor={color2} />
            </linearGradient>
            <linearGradient
                id='bqk6saznib'
                x1='8.992'
                y1='27.502'
                x2='8.992'
                y2='42.137'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor={color1} />
                <stop offset='1' stopColor={color2} />
            </linearGradient>
            <linearGradient
                id='tyur0mqykc'
                x1='3.074'
                y1='3.074'
                x2='3.074'
                y2='42.137'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor={color1} />
                <stop offset='1' stopColor={color2} />
            </linearGradient>
        </defs>
    </svg>
);
UserIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color1: PropTypes.string,
    color2: PropTypes.string,
};
export default UserIcon;
