import React, { useEffect } from 'react';
import './ContractList.scss';
import 'react-table/react-table.css'; // Default table styles
import { I18n } from 'react-redux-i18n';
import Button from '../../../common/components/Button';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import SearchBar from '../../../common/components/SearchBar';
import TablePagination from '../../../common/components/TablePagination';
import { Link, useHistory } from 'react-router-dom';

const ContractList = ({ contracts, getContracts, getContract, setContractSelected, ...props }) => {
    const [textToSearch, setTextToSearch] = React.useState('');

    useEffect(() => {
        getContracts();
        props.getOsList();
        props.getIndications();
    }, []);

    const osName = (osId) => {
        const name = osName.cache?.[osId];
        if (name) return name;

        const os = props.osList?.find((os) => os.id === osId);

        if (!osName.cache) osName.cache = {};
        osName.cache[osId] = os?.razon_social;

        return os ? os.razon_social : '';
    };

    const indicationName = (indicationId) => {
        const name = indicationName.cache?.[indicationId];
        if (name) return name;

        const indication = props.indications?.find((indication) => indication.id === indicationId);

        if (!indicationName.cache) indicationName.cache = {};
        indicationName.cache[indicationId] = indication?.titulo;

        return indication ? indication.titulo : '';
    };

    const history = useHistory();

    const renderRowButtons = (props) => (
        <div className='action-buttons-container'>
            <a
                data-tip={I18n.t('contract.table.buttonTooltips.edit')}
                data-for='actionButtonTooltip'
            >
                <i
                    className='icon-pencil'
                    onClick={() => {
                        getContract(props.id);
                        history.push('detail');
                    }}
                />
            </a>
            <ReactTooltip
                id='actionButtonTooltip'
                backgroundColor='#008e99'
                effect='solid'
                className='action-button-tooltip'
            />
            {/* <a data-tip={I18n.t(`contract.table.buttonTooltips.delete`)} data-for={"actionButtonTooltip"}>
                <i className={'icon-ban'} onClick={() => { onsole.log("props",props) }}/>
            </a>
            <ReactTooltip id={"actionButtonTooltip"} backgroundColor={"#008e99"} effect={"solid"} className={"action-button-tooltip"}/> */}
        </div>
    );

    const getTableColumns = () => {
        const commonData = {
            Cell: (props) => <span className='text'>{props.value}</span>,
            resizable: false,
            headerClassName: 'nexus-header center',
            className: 'nexus-cell center',
            style: { whiteSpace: 'unset' },
        };

        return [
            {
                ...commonData,
                id: '_id', // id: 'id' gets hidden
                Header: () => <h5>{I18n.t('contract.table.headers.id')}</h5>,
                accessor: (d) => d.id,
                width: 80,
            },
            {
                ...commonData,
                id: 'name',
                Header: () => <h5>{I18n.t('contract.table.headers.name')}</h5>,
                accessor: (d) => d.descripcion,
                width: 450,
            },
            {
                ...commonData,
                id: 'os',
                Header: () => <h5>{I18n.t('contract.table.headers.os')}</h5>,
                accessor: (d) => osName(d.obra_social),
            },
            {
                ...commonData,
                id: 'type',
                Header: () => <h5>{I18n.t('contract.table.headers.type')}</h5>,
                accessor: (d) => d.tipo_contrato_descr,
            },
            {
                ...commonData,
                id: 'indication',
                Header: () => <h5>{I18n.t('contract.table.headeontracts/listrs.indication')}</h5>,
                accessor: (d) => indicationName(d.indicacion),
            },
            {
                ...commonData,
                id: 'status',
                Header: () => <h5>{I18n.t('contract.table.headers.status')}</h5>,
                accessor: (d) => d.estado_version,
                Cell: (props) => (
                    <span className={`text status ${props.value}`}>
                        {props.value && I18n.t('contract.versionDetail.status.' + props.value)}
                    </span>
                ),
            },
            {
                ...commonData,
                id: 'actions',
                Header: () => <h5>{I18n.t('contract.table.headers.action')}</h5>,
                accessor: (d) => d.buttons,
                Cell: (props) => renderRowButtons(props.original),
                width: 80,
            },
        ];
    };

    return (
        // props.loading
        //     ?
        //     <div className="contracts-list">
        //         <div className={"header"}/>
        //         <div className="table-container">
        //             <Loader />
        //         </div>
        //     </div>
        //     :
        <div className='contracts-list'>
            <div className='header'>
                <span className='title'>{I18n.t('contract.title')}</span>
                <SearchBar
                    textToSearch={textToSearch}
                    onSearch={(text) => {
                        setTextToSearch(text);
                        props.searchContracts(text);
                    }}
                />
                <div className='search-button'>
                    <Link to='/contracts/detail' style={{ textDecoration: 'none' }}>
                        <Button
                            type='button'
                            className='primary small'
                            onClick={() => {
                                setContractSelected({});
                            }}
                            text={I18n.t('contract.createNew')}
                        />
                    </Link>
                </div>
            </div>
            <div className='table-container'>
                <ReactTable
                    className={contracts?.length > 0 ? 'nexus-table' : 'nexus-table empty'}
                    PaginationComponent={(paginationProps) => (
                        <TablePagination
                            {...paginationProps}
                            total={props.total}
                            totalPages={props.total / 10 /* TODO - MUTABLE PAGE SIZE */}
                            pagedMessageTranslation='contract.table.pagedMessage'
                            pagedMessageEmptyTranslation='contract.table.pagedMessageEmpty'
                        />
                    )}
                    minRows={0}
                    pageSize={10}
                    data={contracts}
                    noDataText={I18n.t('contract.table.noRows')}
                    // todo check if this prop is necessary
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: () => {},
                        };
                    }}
                    columns={getTableColumns()}
                />
            </div>
        </div>
    );
};

export default ContractList;
