import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
import SpinnerLoader from 'react-loader-spinner';

class Button extends Component {
    render() {
        const disabled = () => this.props.disabled || this.props.loading;

        return (
            <button
                type={this.props.type}
                className={`button ${disabled() ? ' disabled' : ''} ${
                    this.props.className ? this.props.className : ''
                }`}
                onClick={!disabled() ? this.props.onClick : null}
                disabled={disabled()}
            >
                {this.props.loading ? (
                    <SpinnerLoader type='ThreeDots' />
                ) : (
                    <div
                        className='button-body'
                        data-tip={this.props.dataTip}
                        data-for={this.props.dataFor}
                    >
                        {this.props.icon}
                        <span className={this.props.classText ?? 'button-text'}>
                            {this.props.text}
                        </span>
                    </div>
                )}
            </button>
        );
    }
}

Button.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.element,
    dataTip: PropTypes.string,
    dataFor: PropTypes.string,
};

export default Button;
