import { connect } from 'react-redux';
import AuditorAuditHistory from '../components/AuditorAuditHistory/AuditorAuditHistory';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    patientHistorial: state.transaction.auditorPatientDetail._original.audit_historial,
    patientDetail: state.transaction.auditorPatientDetail._original,
    role: state.profile.user.type,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuditorAuditHistory);
