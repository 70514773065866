import settings from '../settings/settings';
import axios from 'axios';
import actions from '../actions';
import store from '../store';

const httpClient = axios.create();
httpClient.defaults.timeout = 1200000;

export const webApi = settings.webApi;
export const storePKApi = settings.storePKApi;
export const sendPkByEmailApi = settings.sendPkByEmailApi;

export const walletExplorer = settings.walletExplorer;

export const bwsInstanceUrl = settings.bwsInstanceUrl;

let token = localStorage['token'];
let tokenRefresh = localStorage['token-refresh'];
let tokenTimestamp = localStorage['token-ts'] && parseInt(localStorage['token-ts']);
let refreshingToken = false;

const _request = (url, method, data, config = {}, withOutToken) => {
    refreshToken();
    const headers = withOutToken
        ? { ...config.headers }
        : { ...config.headers, Authorization: 'Bearer ' + token };
    if (withOutToken && method === 'GET') {
        return httpClient({ url, method })
            .then((res) => {
                if (res.status === 200 || res.status === 201 || res.status === 204) return res.data;
                else throw res.data;
            })
            .catch((errorResponse) => {
                if (
                    errorResponse.response.status === 401 &&
                    errorResponse.response.data &&
                    errorResponse.response.data.detail === 'Signature has expired.'
                ) {
                    // window.location.reload();
                }
                throw errorResponse.response || { status: 500 };
            });
    } else {
        if (config.noAuth || !token) delete headers.Authorization;
        return httpClient({ url, method, data, headers, ...config.options })
            .then((res) => {
                if (res.status === 200 || res.status === 201 || res.status === 204) return res.data;
                else throw res.data;
            })
            .catch((errorResponse) => {
                if (
                    errorResponse.response.status === 401 &&
                    errorResponse.response.data &&
                    errorResponse.response.data.detail === 'Signature has expired.'
                ) {
                    // window.location.reload();
                }
                throw errorResponse.response || { status: 500 };
            });
    }
};

const _basic_request = (url, method, data, headers) => {
    return httpClient({ url, method, data, headers })
        .then((res) => {
            if (res.status === 200 || res.status === 201 || res.status === 204) return res.data;
            else throw res.data;
        })
        .catch((errorResponse) => {
            throw errorResponse.response || { status: 500 };
        });
};

const _axios_post = (url, body, headers) => {
    return axios.post(url, body, headers).then(function (response) {});
    // axios({
    //     url,
    //     data: body,
    //     method: 'post',
    //     headers,
    //     withCredentials: false
    // })
    //     .then((response) => {
    //         return response;
    //     })
    //     .catch((error) => {
    //         return error;
    //     });
};

export const basic_get = (url, headers) =>
    _basic_request(url, 'GET', undefined, {
        'Content-Type': 'application/json',
        ...(headers ? headers : {}),
    });

export const get = (url, withOutToken) => _request(url, 'GET', {}, {}, withOutToken);

export const basic_post = (url, body) => _basic_request(url, 'POST', body);

export const axios_post = (url, body, headers) =>
    _axios_post(url, body, {
        headers: { 'Content-Type': 'application/json', ...(headers ? headers : {}) },
    });

export const post = (url, body, config = {}) => _request(url, 'POST', body, config);

export const put = (url, body, config = {}, withOutToken) =>
    _request(url, 'PUT', body, config, withOutToken);

export const patch = (url, body, config = {}) => _request(url, 'PATCH', body, config);
//
export const deleteRequest = (url, body, config = {}) => _request(url, 'DELETE', body, config);

export const services = {
    refreshToken: (refresh) => post(webApi + 'auth/jwt/refresh/', { refresh }),
};

const refreshToken = () => {
    /*
     * Note:
     * - MIN_TIME_TO_REFRESH_TOKEN is equal to 20 min (in milliseconds)
     * - MAX_TIME_TO_REFRESH_TOKEN is equal to 30 min (in milliseconds)
     * */
    const tokenNeedsToBeRefresh =
        !refreshingToken &&
        token &&
        tokenTimestamp &&
        Date.now() - tokenTimestamp > settings.MIN_TIME_TO_REFRESH_TOKEN &&
        Date.now() - tokenTimestamp < settings.MAX_TIME_TO_REFRESH_TOKEN;

    // log out the user if the token is greater MAX_TIME_TO_REFRESH_TOKEN
    const userNeedsToBeLoggedOut =
        !refreshingToken &&
        token &&
        tokenTimestamp &&
        Date.now() - tokenTimestamp > settings.MAX_TIME_TO_REFRESH_TOKEN;

    if (tokenNeedsToBeRefresh) {
        refreshingToken = true;
        services
            .refreshToken(tokenRefresh)
            .then((response) => {
                if (response.access) {
                    _saveToken({
                        token: response.access,
                        refreshToken: tokenRefresh,
                    });
                }
                refreshingToken = false;
            })
            .catch(() => (refreshingToken = false));
    } else if (userNeedsToBeLoggedOut) store.store.dispatch(actions.session.logout());
};

export const _saveToken = (responseToken) => {
    localStorage['token'] = responseToken.token;
    localStorage['token-refresh'] = responseToken.refreshToken;
    localStorage['token-ts'] = Date.now();
    token = responseToken.token;
    tokenRefresh = responseToken.refreshToken;
    tokenTimestamp = Date.now();
};

export const _clearToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('token-refresh');
    localStorage.removeItem('token-ts');
};
