import React from 'react';
import './DashboardScreen.scss';
import Button from '../../../common/components/Button';
import welcomeImage from '../../../assets/images/welcome-card.png';
import rocheLogo from '../../../assets/logos/roche-white-small.png';
import ScreenQuickAccessCard from '../../../common/components/ScreenQuickAccessCard';
import { I18n } from 'react-redux-i18n';
import PatientsQuickAccessImage from '../../../assets/images/quick-access-patients-image.jpg';
import TransactionsQuickAccessImage from '../../../assets/images/quick-access-transactions-image.jpg';
import settings from '../../../settings/settings';
import MigrationModal from '../MigrationModal/MigrationModal';
import { UserTypes } from '../../../constants';

const DashboardScreen = (props) => {
    const openInNewTab = (path) => window.open(path, '_blank');

    // Check for test migration modal with os 4. Add if necessary
    // (process.env.REACT_APP_TEST_MIGRATION_WITH_OS_4 ? props?.osId === 4 : true)
    const userType = props.userType === UserTypes.OS_USER;

    return (
        <>
            <MigrationModal
                migrationStatus={props.migrationStatus}
                migratePatients={props.migratePatients}
                userType={props.userType}
                osMigrated={props.osMigrated}
                logout={props.logout}
            />
            <div className='dashboard-screen'>
                <div className='information-container'>
                    <div className='welcome-card'>
                        <div className='left-column'>
                            <span className='title'>{I18n.t('dashboard.welcomeCard.title')}</span>
                            <Button
                                className='white bold'
                                text={I18n.t('dashboard.welcomeCard.button')}
                                onClick={() => openInNewTab(settings.nexusVideoPath)}
                            />
                            <a style={{ color: 'white' }} href='mailto:argentina.nexus@roche.com'>
                                {settings.contactEmail}
                            </a>
                        </div>
                        <div className='right-column'>
                            <img src={welcomeImage} alt='scientists' />
                        </div>
                    </div>
                    <div className='news-card'>
                        <img src={rocheLogo} alt='roche logo' />
                        <span className='title'>{I18n.t('dashboard.newsCard.title')}</span>
                        <div className='buttons'>
                            {props.userType === UserTypes.OS_USER && (
                                <Button
                                    className='small white bold'
                                    text={I18n.t('dashboard.docsButtons.osTutorial')}
                                    onClick={() => openInNewTab(settings.tutorialPathForOS)}
                                />
                            )}
                            {props.userType === UserTypes.DRUGSTORE && (
                                <Button
                                    className='small white bold'
                                    text={I18n.t('dashboard.docsButtons.drogTutorial')}
                                    onClick={() => openInNewTab(settings.tutorialPathForDrugstore)}
                                />
                            )}
                            {/* <Button
                                className='small white bold'
                                text={I18n.t('dashboard.newsCard.button3')}
                                onClick={() =>
                                    openInNewTab(
                                        props.userType === UserTypes.OS_USER
                                            ? settings.walletTutorialPathForOS
                                            : props.userType === UserTypes.DRUGSTORE
                                            ? settings.walletTutorialPathForDrugstore
                                            : '',
                                    )
                                }
                            /> */}
                        </div>
                    </div>
                </div>
                <div className='screen-links-container'>
                    <ScreenQuickAccessCard
                        title={I18n.t('dashboard.screenQuickAccessCard.abmPatients')}
                        redirectPath='/patients'
                        imageSrc={PatientsQuickAccessImage}
                    />
                    <ScreenQuickAccessCard
                        title={I18n.t('dashboard.screenQuickAccessCard.transactions')}
                        redirectPath='/transactions'
                        imageSrc={TransactionsQuickAccessImage}
                    />
                </div>
            </div>
        </>
    );
};

export default DashboardScreen;
