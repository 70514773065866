import PropTypes from 'prop-types';
import UserIcon from './UserIcon';
import React from 'react';
const OpenWallet = ({ width, height, color1, color2 }) => (
    <svg
        width={width}
        height={height}
        viewBox='0 0 42 42'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M27.2617 29.854H16.7383C14.4558 29.854 12.6042 28.0023 12.6042 25.7198V19.5507C12.6042 17.2682 14.4558 15.4165 16.7383 15.4165H27.2617C29.5442 15.4165 31.3958 17.2682 31.3958 19.5507V20.8707C31.3958 21.2465 31.0842 21.5582 30.7083 21.5582H28.8567C28.5358 21.5582 28.2425 21.6773 28.0317 21.8973L28.0225 21.9065C27.7658 22.154 27.6467 22.4932 27.6742 22.8415C27.7292 23.4465 28.3067 23.9323 28.9667 23.9323H30.7083C31.0842 23.9323 31.3958 24.244 31.3958 24.6198V25.7107C31.3958 28.0023 29.5442 29.854 27.2617 29.854ZM16.7383 16.7915C15.2167 16.7915 13.9792 18.029 13.9792 19.5507V25.7198C13.9792 27.2415 15.2167 28.479 16.7383 28.479H27.2617C28.7833 28.479 30.0208 27.2415 30.0208 25.7198V25.3165H28.9667C27.5825 25.3165 26.4092 24.2898 26.2992 22.9698C26.2258 22.2182 26.5008 21.4757 27.0508 20.9348C27.5275 20.449 28.1692 20.1832 28.8567 20.1832H30.0208V19.5507C30.0208 18.029 28.7833 16.7915 27.2617 16.7915H16.7383Z'
            fill='url(#paint0_linear_75_953)'
        />
        <path
            d='M13.2917 21.0635C12.9158 21.0635 12.6042 20.7518 12.6042 20.376V16.1869C12.6042 14.821 13.4658 13.5835 14.74 13.0977L22.0183 10.3477C22.77 10.0635 23.6042 10.1644 24.255 10.6227C24.915 11.081 25.3 11.8235 25.3 12.621V16.1044C25.3 16.4802 24.9883 16.7919 24.6125 16.7919C24.2367 16.7919 23.925 16.4802 23.925 16.1044V12.621C23.925 12.2727 23.76 11.9519 23.4667 11.7502C23.1733 11.5485 22.825 11.5027 22.495 11.631L15.2167 14.381C14.4742 14.6652 13.97 15.3894 13.97 16.1869V20.376C13.9792 20.761 13.6675 21.0635 13.2917 21.0635Z'
            fill='url(#paint1_linear_75_953)'
        />
        <path
            d='M28.9671 25.3169C27.583 25.3169 26.4096 24.2903 26.2996 22.9703C26.2263 22.2094 26.5013 21.4669 27.0513 20.9261C27.5188 20.4494 28.1605 20.1836 28.848 20.1836H30.7546C31.6621 20.2111 32.3588 20.9261 32.3588 21.8061V23.6944C32.3588 24.5744 31.6621 25.2894 30.7821 25.3169H28.9671ZM30.7363 21.5586H28.8571C28.5363 21.5586 28.243 21.6778 28.0321 21.8978C27.7663 22.1544 27.638 22.5028 27.6746 22.8511C27.7296 23.4561 28.3071 23.9419 28.9671 23.9419H30.7638C30.883 23.9419 30.993 23.8319 30.993 23.6944V21.8061C30.993 21.6686 30.883 21.5678 30.7363 21.5586Z'
            fill='url(#paint2_linear_75_953)'
        />
        <path
            d='M23.8333 20.6875H17.4167C17.0408 20.6875 16.7292 20.3758 16.7292 20C16.7292 19.6242 17.0408 19.3125 17.4167 19.3125H23.8333C24.2092 19.3125 24.5208 19.6242 24.5208 20C24.5208 20.3758 24.2092 20.6875 23.8333 20.6875Z'
            fill='url(#paint3_linear_75_953)'
        />
        <defs>
            <linearGradient
                id='paint0_linear_75_953'
                x1='22'
                y1='15.4165'
                x2='22'
                y2='29.854'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor={color1} />
                <stop offset='1' stopColor={color2} />
            </linearGradient>
            <linearGradient
                id='paint1_linear_75_953'
                x1='18.9521'
                y1='10.189'
                x2='18.9521'
                y2='21.0635'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor={color1} />
                <stop offset='1' stopColor={color2} />
            </linearGradient>
            <linearGradient
                id='paint2_linear_75_953'
                x1='29.3234'
                y1='20.1836'
                x2='29.3234'
                y2='25.3169'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor={color1} />
                {/* <stop stop-color={color1}/>{color2}"#057A83"/> */}
            </linearGradient>
            <linearGradient
                id='paint3_linear_75_953'
                x1='20.625'
                y1='19.3125'
                x2='20.625'
                y2='20.6875'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor={color1} />
                <stop offset='1' stopColor={color2} />
            </linearGradient>
        </defs>
    </svg>
);

export default OpenWallet;
OpenWallet.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color1: PropTypes.string,
    color2: PropTypes.string,
};
