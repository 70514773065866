import Loader from '../../../../common/components/Loader';
import ReactTable from 'react-table';
import { I18n } from 'react-redux-i18n';
import React, { useEffect } from 'react';
import { parseDate } from '../../../../utils';
import '../AccessTableScreen.scss';
import TableHeader from '../../../../common/components/TableHeader/TableHeader';
import DeleteCell from './DeleteCell';
import { isValidDate } from '../../../../utils/date';

const AccessTable = (props) => {
    useEffect(() => {
        props.getAccess(props.patientId);
    }, []);

    const getTableColumns = () => {
        return [
            {
                id: 'roleType',
                Header: () => (
                    <TableHeader text={I18n.t('roleAccess.accessTable.headers.roleType')} />
                ),
                accessor: (data) => data.tipo_rol, // Custom value accessors!
                Cell: (props) => <span className='text text-ellipsis'>{props.value ?? '-'}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset', alignItems: 'center' },
                resizable: true,
            },
            {
                id: 'id',
                Header: () => <TableHeader text={I18n.t('roleAccess.accessTable.headers.id')} />,
                accessor: (data) => data.id, // Custom value accessors!
                Cell: (props) => <span className='text text-ellipsis'>{props.value ?? '-'}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset', alignItems: 'center' },
                width: 100,
            },

            {
                id: 'description',
                Header: () => (
                    <TableHeader text={I18n.t('roleAccess.accessTable.headers.description')} />
                ),
                accessor: (data) => data.razon_social, // Custom value accessors!
                Cell: (props) => <span className='text text-ellipsis'>{props.value ?? '-'}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset', alignItems: 'center' },
                resizable: true,
            },
            {
                id: 'createdBy',
                Header: () => (
                    <TableHeader text={I18n.t('roleAccess.accessTable.headers.createdBy')} />
                ),
                accessor: (data) => data.creado_por, // Custom value accessors!
                Cell: (props) => <span className='text text-ellipsis'>{props.value ?? '-'}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset', alignItems: 'center' },
                resizable: true,
            },
            {
                id: 'date',
                Header: () => <TableHeader text={I18n.t('roleAccess.accessTable.headers.date')} />,
                accessor: (data) => data.fecha, // Custom value accessors!
                Cell: (props) => (
                    <span className='text text-ellipsis'>
                        {isValidDate(props.value) ? parseDate(new Date(props.value)) : '-'}
                    </span>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset', alignItems: 'center' },
                resizable: true,
            },
            {
                id: 'action',
                Header: () => (
                    <TableHeader text={I18n.t('roleAccess.accessTable.headers.action')} />
                ),
                accessor: (data) => data.original, // Custom value accessors!
                Cell: (data) => (
                    <DeleteCell
                        owner={props.owner}
                        id={data.original.id}
                        showModal={() => {
                            props.openDeleteModal({
                                accessId: data.original.id,
                                paciente: props.patientId,
                                drogueria: data.original.drogueria || undefined,
                                obra_social: data.original.obra_social || undefined,
                                grupo_auditor: data.original.grupo_auditor || undefined,
                            });
                        }}
                        setId={props.setId}
                        disable={data.original.editable}
                    />
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset', alignItems: 'center' },
                width: 100,
            },
        ];
    };
    return (
        <>
            {props.getAccessStatus.loading || props.access.length === 0 ? (
                <>
                    <div className='roche-transactions-table'>
                        <Loader />
                    </div>
                </>
            ) : (
                <div className='users-table'>
                    <ReactTable
                        className={props.access?.length > 0 ? 'nexus-table' : 'nexus-table empty'}
                        PaginationComponent={() => <div className='table-footer'></div>}
                        minRows={0}
                        pageSize={10}
                        data={props.access}
                        noDataText={I18n.t('roleAccess.accessTable.noData')}
                        getTdProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                },
                            };
                        }}
                        columns={getTableColumns()}
                        defaultSorted={[{ id: 'dates', desc: false }]}
                    />
                </div>
            )}
        </>
    );
};

export default AccessTable;
