import { patch, webApi, get, post, put } from '../utils/http';

export default {
    getIndications: (ind) => get(webApi + `api/indicacion/${ind ?? ''}`),
    putIndication: (ind) => put(webApi + `api/indicacion/${ind.id}/`, ind),
    postIndication: (ind) => post(webApi + `api/indicacion/`, ind),

    getProdFamily: () => get(webApi + `api/familiaprod/`),
    getFamilyProducts: (fam) => get(webApi + `api/familiaprod/${fam}/productos`),
    getEvidenceType: () => get(webApi + `api/tipoevidencia/`),
};
