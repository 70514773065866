import actions, {
    GET_INDICATIONS,
    PUT_INDICATION,
    POST_INDICATION,
    GET_PRODFAMILY,
    GET_FAMILY_PRODUCTS,
    GET_EVIDENCE_TYPE,
} from './indication.actions';
import services from './indication.services';

const indicationMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        switch (action.type) {
            case GET_INDICATIONS:
                services
                    .getIndications(action.ind)
                    .then((res) => {
                        dispatch(actions.getIndicationsResponse(res, action.ind));
                    })
                    .catch((err) => {
                        dispatch(actions.getIndicationsError(err, action.ind));
                    });
                break;
            case PUT_INDICATION:
                services
                    .putIndication(action.ind)
                    .then((res) => {
                        dispatch(actions.putIndicationResponse(res));
                    })
                    .catch((err) => {
                        dispatch(actions.putIndicationError(err));
                    });
                break;
            case POST_INDICATION:
                services
                    .postIndication(action.ind)
                    .then((res) => {
                        dispatch(actions.postIndicationResponse(res));
                    })
                    .catch((err) => {
                        dispatch(actions.postIndicationError(err));
                    });
                break;
            case GET_PRODFAMILY:
                services
                    .getProdFamily()
                    .then((res) => {
                        dispatch(actions.getProdFamilyResponse(res));

                        // GET ALL PRODUCTS OF EACH FAMILY
                        let promises = [];
                        if (action.getProducts) {
                            res.forEach((fam) => {
                                promises.push(services.getFamilyProducts(fam.id));
                            });
                            Promise.all(promises).then((_res) => {
                                const _products = res.map((p, i) => ({ ...p, products: _res[i] }));
                                dispatch(actions.getProdFamilyResponse(_products));
                            });
                        }
                    })
                    .catch((err) => {
                        dispatch(actions.getProdFamilyError(err));
                    });
                break;
            case GET_FAMILY_PRODUCTS:
                services
                    .getFamilyProducts(action.fam)
                    .then((res) => {
                        dispatch(actions.getFamilyProductsResponse(res, action.fam));
                    })
                    .catch((err) => {
                        dispatch(actions.getProdFamilyError(err));
                    });
                break;
            case GET_EVIDENCE_TYPE:
                services
                    .getEvidenceType()
                    .then((res) => {
                        dispatch(actions.getEvidenceTypeResponse(res));
                    })
                    .catch((err) => {
                        dispatch(actions.getEvidenceTypeError(err));
                    });
                break;
            default:
                break;
        }
    };
export default indicationMiddleware;
