import React, { useState, useEffect } from 'react';
import './OSTransactionsSreen.scss';
// components
import SearchBar from '../../../common/components/SearchBar';
import SelectMultiple from '../../../common/components/SelectMultiple';
import DateRangePicker from '../../../common/components/DateRangePicker';
import Checkbox from '../../../common/components/Checkbox';
import Button from '../../../common/components/Button';
// Translations
import { I18n } from 'react-redux-i18n';
// React Table
import ReactTable from 'react-table';
import TablePagination from '../../../common/components/TablePagination';
import Loader from '../../../common/components/Loader';
import ReactTooltip from 'react-tooltip';
// Modals
import { ConfirmationModal } from '../../../common/components/Modal';
import TransactionsSuccessModal from '../TransactionsSuccessModal';
import TransactionsErrorModal from '../TransactionsErrorModal';
// Utils
import { parseDate, parseTransactionStatus } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import CycleCell from '../../containers/CycleCell';

const defaultStatusSelected = [
    { id: 'all', checked: false },
    { id: 'validated', checked: true },
    { id: 'pending', checked: true },
    { id: 'rejected', checked: false },
    { id: 'posted', checked: false },
    { id: 'errorSAP', checked: true },
    { id: 'validationError', checked: true },
    { id: 'processingPayment', checked: true },
];

const resultsInitialState = {
    allSuccessful: false,
    successfulTotal: 0,
    errorTotal: 0,
    errors: [],
};

const OSTransactionsScreen = (props) => {
    const [textToSearch, setTextToSearch] = useState('');
    const [transactionsSelected, setTransactionsSelected] = useState([]);
    const [statusFilter, setStatusFilter] = useState(defaultStatusSelected);
    const [validationResults, setValidationResults] = useState(resultsInitialState);
    const [rejectionResults, setRejectionResults] = useState(resultsInitialState);
    const [showDosesId, setShowDosesId] = useState(null);

    const [showConfirmRejectionModal, setShowConfirmRejectionModal] = useState(false);

    const [transactions, setTransactions] = useState([]);

    const updTransaction = (transaction) => {
        setTransactions(
            transactions.map((t) => (t.id === transaction.id ? { ...t, ...transaction } : t)),
        );
    };
    useEffect(() => {
        props.transactions && setTransactions(props.transactions);
    }, [props.transactions]);

    useEffect(() => {
        const allAreSelected = allFiltersAreSelected();
        const allAreDeselected = allFiltersAreDeselected();
        const isTheAllOptionChecked = statusFilter.filter((status) => status.id === 'all')[0]
            .checked;

        // this is done to auto check the 'all' checkbox if all the other options are selected (and vise versa)
        if (
            (allAreSelected && !isTheAllOptionChecked) ||
            (allAreDeselected && isTheAllOptionChecked)
        ) {
            setStatusFilter(
                statusFilter.map((status) => {
                    if (status.id === 'all') return { ...status, checked: !isTheAllOptionChecked };
                    return status;
                }),
            );
        }

        const statusSelectedArray = statusFilter
            .filter((status) => status.id !== 'all' && status.checked)
            .map((status) => status.id);

        props.updateFilterValues({ status: statusSelectedArray });
        props.getTransactions({ statusFilter });
        // eslint-disable-next-line
    }, [statusFilter]);

    useEffect(() => {
        if (props.validateTransactionsStatus.success || props.validateTransactionsStatus.error) {
            const mappedResults = mapResults(props.validationResults);
            setValidationResults(mappedResults);
        }
    }, [props.validateTransactionsStatus]);

    useEffect(() => {
        if (props.rejectTransactionsStatus.success || props.rejectTransactionsStatus.error) {
            const mappedResults = mapResults(props.rejectionResults);
            setRejectionResults(mappedResults);
        }
    }, [props.rejectTransactionsStatus]);

    /*
     * Maps the transaction rejection or validation results (quantity of success, of errors, and the errors strings).
     * */
    const mapResults = (transactions) => {
        let transactionsErrored = transactions.filter((t) => t.errors.length !== 0);

        if (transactionsErrored.length === 0)
            return { ...resultsInitialState, allSuccessful: true };

        let transactionsSuccessfulCount = transactions.length - transactionsErrored.length;

        const mapTransactionErrors = (transactionId, errors) =>
            errors.map((error) =>
                transactionId ? `ID ${transactionId} - ${error.description}` : error,
            );

        return {
            allSuccessful: false,
            successfulTotal: transactionsSuccessfulCount,
            errorTotal: transactionsErrored.length,
            errors: transactionsErrored.map((t) => mapTransactionErrors(t.id, t.errors)).flat(),
        };
    };

    const allFiltersAreSelected = () => {
        return (
            statusFilter.filter((status) => status.id !== 'all' && status.checked).length ===
            statusFilter.length - 1
        );
    };

    const allFiltersAreDeselected = () => {
        return (
            statusFilter.filter((status) => status.id !== 'all' && !status.checked).length ===
            statusFilter.length - 1
        );
    };

    const handleStatusFilterChange = (statusId) => {
        if (statusId === 'all') {
            const allIsSelected = statusFilter.filter((status) => status.id === 'all')[0].checked;
            setStatusFilter(
                statusFilter.map((status) => ({ id: status.id, checked: !allIsSelected })),
            );
        } else {
            const otherStatus = statusFilter.filter((status) => status.id !== statusId);
            const selectedStatus = statusFilter.filter((status) => status.id === statusId)[0];
            setStatusFilter([...otherStatus, { id: statusId, checked: !selectedStatus?.checked }]);
        }
    };

    const renderTableFooter = (paginationProps) => {
        return (
            <div className='transaction-table-footer'>
                <Button
                    type='button'
                    className='primary small'
                    onClick={props.downloadTransactions}
                    loading={props.downloadStatus.loading}
                    text={I18n.t('transaction.download')}
                    icon={<i className='icon-cloud-download' />}
                />
                <TablePagination
                    {...paginationProps}
                    total={props.total}
                    totalPages={props.totalPages}
                    pagedMessageTranslation='transaction.table.pagedMessage'
                    pagedMessageEmptyTranslation='transaction.table.pagedMessageEmpty'
                />
            </div>
        );
    };

    const renderCheckBox = (rowData) => {
        return (
            <div className='action-checkbox-container'>
                <Checkbox
                    id={rowData.id.toString()}
                    checked={isCheckboxSelected(rowData.id.toString())}
                    onChange={handleCheckboxChange}
                    disabled={
                        rowData.status === 'rejected' ||
                        rowData.status === 'paid' ||
                        rowData.status === 'validated' ||
                        rowData.status === 'errorSAP'
                    }
                />
            </div>
        );
    };

    const handleCheckboxChange = (checkboxId, checked) => {
        if (checkboxId === 'ALL') {
            if (checked) {
                setTransactionsSelected([
                    ...props.transactions
                        .filter(
                            (t) =>
                                t.status !== 'rejected' &&
                                t.status !== 'paid' &&
                                t.status !== 'validated',
                        )
                        .map((t) => `${t.id.toString()}`),
                    'ALL',
                ]);
            } else setTransactionsSelected([]);
        } else {
            const filtered = transactionsSelected.filter(
                (transactionId) => transactionId === checkboxId,
            );
            if (checked) {
                // if the checkbox was checked and the checkbox id is not on transactionsSelected, add to it
                if (filtered.length === 0)
                    setTransactionsSelected([...transactionsSelected, `${checkboxId}`]);
            } else {
                // if the checkbox was deselected and the checkbox id is on transactionsSelected, remove from it
                if (filtered.length === 1)
                    setTransactionsSelected([
                        ...transactionsSelected.filter(
                            (transactionId) => transactionId !== checkboxId,
                        ),
                    ]);
            }
        }
    };

    const isCheckboxSelected = (checkboxId) => {
        const filtered = transactionsSelected.filter(
            (transactionId) => transactionId === checkboxId,
        );
        return filtered.length === 1;
    };

    const renderDate = (dates, date) => {
        const parseDateRow = (date) => {
            const stringDate = parseDate(new Date(date.created_at));
            const stringStatus = I18n.t(
                `transaction.table.status.${parseTransactionStatus(date.estado_txn)}`,
            );
            return `${stringDate} ${stringStatus}`;
        };

        const allDatesByStatus = `${dates
            .map((date) => `<span>${parseDateRow(date)}<span/><br>`)
            .join('')}`;

        return (
            <div>
                <a data-tip={allDatesByStatus} data-html={true} data-for='dateTooltip'>
                    <span className='text'>{parseDate(new Date(date))}</span>
                </a>
                <ReactTooltip
                    id='dateTooltip'
                    backgroundColor='#ffffff'
                    effect='solid'
                    className='transaction-tooltip paragraph'
                />
            </div>
        );
    };

    const renderStatus = (status, errors) => {
        const translatedStatus = I18n.t(`transaction.table.status.${status}`);
        // if the status is different from "error", only render the span with the status
        if (status !== 'validationError' && status !== 'errorSAP') {
            return (
                <div className='status-container'>
                    <span className={`text status ${status.toString().toLowerCase()}`}>
                        {translatedStatus}
                    </span>
                </div>
            );
        }
        // if the status is an "error", also render the tooltip with the errors description
        const allErrors = `${errors
            .map((error) => `<span>ID ${error.errorCode} - ${error.description}<span/><br>`)
            .join('')}`;
        return (
            <div className='status-container'>
                <div
                    className='error-container'
                    data-tip={allErrors}
                    data-html={true}
                    data-for='statusTooltip'
                >
                    <span className={`text status ${status.toString().toLowerCase()}`}>
                        {translatedStatus}
                    </span>
                    {/*<span className={"status-error-indicator"} />*/}
                </div>
                <ReactTooltip
                    id='statusTooltip'
                    backgroundColor='#ffffff'
                    effect='solid'
                    className='transaction-tooltip paragraph'
                />
            </div>
        );
    };

    const getSelectedTransactions = () => {
        const transactionSelectedIds = transactionsSelected.filter((t) => t !== 'ALL');
        return props.transactions.filter((t) => transactionSelectedIds.includes(t.id.toString()));
    };

    const validateSelectedTransactions = () => {
        const selectedTransactions = getSelectedTransactions();
        if (selectedTransactions.length > 0) {
            props.validateTransactions(getSelectedTransactions());
            setTransactionsSelected([]);
        }
    };

    const rejectSelectedTransactions = () => {
        const selectedTransactions = getSelectedTransactions();
        if (selectedTransactions.length > 0) {
            props.rejectTransactions(getSelectedTransactions());
            setTransactionsSelected([]);
        }
    };

    const infoModal = () => {
        return (
            <>
                <h5>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{
                            color: '#008e99',
                            fontSize: '2.25rem',
                            margin: '0 1.75rem',
                        }}
                    />
                    {I18n.t('transaction.table.status.definition')}
                </h5>
                <hr />
                <ul className='list os-transactions'>
                    <li>
                        <h5>{I18n.t('transaction.table.status.validationError')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.errorSAP')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.posted')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.paid')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.pending')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.processingPayment')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.rejected')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.validated')}</h5>
                    </li>
                </ul>
            </>
        );
    };

    const formatHeader = (text, id, infoIcon = false, modal) => {
        return (
            <div className='nexus-header-arrows top space-between'>
                <h5 style={{ width: '100%', textAlign: 'left' }}>
                    {text}&nbsp;
                    {infoIcon && <FontAwesomeIcon data-tip data-for={id} icon={faInfoCircle} />}
                </h5>
                <ReactTooltip
                    id={id}
                    place='bottom'
                    type='light'
                    effect='solid'
                    className='info-modal'
                >
                    {modal}
                </ReactTooltip>
                <div className='arrows mt'>
                    <span>▲</span>
                    <span>▼</span>
                </div>
            </div>
        );
    };

    const getTableColumns = () => {
        // here could be some logic to show certain columns depending on user role.
        return [
            {
                id: 'id', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transaction.table.headers.id')}</h5>,
                accessor: (d) => d.id, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.original.id}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 100,
            },
            {
                id: 'dni', // Required because our accessor is not a string
                Header: () => formatHeader(I18n.t('transaction.table.headers.dni'), 'header_dni'),
                accessor: (d) => d.dni, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header between',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'name', // Required because our accessor is not a string
                Header: () => formatHeader(I18n.t('transaction.table.headers.name'), 'header_name'),
                accessor: (d) => d.name, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header between',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'affiliate', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('transaction.table.headers.affiliate'), 'header_affiliate'),
                accessor: (d) => d.affiliate, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header between',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'contract', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('transaction.table.headers.contracts'), 'header_contracts'),
                accessor: (d) => d.contract?.descripcion, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header between',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'product', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transaction.table.headers.product')}</h5>,
                accessor: (d) => d.denomination, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'dates', // Required because our accessor is not a string
                Header: () => formatHeader(I18n.t('transaction.table.headers.date'), 'header_date'),
                // accessor: (d) => new Date(d.dates[d.dates.length - 1].created_at), // Get the last date
                Cell: ({ original }) => renderDate(original.dates, original.updated_at),
                resizable: false,
                headerClassName: 'nexus-header between',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'cycle', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('transaction.table.headers.cycle'), 'header_cycle'),
                accessor: (d) => d,
                Cell: ({ original }) => (
                    <CycleCell
                        row={original}
                        updTransaction={updTransaction}
                        putTransactionsAplications={props.putTransactionsAplications}
                        showDosesId={showDosesId}
                        setShowDosesId={setShowDosesId}
                    />
                ),
                resizable: false,
                headerClassName: 'nexus-header between',
                className: 'nexus-cell overflow center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'tokenAmount', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('rocheTransaction.table.headers.tokenAmount')}</h5>,
                accessor: (data) => parseFloat(data.tokenAmount), // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value.toFixed(2)}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'status', // Required because our accessor is not a string
                Header: (props) =>
                    formatHeader(
                        I18n.t('transaction.table.headers.status'),
                        'header_status',
                        true,
                        infoModal(),
                    ),
                accessor: (d) => d.status,
                Cell: ({ original }) => renderStatus(original.status, original.errors),
                resizable: false,
                headerClassName: 'nexus-header extra-div',
                className: 'nexus-cell overflow center',
                style: { whiteSpace: 'unset' },
                width: 150,
            },
            {
                id: 'id', // Required because our accessor is not a string
                Header: () => (
                    <div className='header-with-checkbox'>
                        <h5>{I18n.t('transaction.table.headers.action')}</h5>
                        <Checkbox
                            id='ALL'
                            checked={isCheckboxSelected('ALL')}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                ),
                accessor: (d) => d.buttons, // Custom value accessors!
                sortable: false,
                Cell: (props) => renderCheckBox(props.original),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
        ];
    };

    return (
        <div className='os-transactions-screen'>
            <div className='header'>
                <span className='title'>{I18n.t('transaction.title')}</span>
                <SearchBar
                    textToSearch={textToSearch}
                    onSearch={(text) => {
                        setTextToSearch(text);
                        props.searchTransactions(text);
                    }}
                />
                <DateRangePicker
                    allTransactions={props.allTransactions}
                    textToSearch={textToSearch}
                    searchText={(text) => {
                        props.searchTransactions(text);
                    }}
                    updateFilteredTransactionsByDateRange={(newResults) => {
                        props.updateFilteredTransactionsByDateRange(newResults);
                    }}
                    clearDateRangeSelection={() => props.clearDateRangeSelection(textToSearch)}
                />
                <SelectMultiple
                    values={statusFilter}
                    onChange={handleStatusFilterChange}
                    className='rounded'
                    placeholder={I18n.t('transaction.table.headers.status')}
                    options={[
                        {
                            value: 'validationError',
                            label: I18n.t('transaction.table.status.validationError'),
                        },
                        { value: 'pending', label: I18n.t('transaction.table.status.pending') },
                        { value: 'rejected', label: I18n.t('transaction.table.status.rejected') },
                        { value: 'validated', label: I18n.t('transaction.table.status.validated') },
                        { value: 'posted', label: I18n.t('transaction.table.status.posted') },
                        {
                            value: 'processingPayment',
                            label: I18n.t('transaction.table.status.processingPayment'),
                        },
                        { value: 'errorSAP', label: I18n.t('transaction.table.status.errorSAP') },
                        { value: 'all', label: I18n.t('transaction.table.status.all') },
                    ]}
                />
                <div className='buttons'>
                    <Button
                        type='button'
                        className='primary small'
                        onClick={validateSelectedTransactions}
                        text={I18n.t('transaction.validate')}
                    />
                    <Button
                        type='button'
                        className='error small'
                        text={I18n.t('transaction.reject')}
                        onClick={() => {
                            if (getSelectedTransactions().length > 0)
                                setShowConfirmRejectionModal(true);
                        }}
                    />
                </div>
            </div>
            <div className='transaction-table-container'>
                {props.loading ? (
                    <>
                        <div className='header' />
                        <div className='transaction-table-container'>
                            <Loader />
                        </div>
                    </>
                ) : (
                    <ReactTable
                        className={
                            props?.transactions?.length > 0
                                ? 'nexus-table overflow-visible'
                                : 'nexus-table empty'
                        }
                        PaginationComponent={(paginationProps) =>
                            renderTableFooter(paginationProps)
                        }
                        minRows={0}
                        pageSize={10}
                        data={transactions}
                        noDataText={I18n.t('transaction.table.noRows')}
                        onSortedChange={(newSorted, column, shiftKey) => {
                            // props.updateFilterValues({ordering: (newSorted[0].desc ? "-" : "") + column.id});
                            // props.getTransactions();
                        }}
                        // todo check if this prop is necessary
                        getTdProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                },
                            };
                        }}
                        columns={getTableColumns()}
                        defaultSorted={[{ id: 'dates', desc: false }]}
                    />
                )}
            </div>
            {/* Modals */}
            <TransactionsSuccessModal
                show={validationResults.allSuccessful || rejectionResults.allSuccessful}
                onClick={() => {
                    setValidationResults(resultsInitialState);
                    setRejectionResults(resultsInitialState);
                    props.getTransactions();
                    // todo check how to clear the ui now, this might not work
                    props.clearUI();
                }}
                text={
                    validationResults.allSuccessful
                        ? I18n.t('transaction.successfulModal.translationsValidated')
                        : I18n.t('transaction.successfulModal.translationsRejected')
                }
            />
            <ConfirmationModal
                show={showConfirmRejectionModal}
                icon={<i className='icon-exclamation red' />}
                title={I18n.t('transaction.confirmRejectionModal.text')}
                noBody={true}
                cancel={() => setShowConfirmRejectionModal(false)}
                submit={() => {
                    setShowConfirmRejectionModal(false);
                    rejectSelectedTransactions();
                }}
            />
            <TransactionsErrorModal
                show={validationResults.errorTotal > 0 || rejectionResults.errorTotal > 0}
                errors={
                    validationResults.errorTotal > 0
                        ? validationResults.errors
                        : rejectionResults.errors
                }
                isAValidationError={validationResults.errors.length > 0}
                close={() => {
                    setValidationResults(resultsInitialState);
                    setRejectionResults(resultsInitialState);
                    props.getTransactions();
                    // todo check how to clear the ui now, this might not work
                    props.clearUI();
                }}
                successfulTotal={
                    validationResults.errorTotal > 0
                        ? validationResults.successfulTotal
                        : rejectionResults.successfulTotal
                }
            />
        </div>
    );
};

export default OSTransactionsScreen;
