import React, { useState, useEffect } from 'react';
import './GrantingMonitorScreen.scss';
import SearchBar from '../../../common/components/SearchBar';
import { I18n } from 'react-redux-i18n';
import Loader from '../../../common/components/Loader';
import ReactTable from 'react-table';
import TablePagination from '../../../common/components/TablePagination';
import Button from '../../../common/components/Button';
import Checkbox from '../../../common/components/Checkbox';
import { ConfirmationModal } from '../../../common/components/Modal';
import TransactionsSuccessModal from '../TransactionsSuccessModal';
import TransactionsErrorModal from '../TransactionsErrorModal';
import Swal from 'sweetalert2';
import SelectMultiple from '../../../common/components/SelectMultiple';

const defaultStatusSelected = [
    { id: 'all', checked: true },
    { id: 'granted', checked: true },
    { id: 'notGranted', checked: true },
];

const grantAddressesResultsInitialState = {
    allSuccessful: false,
    successfulTotal: 0,
    errorTotal: 0,
    errors: [],
};

const GrantingMonitorScreen = (props) => {
    const [textToSearch, setTextToSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState(defaultStatusSelected);
    const [addressesSelected, setAddressesSelected] = useState([]);
    const [reactTable, setReactTable] = useState();

    const [grantAddressesResults, setGrantAddressesResults] = useState(
        grantAddressesResultsInitialState,
    );

    const [showConfirmGrantAddressesModal, setShowConfirmGrantAddressesModal] = useState(false);

    useEffect(() => {
        const allAreSelected = allFiltersAreSelected();
        const allAreDeselected = allFiltersAreDeselected();
        const isTheAllOptionChecked = statusFilter.filter((status) => status.id === 'all')[0]
            .checked;

        // this is done to auto check the 'all' checkbox if all the other options are selected (and vise versa)
        if (
            (allAreSelected && !isTheAllOptionChecked) ||
            (allAreDeselected && isTheAllOptionChecked)
        ) {
            setStatusFilter(
                statusFilter.map((status) => {
                    if (status.id === 'all') return { ...status, checked: !isTheAllOptionChecked };
                    return status;
                }),
            );
        }

        const statusSelectedArray = statusFilter
            .filter((status) => status.id !== 'all' && status.checked)
            .map((status) => status.id);
        props.getAddresses({ statusFilter });
        props.updateFilterValues({ status: statusSelectedArray });
    }, [statusFilter]);

    useEffect(() => {
        if (props.grantAddressesStatus.errorMessage) {
            Swal.fire({
                icon: 'error',
                title: I18n.t('grantingMonitor.grantAddressesErrorAlert.title'),
                text: props.grantAddressesStatus.errorMessage,
            }).then((result) => {
                if (result.isConfirmed) {
                    props.getAddresses({ statusFilter });
                }
            });
        } else {
            if (
                props.grantAddressesStatus.success ||
                props.grantAddressesStatus.error ||
                props.grantAddressesStatus.loading
            ) {
                const mappedResults = mapResults(props.grantAddressesResults);
                setGrantAddressesResults(mappedResults);
            }
            if (!props.grantAddressesStatus.loading && props.grantAddressesStatus.success)
                props.getAddresses({ statusFilter });
        }
    }, [props.grantAddressesStatus]);

    /*
     * Maps the transaction payment results (quantity of success, of errors, and the errors strings).
     * */
    const mapResults = (addresses) => {
        let addressesErrored = addresses.filter((a) => a.errors.length !== 0);

        if (addressesErrored.length === 0)
            return { ...grantAddressesResultsInitialState, allSuccessful: true };

        let addressesSuccessfulCount = addresses.length - addressesErrored.length;

        const mapTransactionErrors = (addressId, errors) =>
            errors.map((error) => `ID ${addressId} - ${error.description}`);

        return {
            allSuccessful: false,
            successfulTotal: addressesSuccessfulCount,
            errorTotal: addressesErrored.length,
            errors: addressesErrored.map((a) => mapTransactionErrors(a.id, a.errors)).flat(),
        };
    };

    const allFiltersAreSelected = () => {
        return (
            statusFilter.filter((status) => status.id !== 'all' && status.checked).length ===
            statusFilter.length - 1
        );
    };

    const allFiltersAreDeselected = () => {
        return (
            statusFilter.filter((status) => status.id !== 'all' && !status.checked).length ===
            statusFilter.length - 1
        );
    };

    const handleStatusFilterChange = (statusId) => {
        if (statusId === 'all') {
            const allIsSelected = statusFilter.filter((status) => status.id === 'all')[0].checked;
            setStatusFilter(
                statusFilter.map((status) => ({ id: status.id, checked: !allIsSelected })),
            );
        } else {
            const otherStatus = statusFilter.filter((status) => status.id !== statusId);
            const selectedStatus = statusFilter.filter((status) => status.id === statusId)[0];
            setStatusFilter([...otherStatus, { id: statusId, checked: !selectedStatus?.checked }]);
        }
    };

    const renderCheckBox = (rowData) => {
        return (
            <div className='action-checkbox-container'>
                <Checkbox
                    id={rowData}
                    checked={isCheckboxSelected(rowData)}
                    onChange={handleCheckboxChange}
                    disabled={rowData.granted}
                />
            </div>
        );
    };

    const handleCheckboxChange = (checkboxId, checked) => {
        if (checkboxId === 'ALL') {
            if (checked)
                setAddressesSelected(
                    props.addresses.filter((a) => !a.granted).map((a) => ({ index: a.index })),
                );
            else setAddressesSelected([]);
        } else {
            const filtered = addressesSelected.filter(
                (address) => address.index === checkboxId.index,
            );
            if (checked) {
                // if the checkbox was checked and the checkbox id is not on addressesSelected, add to it
                if (filtered.length === 0)
                    setAddressesSelected([
                        ...addressesSelected,
                        {
                            index: checkboxId.index,
                        },
                    ]);
            } else {
                // if the checkbox was deselected and the checkbox id is on addressesSelected, remove from it
                if (filtered.length === 1)
                    setAddressesSelected([
                        ...addressesSelected.filter(
                            (address) => address.index !== checkboxId.index,
                        ),
                    ]);
            }
        }
    };

    const isCheckboxSelected = (checkboxId) => {
        const filtered = addressesSelected.filter((address) => address.index === checkboxId.index);
        return filtered.length === 1;
    };

    const handleGrantAddressesButtonClick = () => {
        if (addressesSelected.length > 0) {
            const addressesSelectedWithoutAllElement = addressesSelected.filter(function (item) {
                return item !== 'ALL';
            });

            if (addressesSelectedWithoutAllElement.length > 0) {
                let addressesFiltered = [];
                props.addresses.map((address) => {
                    addressesSelectedWithoutAllElement.map((element) => {
                        if (address.index === element.index) addressesFiltered.push(address);
                    });
                });
                props.grantAddresses(addressesFiltered);
                setAddressesSelected([]);
            }
        }
    };

    const getTableColumns = () => {
        // here could be some logic to show certain columns depending on user role.
        return [
            {
                id: 'businessName', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('grantingMonitor.table.headers.businessName')}</h5>,
                accessor: (data) => data.businessName, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'description', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('grantingMonitor.table.headers.description')}</h5>,
                accessor: (data) => data.description, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'address', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('grantingMonitor.table.headers.address')}</h5>,
                accessor: (data) => data.address, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 400,
            },
            {
                id: 'nex', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('grantingMonitor.table.headers.nex')}</h5>,
                accessor: (data) => data, // Custom value accessors!
                Cell: (props) => (
                    <span className='text'>
                        {props.original.status === 'granted' ? parseFloat(props.original.nex) : '-'}
                    </span>
                ),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'jac', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('grantingMonitor.table.headers.jac')}</h5>,
                accessor: (data) => data, // Custom value accessors!
                Cell: (props) => (
                    <span className='text'>
                        {props.original.status === 'granted' ? parseFloat(props.original.jac) : '-'}
                    </span>
                ),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'grantAddresses', // Required because our accessor is not a string
                Header: () => (
                    <div className='header-with-checkbox'>
                        <h5>{I18n.t('grantingMonitor.table.headers.grantAddresses')}</h5>
                        <Checkbox
                            id='ALL'
                            checked={isCheckboxSelected('ALL')}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                ),
                accessor: (data) => data.buttons, // Custom value accessors!
                Cell: (props) => renderCheckBox(props.original),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
        ].filter((column) => (props.userIsGranter ? column : column.id !== 'grantAddresses'));
    };

    return (
        <div className='granting-monitor-screen'>
            <div className='header'>
                <span className='title'>{I18n.t('grantingMonitor.title')}</span>
                <SearchBar
                    textToSearch={textToSearch}
                    onSearch={(text) => {
                        setTextToSearch(text);
                        props.searchAddresses(text);
                    }}
                />
                <SelectMultiple
                    values={statusFilter}
                    onChange={handleStatusFilterChange}
                    className='rounded'
                    placeholder={I18n.t('grantingMonitor.statusLabel')}
                    options={[
                        { value: 'granted', label: I18n.t('grantingMonitor.table.status.granted') },
                        {
                            value: 'notGranted',
                            label: I18n.t('grantingMonitor.table.status.notGranted'),
                        },
                        { value: 'all', label: I18n.t('grantingMonitor.table.status.all') },
                    ]}
                />
                <Button
                    type='button'
                    className='primary small download-button'
                    onClick={props.downloadAddresses}
                    loading={props.downloadStatus.loading}
                    text={I18n.t('grantingMonitor.download')}
                    icon={<i className='icon-cloud-download' />}
                />
                {props.userIsGranter && (
                    <Button
                        type='button'
                        className='primary small'
                        onClick={() => {
                            if (addressesSelected.length > 0)
                                setShowConfirmGrantAddressesModal(true);
                        }}
                        text={I18n.t('grantingMonitor.grantAddresses')}
                    />
                )}
            </div>
            <div className='granting-monitor-table'>
                {props.loading ? (
                    <>
                        <div className='header' />
                        <div className='granting-monitor-table'>
                            <Loader />
                        </div>
                    </>
                ) : (
                    <ReactTable
                        className={props.addresses.length > 0 ? 'nexus-table' : 'nexus-table empty'}
                        PaginationComponent={(paginationProps) => (
                            <TablePagination
                                {...paginationProps}
                                total={props.total}
                                totalPages={props.totalPages}
                                pagedMessageTranslation='grantingMonitor.table.pagedMessage'
                                pagedMessageEmptyTranslation='grantingMonitor.table.pagedMessageEmpty'
                            />
                        )}
                        minRows={0}
                        pageSize={10}
                        data={props.addresses}
                        noDataText={I18n.t('grantingMonitor.table.noRows')}
                        onSortedChange={(newSorted, column, shiftKey) => {
                            props.updateFilterValues({
                                ordering: (newSorted[0].desc ? '-' : '') + column.id,
                            });
                        }}
                        // todo check if this prop is necessary
                        getTdProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                },
                            };
                        }}
                        columns={getTableColumns()}
                        ref={(r) => {
                            setReactTable(r);
                        }}
                    />
                )}
            </div>
            <ConfirmationModal
                show={showConfirmGrantAddressesModal}
                icon={<i className='icon-exclamation red' />}
                title={I18n.t('grantingMonitor.confirmGrantAddressesModal.text')}
                noBody={true}
                cancel={() => setShowConfirmGrantAddressesModal(false)}
                submit={() => {
                    setShowConfirmGrantAddressesModal(false);
                    handleGrantAddressesButtonClick();
                }}
            />
            {/* {props.grantAddressesStatus.loading ?
                <div className={'loader-container'}>
                    <Loader />
                </div> : */}
            <TransactionsSuccessModal
                show={grantAddressesResults.allSuccessful && !props.grantAddressesStatus.error}
                onClick={() => {
                    setGrantAddressesResults(grantAddressesResultsInitialState);
                    props.getAddresses();
                    // todo check how to clear the ui now, this might not work
                    props.clearUI();
                }}
                text={I18n.t('grantingMonitor.successfulModal.addressesGranted')}
                status={props.grantAddressesStatus}
            />
            {/* } */}
            <TransactionsErrorModal
                show={
                    (grantAddressesResults.errorTotal > 0 || props.grantAddressesStatus.error) &&
                    !props.grantAddressesStatus.errorMessage
                }
                errors={grantAddressesResults.errors}
                isAValidationError={false}
                isAGrantAddressesError={true}
                close={() => {
                    setGrantAddressesResults(grantAddressesResultsInitialState);
                    props.getAddresses();
                    // todo check how to clear the ui now, this might not work
                    props.clearUI();
                }}
                successfulTotal={grantAddressesResults.successfulTotal}
            />
        </div>
    );
};

export default GrantingMonitorScreen;
