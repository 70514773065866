import { patch, webApi, get, post, put } from '../utils/http';

export default {
    getContracts: () => get(webApi + `api/contrato`),
    getContract: (id) => get(webApi + `api/contrato/${id}`),
    getContractOs: (id) => get(webApi + `api/paciente/contrato/${id}`),
    putContract: (cont) => put(webApi + `api/contrato/${cont.id}/`, cont),
    postContract: (cont) => post(webApi + `api/contrato/`, cont),

    getAuditorGroups: () => get(webApi + `api/grupoauditor`),

    getFamilyProducts: (prod) => get(webApi + `api/familiaprod/${prod}/productos`),
    getContractTypes: () => get(webApi + `api/tipocontrato`),
};
