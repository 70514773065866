import React from 'react';
import { useState } from 'react';

import TempInput from '../TempInput/TempInput';

import './ListEditor.scss';

const ListEditor = ({
    list,
    setList,

    addMessage,
    defaultElement,

    attributes, //  [ { key, label, i_props{ type, format, validate } } ]
    primaryKey,

    editable,
    isError = false,
    readOnly,
}) => {
    const [popup, setpopup] = useState(false);
    const togglepopup = () => {
        setpopup(!popup);
    };
    const addElement = () => {
        if (list?.length > 0) {
            const last = list[list.length - 1];
            for (const att of attributes) {
                if (att?.i_props?.required && !last[att.key]) return;
            }
        }

        setList([...(list ?? []), defaultElement ?? { [attributes[0].key]: '-' }]);
    };

    const setAtt = (i, obj) => {
        // validate attributes
        for (const key in obj) {
            const validate = attributes.find((a) => a.key === key).i_props.validate;
            if (validate && !validate(obj[key])) return;
        }

        const newList = list.map((el, j) => {
            if (i === j) {
                return { ...el, ...obj };
            }
            return el;
        });
        setList(newList);
    };

    const lastValue = list?.[list.length - 1]?.[primaryKey ?? attributes[0]?.key] ?? '';

    // SET EDITABLE
    const _list = !list?.length
        ? []
        : !editable
        ? list.map((el) => ({ ...el, _LiEd_Editable: false }))
        : editable === 'last'
        ? list.map((el, i) =>
              i === list.length - 1
                  ? { ...el, _LiEd_Editable: true }
                  : { ...el, _LiEd_Editable: false },
          )
        : list;

    let blurEvent = null;

    return (
        <div
            className='listeditor-wrapper'
            tabIndex='0'
            onBlur={(e) => {
                if (blurEvent) return;
                blurEvent = setTimeout(() => {
                    setpopup(false);
                    blurEvent = null;
                }, 100);
            }}
            onFocus={() => {
                if (blurEvent) clearTimeout(blurEvent);
                blurEvent = null;
                setpopup(true);
            }}
        >
            <div
                className={`listeditor-main ${isError ? 'error' : ''}`}
                onClick={() => {
                    togglepopup();
                }}
            >
                {attributes[primaryKey]?.i_props?.format?.(lastValue) ?? lastValue}
            </div>
            {popup && (
                <div className='listeditor-popup'>
                    <table>
                        <thead>
                            <tr>
                                {attributes?.map(({ label }, i) => (
                                    <th key={i}>{label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {_list?.map((item, i) =>
                                item._LiEd_Editable ? ( // READ WRITE ROW
                                    <tr key={i}>
                                        {attributes?.map(({ key, i_props }, j) => (
                                            <td key={j}>
                                                <TempInput
                                                    tempObj={item}
                                                    setAtt={(o) => setAtt(i, o)}
                                                    att={key}
                                                    className='simple-text-input'
                                                    maxDate={new Date()}
                                                    {...i_props}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                ) : (
                                    // READ ONLY ROW
                                    <tr key={i}>
                                        {attributes?.map(({ key, i_props }, j) => (
                                            <td key={j}>
                                                {i_props?.format?.(item[key]) ?? item[key]}
                                            </td>
                                        ))}
                                    </tr>
                                ),
                            )}
                        </tbody>
                    </table>
                    {setList && editable === 'last' && (
                        <>
                            <hr />
                            <div className='card-rlink' onClick={addElement}>
                                {addMessage || '+ Agregar'}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default ListEditor;
