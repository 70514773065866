import Lexer from 'lex';
import { Parser } from './parserImplementation';

export const AND_OPERATOR = '&';
export const OR_OPERATOR = '|';

/** Lexer **/

const lexer = new Lexer();

// skip whitespace
lexer.addRule(/\s+/, function () {});

// match opening parentheses
lexer.addRule(/\(/, function () {
    return '(';
});

// match closing parentheses
lexer.addRule(/\)/, function () {
    return ')';
});

// match words
lexer.addRule(/[^&|()].*?[^&|()]*/, function (lexeme) {
    return lexeme;
});

// match AND and OR special characters
lexer.addRule(/[&|]/, function (lexeme) {
    return lexeme;
});

/** Parser **/

const parser = new Parser({
    '&': {
        precedence: 1,
        associativity: 'right',
    },
    '|': {
        precedence: 0,
        associativity: 'right',
    },
});

export function parseConditionalStatement(string) {
    lexer.setInput(string);

    let tokens = [],
        token;

    while ((token = lexer.lex())) tokens.push(token);
    tokens = parser.parse(tokens);

    let stack = [],
        length = tokens.length,
        index = 0;

    while (index < length) {
        token = tokens[index++];

        switch (token) {
            case AND_OPERATOR:
            case OR_OPERATOR:
                let b = stack.pop();
                let a = stack.pop();
                stack.push([a, token, b]);
                break;
            default:
                stack.push(token);
        }
    }

    return stack.length && stack[0];
}
