import {
    GET_ACCESS,
    DELETE_FMI_CONTACT,
    GET_FMI_OPTIONS,
    GET_MEDIC_OPTIONS,
    GET_OPERATOR_OPTIONS,
    GET_OS_OPTIONS,
    GET_PATIENT_OPTIONS,
    GET_AUDITOR_OPTIONS,
    GET_DRUGSTORE_OPTIONS,
    GET_PATIENT,
    ADD_PATIENT_ACCESS,
    DELETE_PATIENT_ACCESS,
} from './roleAccess.actions';
import service from './roleAccess.services';
import patientService from '../patient/patient.services';
import actions from '../actions';
import { Cipher } from 'jasper-roche-crypto';
import { generateCse } from '../utils/encryption';

const _ = require('lodash');
const parseDoctorsOptions = (response) =>
    response.map((doctor) => ({ value: doctor.id, label: doctor.nombre }));
const parseOsOptions = (response) =>
    response.map((os) => ({ value: os.id, label: os.razon_social, pb: os.pubkey_data }));
const parseFmiOptions = (response) =>
    response.map((op) => ({ value: op.id, label: op.descripcion }));

const parseOsOptionsPatientAcesss = (
    patientAccess,
    list, //filter on the role that patient doesn't have
) =>
    list
        .filter(({ id }) => !patientAccess.some(({ drogueria }) => drogueria === id))
        .map(({ id, razon_social, pubkey_data }) => ({
            value: id,
            label: razon_social,
            pb: pubkey_data,
        }));

const fmiMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);

        switch (action.type) {
            case GET_PATIENT: {
                service
                    .getPatient(action.id)
                    .then((response) => {
                        dispatch(actions.roleAccess.getPatientResponse(response));
                    })
                    .catch((error) => {
                        dispatch(actions.roleAccess.getPatientError(error));
                    });
                break;
            }

            case GET_ACCESS: {
                service
                    .getPatient(action.id)
                    .then((response) =>
                        dispatch(actions.roleAccess.getAccessResponse(response.accesos)),
                    )
                    .catch((error) => dispatch(actions.roleAccess.getAccessError(error)));
                break;
            }
            case DELETE_FMI_CONTACT: {
                const newAccesess = getState().roleAccess.access.filter(
                    (access) => access.id !== action.id,
                );

                patientService
                    .editPatient(getState().patient.editionDetails.id, {
                        ...getState().editionDetails,
                        accesos: newAccesess,
                    })
                    .then((response) => {
                        dispatch(actions.patient.editPatientResponse(response));
                        dispatch(
                            actions.patient.setPatientDetails({
                                ...getState().patient.editionDetails,
                                accesos: newAccesess,
                            }),
                        );
                    })
                    .catch((error) => dispatch(actions.patient.editPatientError(error)));
                break;
            }

            case GET_FMI_OPTIONS: {
                service
                    .getFmiOptions()
                    .then((response) =>
                        dispatch(
                            actions.roleAccess.getRoleOptionsResponse(parseFmiOptions(response)),
                        ),
                    )
                    .catch((error) => dispatch(actions.roleAccess.getRoleOptionsError(error)));
                break;
            }
            case GET_OS_OPTIONS: {
                service
                    .getSocialCoverages()
                    .then((response) =>
                        dispatch(
                            actions.roleAccess.getRoleOptionsResponse(parseOsOptions(response)),
                        ),
                    )
                    .catch((error) => dispatch(actions.roleAccess.getRoleOptionsError(error)));
                break;
            }
            case GET_OPERATOR_OPTIONS: {
                service
                    .getOperators()
                    .then((response) =>
                        dispatch(actions.roleAccess.getRoleOptionsResponse(response)),
                    )
                    .catch((error) => dispatch(actions.roleAccess.getRoleOptionsError(error)));
                break;
            }
            case GET_MEDIC_OPTIONS: {
                service
                    .getDoctors()
                    .then((response) =>
                        dispatch(
                            actions.roleAccess.getRoleOptionsResponse(
                                parseDoctorsOptions(response),
                            ),
                        ),
                    )
                    .catch((error) => dispatch(actions.roleAccess.getRoleOptionsError(error)));
                break;
            }
            case GET_PATIENT_OPTIONS: {
                service
                    .getPatientOptions()
                    .then((response) =>
                        dispatch(actions.roleAccess.getRoleOptionsResponse(response)),
                    )
                    .catch((error) => dispatch(actions.roleAccess.getRoleOptionsError(error)));
                break;
            }
            case GET_AUDITOR_OPTIONS: {
                service
                    .getAuditorOptions()
                    .then((response) =>
                        dispatch(
                            actions.roleAccess.getRoleOptionsResponse(parseOsOptions(response)),
                        ),
                    )
                    .catch((error) => dispatch(actions.roleAccess.getRoleOptionsError(error)));
                break;
            }
            case GET_DRUGSTORE_OPTIONS: {
                service
                    .getDrugstoreOptions()
                    .then((response) =>
                        dispatch(
                            actions.roleAccess.getRoleOptionsResponse(
                                parseOsOptionsPatientAcesss(getState().roleAccess.access, response),
                            ),
                        ),
                    )
                    .catch((error) => dispatch(actions.roleAccess.getRoleOptionsError(error)));
                break;
            }
            case ADD_PATIENT_ACCESS: {
                const { patientId, accessData, patientCse } = action;
                service
                    .addPatientAccess(patientId, encryptAccess(accessData, patientCse, patientId))
                    .then((res) => {
                        dispatch(actions.roleAccess.addPatientAccessResponse(res));
                        dispatch(actions.roleAccess.getAccess(patientId));
                    })
                    .catch((err) => {
                        dispatch(actions.roleAccess.addPatientAccessError(err));
                    });
                break;
            }
            case DELETE_PATIENT_ACCESS: {
                const { accessId, body } = action;
                const patientId = getState().patient.editionDetails?.id || -1;
                service
                    .deletePatientAccess(accessId, body)
                    .then((res) => {
                        dispatch(actions.roleAccess.deletePatientAccessResponse(res));
                        dispatch(actions.roleAccess.getAccess(patientId));
                    })
                    .catch((err) => {
                        dispatch(actions.roleAccess.deletePatientAccessError(err));
                    });
                break;
            }
            default:
                break;
        }
    };

export const encryptAccess = (access, key, patientId) => {
    const cipher = new Cipher();

    return {
        owner: access.owner,
        csee: access.publicKey ? cipher.encrypt(access.publicKey, key) : access.csee,
        grupo_auditor: access?.grupo_auditor || undefined,
        drogueria: access?.drogueria || undefined,
        obra_social: access?.obra_social || undefined,
        tipo_usuario: access?.tipo_usuario || undefined,
        paciente: patientId || undefined,
    };
};

export default fmiMiddleware;
