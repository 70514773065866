import React, { useState } from 'react';
import './AccessTableScreen.scss';
import AccessTableContainer from '../../Containers/AccessTableContainer';
import NewAccessButton from './NewAccess/NewAccessButton';
import NewAccessMonitor from '../../Containers/NewAccessMonitor';
import BackLink from '../../../common/components/BackLink/BackLink';
import TermsAndConditionsModal from '../../../common/components/TermsAndConditionsModal';
import { I18n } from 'react-redux-i18n';
import settings from '../../../settings/settings';

const AccessScreen = ({
    owner,
    access,
    patientCse,
    getAccessStatus,
    addPatientAccessStatus,
    deletePatientAccessStatus,
    addPatientAccess,
    deletePatientAccess,
}) => {
    const [showModal, toggleModal] = useState(false);
    const [showDeleteModal, toggleDeleteModal] = useState(false);

    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

    const [updPatient, setUpdPatient] = useState(0);

    const [id, setId] = useState(0);

    const closeAll = () => {
        toggleModal(false);
        toggleDeleteModal(false);
        setShowTermsAndConditions(false);
    };
    /**
     * use same modal for add and delete
     * access, calculate props
     */
    const getTermsAndConditionsModalProps = () => {
        var result = {
            show: showTermsAndConditions || showDeleteModal,
            onCancel: () => closeAll(),
        };
        if (showTermsAndConditions) {
            result = {
                ...result,
                title: I18n.t('roleAccess.newAccess.TermsAndCondition.title'),
                titleChk: I18n.t('roleAccess.newAccess.TermsAndCondition.authorize'),
                titleRedirect: I18n.t('roleAccess.newAccess.TermsAndCondition.titlePdf'),
                loading: addPatientAccessStatus.loading,
                redirect: openDroguserTermsAndConditionsPathNew,
                onSubmit: () => {
                    const { patientId, ...body } = updPatient;
                    addPatientAccess(patientId, body, patientCse);
                    closeAll();
                },
            };
        } else if (showDeleteModal) {
            result = {
                ...result,
                title: I18n.t('roleAccess.deleteAccess.TermsAndCondition.title'),
                titleChk: I18n.t('roleAccess.deleteAccess.TermsAndCondition.authorize'),
                titleRedirect: I18n.t('roleAccess.deleteAccess.TermsAndCondition.titlePdf'),
                loading: deletePatientAccessStatus.loading,
                redirect: openDroguserTermsAndConditionsPathDelete,
                onSubmit: () => {
                    const { accessId, ...body } = updPatient;
                    deletePatientAccess(accessId, body);
                    closeAll();
                },
            };
        }
        return result;
    };

    return (
        <div className='access-table-screen-container'>
            <div style={{ width: '85%', marginTop: '20px' }}>
                <BackLink to='edit' />
            </div>
            <div className='users-table-card'>
                <AccessTableContainer
                    openDeleteModal={(data) => {
                        setUpdPatient(data);
                        toggleDeleteModal(true);
                    }}
                    setId={(id) => setId(id)}
                />
                {owner && access.length > 0 && (
                    <NewAccessButton
                        openModal={() => toggleModal(true)}
                        disabled={access.some((r) => r.tipo_rol === 'Drogueria')}
                        loading={getAccessStatus.loading}
                    />
                )}
            </div>
            <NewAccessMonitor
                show={showModal}
                close={() => toggleModal(false)}
                updPatientWithNewAccess={() => null}
                onNewAccess={(newAccess) => {
                    setUpdPatient(newAccess);
                    setShowTermsAndConditions(true);
                    toggleModal(false);
                }}
            />
            <TermsAndConditionsModal {...getTermsAndConditionsModalProps()} />
        </div>
    );
};

const openDroguserTermsAndConditionsPathNew = () => {
    window.open(settings.droguserTermsAndConditionsPathNew, '_blank');
};

const openDroguserTermsAndConditionsPathDelete = () => {
    window.open(settings.droguserTermsAndConditionsPathDelete, '_blank');
};

export default AccessScreen;
