import React from 'react';
import './Modal.scss';
import PropTypes from 'prop-types';
import Button from '../Button';
import { I18n } from 'react-redux-i18n';
import TextArea from '../TextArea/TextArea';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../Loader';
import { success } from '../../../utils/models';

/*
 * This is a simple 'empty' modal.
 * */
export const SimpleModal = (props) => {
    return (
        <div
            className={`modal ${props.show && 'show'} ${props.noZindex && 'no-zindex'}`}
            onClick={() => props.close && props?.close()}
        >
            <div className={`content ${props.className ? props.className : ''}`}>
                {props.children}
            </div>
        </div>
    );
};

/*
 * This is a modal with a header and a footer.
 * The header is composed by: An icon, and a title.
 * The footer is a composed by 2 buttons: "confirm" and "cancel"
 * */
export const ConfirmationModal = (props) => {
    // modal with header and footer or "confirm modal"
    return (
        <div
            className={`modal ${props.show && 'show'}`}
            onClick={() => props.close && props?.close()}
        >
            <div className='content'>
                <div className='header'>
                    {props.icon}
                    <span className={`title ${props.titleClassname ?? ''}`}>{props.title}</span>
                </div>
                {!props.noBody && <div className='separator-line' />}
                {!props.noBody ? <div className='body'>{props.children}</div> : null}
                <div className='footer'>
                    <Button
                        type='button'
                        onClick={props.submit}
                        text={props.submitText || I18n.t('patient.newPatient.confirm')}
                        className='primary'
                        disabled={props.disableSubmitButton}
                        loading={props.loading}
                    />
                    <Button
                        type='button'
                        onClick={props.cancel}
                        text={props.cancelText || I18n.t('patient.newPatient.cancel')}
                        className='error'
                    />
                </div>
            </div>
        </div>
    );
};

export const ConfirmationModalWithText = (props) => {
    return (
        props.show && (
            <div className='modal-backdrop' onClick={props.close}>
                <div className={`modal ${props.show && 'show'}`}>
                    <div
                        className='content'
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <div className='header'>
                            {props.icon}
                            <span className='title'>{props.title}</span>
                        </div>

                        <div
                            key='input-label'
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                            <label className='input-label big'>
                                {I18n.t('auditor.incident.comment')}
                            </label>
                        </div>
                        <div>
                            {!props.noBody && (
                                <div className='modal-body-label'>{props.children}</div>
                            )}
                        </div>
                        <TextArea
                            value={props.comment}
                            onChange={(e) => {
                                props.setComment(e.target.value);
                            }}
                        />
                        <div className='footer'>
                            <Button
                                type='button'
                                onClick={props.submit}
                                text={props.submitText || I18n.t('patient.newPatient.confirm')}
                                className='primary small'
                                disabled={props.disableSubmitButton}
                                loading={props.loading}
                            />
                            <Button
                                type='button'
                                onClick={props.cancel}
                                text={props.cancelText || I18n.t('patient.newPatient.cancel')}
                                className='error small'
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};
export const StatusModal = ({
    show,
    close,
    status,
    setShowErrors,
    successMessage,
    successPathname,
    errorPathname,
}) => {
    const history = useHistory();

    return (
        <SimpleModal show={show} className='IndStatusModal'>
            {show?.message ? (
                <div>{show.message}</div>
            ) : (
                <>
                    {status?.loading && <Loader />}
                    {status?.error && (
                        <div>
                            {' '}
                            {status?.message
                                ? status?.message.toString()
                                : I18n.t('errors.generic')}{' '}
                        </div>
                    )}
                    {status?.success && <div> {successMessage ?? I18n.t('success.load')} </div>}
                </>
            )}
            <br />
            <Button
                type='button'
                className='primary small'
                text='Ok'
                onClick={() => {
                    close();
                    setShowErrors && setShowErrors(status?.error);
                    if (status?.success) {
                        successPathname && history.push(`${successPathname}`);
                    } else if (status?.error) {
                        errorPathname && history.push(`${errorPathname}`);
                    }
                }}
            />
        </SimpleModal>
    );
};

SimpleModal.propTypes = {
    show: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

StatusModal.propTypes = {
    show: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    status: PropTypes.object.isRequired,
    setShowErrors: PropTypes.func,
    successMessage: PropTypes.string,
    successPathname: PropTypes.string,
    errorPathname: PropTypes.string,
};

ConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    icon: PropTypes.element,
    title: PropTypes.string.isRequired,
    titleClassname: PropTypes.string,
    submit: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    submitText: PropTypes.string,
    cancelText: PropTypes.string,
    disableSubmitButton: PropTypes.bool,
    loading: PropTypes.bool,
    noBody: PropTypes.bool,
    children: PropTypes.element,
};
