import React from 'react';
import './AuditHistoryTable.scss';
import { I18n } from 'react-redux-i18n';
import Card from '../../../../../common/components/Card/Card';
import ReactTable from 'react-table';
import TablePagination from '../../../../../common/components/TablePagination';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { parseDate } from '../../../../../utils';

// Enum de estados
const auditStates = [
    { value: 'Q', label: 'auditRequired' },
    { value: 'P', label: 'auditPending' },
    { value: 'R', label: 'auditRejected' },
    { value: 'A', label: 'auditApproved' },
    { value: 'C', label: 'auditConsumptionRejected' },
    { value: 'B', label: 'cleanedEvidence' },
    { value: 'E', label: 'endSuccessfulTreatment' },
    { value: 'F', label: 'endTreatmentProgression' },
    { value: '#', label: 'noAuditor' },
];

// Enum de grupo de auditores
const auditGroup = [{ value: '1', label: 'Grupo Auditores S.A.' }];

const AuditHistoryTable = ({ patientHistorial, patientDetail }) => {
    // Function que formatea la columna de grupo de auditores
    const formatAuditGroup = (value) => {
        const [new_value] = auditGroup.filter((group) => group.value === `${value}`);
        return <span className='text transactions-table-row'>{new_value?.label}</span>;
    };

    // Function que formatea la columna de estado
    const renderStateColumn = (value) => {
        const [new_value] = auditStates.filter((state) => state.value === value);
        return (
            <span
                className={`transactions-table-row ${new_value?.label}`}
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
            >
                {I18n.t(`transaction.table.status.${new_value?.label}`)}
            </span>
        );
    };

    // Function que agrega la paginacion
    const renderTableFooter = (paginationProps) => (
        <div className='transaction-table-footer' style={{ padding: '1rem 3rem' }}>
            <TablePagination
                {...paginationProps}
                total={patientHistorial?.length}
                totalPages={patientHistorial?.length / 10}
                pagedMessageTranslation='transaction.table.pagedMessage'
                pagedMessageEmptyTranslation='transaction.table.pagedMessageEmpty'
            />
        </div>
    );

    const infoModal = () => {
        return (
            <>
                <h5>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{
                            color: '#008e99',
                            fontSize: '2.25rem',
                            margin: '0 1.75rem',
                        }}
                    />
                    {I18n.t('transaction.table.status.definition')}
                </h5>
                <hr />
                <ul className='list approved'>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditPending')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditApproved')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditRejected')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditConsumptionRejected')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.cleanedEvidence')}</h5>
                    </li>
                </ul>
            </>
        );
    };

    const formatHeader = (text, id, infoIcon = false, modal) => {
        return (
            <div className='nexus-header-arrows space-between'>
                <h5>
                    {text}&nbsp;
                    {infoIcon && <FontAwesomeIcon data-tip data-for={id} icon={faInfoCircle} />}
                </h5>
                <ReactTooltip
                    id={id}
                    place='bottom'
                    type='light'
                    effect='solid'
                    className='info-modal'
                >
                    <>{modal}</>
                </ReactTooltip>
                <div className='arrows'>
                    <span>▲</span>
                    <span>▼</span>
                </div>
            </div>
        );
    };

    const getTableColumns = () => {
        // here could be some logic to show certain columns depending on user role.
        return [
            {
                id: 'numero', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('auditor.auditHistoryTable.number')}</h5>,
                accessor: (d) => d.id, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 100,
            },
            {
                id: 'fecha', // Required because our accessor is not a string
                Header: () => formatHeader(I18n.t('auditor.auditHistoryTable.date'), 'header_date'),
                accessor: (d) => d?.fecha, // Custom value accessors!
                Cell: (props) => (
                    <span className='text transactions-table-row'>
                        {parseDate(new Date(patientDetail.created_at))}
                    </span>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 150,
            },
            {
                id: 'droga', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('auditor.auditHistoryTable.object'), 'header_drug'),
                accessor: (d) => d?.droga, // Custom value accessors!
                Cell: (props) => (
                    <span className='text transactions-table-row'>
                        {patientDetail.producto_descr}
                    </span>
                ),
                headerClassName: 'nexus-header space-between',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'registrador', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(
                        I18n.t('auditor.auditHistoryTable.registrator'),
                        'header_register',
                    ),
                accessor: (d) => d?.registrator, // Custom value accessors!
                Cell: (props) => (
                    <span className='text transactions-table-row'>
                        {patientDetail.obra_social.razon_social}
                    </span>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'auditor', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('auditor.auditHistoryTable.auditor'), 'header_auditor'),
                accessor: (d) => d?.usuario_auditor, // Custom value accessors
                Cell: (props) => (
                    <span className='text transactions-table-row'>
                        {patientDetail.grupo_auditor_descr}
                    </span>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'estado', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(
                        I18n.t('auditor.auditHistoryTable.status'),
                        'header_state',
                        true,
                        infoModal(),
                    ),
                accessor: (d) => d?.estado, // Custom value accessors!
                Cell: (props) => renderStateColumn(props.value),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'comment', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('auditor.auditHistoryTable.comment'), 'header_comment'),
                accessor: (d) => d?.comentario_enc, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
        ];
    };

    return (
        <Card
            title={I18n.t('auditor.auditHistoryTable.title')}
            classHead='card-head-mod'
            classBody='no-padding'
        >
            <div className='table-container'>
                <ReactTable
                    //className={(props.transactions.length > 0 ? "nexus-table"  : "nexus-table empty")}
                    className='nexus-table'
                    PaginationComponent={(paginationProps) => renderTableFooter(paginationProps)}
                    minRows={0}
                    pageSize={10}
                    data={patientHistorial}
                    noDataText={I18n.t('transaction.table.noRows')}
                    columns={getTableColumns()}
                    defaultSorted={[{ id: 'dates', desc: true }]}
                />
            </div>
        </Card>
    );
};

export default AuditHistoryTable;
