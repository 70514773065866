import { connect } from 'react-redux';
import actions from '../../actions';
import LoginScreen from '../components/LoginScreen/LoginScreen';

const mapStateToProps = (state, ownProps) => ({
    loginStatus: state.session.loginStatus,
    isLoggedIn: state.session.isLoggedIn,
});

// Flag to switch to normal login without migration
// login: (email, password) => process.env?.REACT_APP_MIGRATE_USERS === 'true'
//     ? dispatch(actions.session.loginMigrationRequest(email, password))
//     : dispatch(actions.session.loginRequest(email, password))
const mapDispatchToProps = (dispatch) => ({
    login: (email, password) => dispatch(actions.session.loginMigrationRequest(email, password)),
    refreshAllLists: () => dispatch(actions.common.refreshAllLists()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
