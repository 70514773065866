import {
    REGISTRAR_SIGN_UP,
    REGISTRAR_SIGN_UP_RESPONSE,
    REGISTRAR_SIGN_UP_ERROR,
    GET_INSTITUTION_BY_CUIT,
    GET_INSTITUTION_BY_CUIT_RESPONSE,
    GET_INSTITUTION_BY_CUIT_ERROR,
    CREATE_INSTITUTION_AND_BRANCH,
    CREATE_INSTITUTION_AND_BRANCH_RESPONSE,
    CREATE_INSTITUTION_AND_BRANCH_ERROR,
    CREATE_BRANCH,
    CREATE_BRANCH_RESPONSE,
    CREATE_BRANCH_ERROR,
    RESET_REGISTRAR_UI,
    SELECT_BRANCH,
    CREATE_REGISTRAR,
    CREATE_REGISTRAR_RESPONSE,
    CREATE_REGISTRAR_ERROR,
    ACTIVATE_REGISTRAR_ACCOUNT,
    ACTIVATE_REGISTRAR_ACCOUNT_RESPONSE,
    ACTIVATE_REGISTRAR_ACCOUNT_ERROR,
} from './registrar.actions';
import { LOGIN_RESPONSE, LOGOUT } from '../session/session.actions';
import { REHYDRATE } from 'redux-persist';
import { SAVE_KEYS_RESPONSE } from '../profile/profile.actions';

const initialState = {
    signUpEmailSendTo: undefined, // the email where the confirmation mail was sent. (registrar sign up)
    institution: undefined,
    /*
     * the difference between branch and branches is that 'branches' are all the branches in the institution, while
     * 'branch' is the selected branch (or the created one).
     * */
    branch: undefined,
    branches: [],
    getInstitutionByCuitStatus: { error: false, loading: false, success: false, errorMessage: '' },
    registrarSignUpStatus: { error: false, loading: false, success: false, errorMessage: '' },
    createBranchStatus: { error: false, loading: false, success: false, errorMessage: '' },
    createInstitutionAndBranchStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    createRegistrarStatus: { error: false, loading: false, success: false, errorMessage: '' },
    activateRegistrarAccountStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
};

const registrarReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_RESPONSE:
            return {
                ...state,
                branch: action.response.branch,
                institution: action.response.institution,
            };
        case REGISTRAR_SIGN_UP:
            return {
                ...state,
                registrarSignUpStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case REGISTRAR_SIGN_UP_RESPONSE:
            return {
                ...state,
                signUpEmailSendTo: action.response.email,
                registrarSignUpStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case REGISTRAR_SIGN_UP_ERROR:
            return {
                ...state,
                registrarSignUpStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case GET_INSTITUTION_BY_CUIT:
            return {
                ...state,
                getInstitutionByCuitStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case GET_INSTITUTION_BY_CUIT_RESPONSE:
            return {
                ...state,
                institution: action.response.institution,
                branches: action.response.branches,
                getInstitutionByCuitStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case GET_INSTITUTION_BY_CUIT_ERROR:
            return {
                ...state,
                getInstitutionByCuitStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case CREATE_BRANCH:
            return {
                ...state,
                createBranchStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case CREATE_BRANCH_RESPONSE:
            return {
                ...state,
                branch: action.response.branch,
                createBranchStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case CREATE_BRANCH_ERROR:
            return {
                ...state,
                createBranchStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case CREATE_INSTITUTION_AND_BRANCH:
            return {
                ...state,
                createInstitutionAndBranchStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case CREATE_INSTITUTION_AND_BRANCH_RESPONSE:
            return {
                ...state,
                institution: action.response.institution,
                branch: action.response.branch,
                createInstitutionAndBranchStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case CREATE_INSTITUTION_AND_BRANCH_ERROR:
            return {
                ...state,
                createInstitutionAndBranchStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case SELECT_BRANCH:
            return {
                ...state,
                branch: action.branch,
            };
        case CREATE_REGISTRAR:
            return {
                ...state,
                createRegistrarStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case CREATE_REGISTRAR_RESPONSE:
            return {
                ...state,
                institution: action.response.institution,
                branch: action.response.branch,
                createRegistrarStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case CREATE_REGISTRAR_ERROR:
            return {
                ...state,
                createRegistrarStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case ACTIVATE_REGISTRAR_ACCOUNT:
            return {
                ...state,
                activateRegistrarAccountStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case ACTIVATE_REGISTRAR_ACCOUNT_RESPONSE:
            return {
                ...state,
                activateRegistrarAccountStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case ACTIVATE_REGISTRAR_ACCOUNT_ERROR:
            return {
                ...state,
                activateRegistrarAccountStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case SAVE_KEYS_RESPONSE: {
            return {
                ...state,
                branch: {
                    ...state.branch,
                    publicKey: action.response.publicKey,
                },
            };
        }
        case RESET_REGISTRAR_UI:
            return {
                ...state,
                registrarSignUpStatus: initialState.registrarSignUpStatus,
                getInstitutionByCuitStatus: initialState.getInstitutionByCuitStatus,
                createBranchStatus: initialState.createBranchStatus,
                createInstitutionAndBranchStatus: initialState.createInstitutionAndBranchStatus,
                createRegistrarStatus: initialState.createRegistrarStatus,
            };
        case LOGOUT:
            return { ...initialState };
        case REHYDRATE:
            let incoming = action.payload;
            if (incoming) {
                return {
                    ...incoming.registrar,
                    registrarSignUpStatus: initialState.registrarSignUpStatus,
                    getInstitutionByCuitStatus: initialState.getInstitutionByCuitStatus,
                    createBranchStatus: initialState.createBranchStatus,
                    createInstitutionAndBranchStatus: initialState.createInstitutionAndBranchStatus,
                    createRegistrarStatus: initialState.createRegistrarStatus,
                    activateRegistrarAccountStatus: initialState.activateRegistrarAccountStatus,
                };
            } else {
                return state;
            }
        default:
            return state;
    }
};

export default registrarReducer;
