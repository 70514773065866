import { connect } from 'react-redux';
import GrantingMonitorScreen from '../components/GrantingMonitorScreen';
import actions from '../../actions';
import TransactionMonitorScreen from '../components/TransactionMonitorScreen';

const mapStateToProps = (state) => ({
    transactions: state.transaction.transactionMonitorFilteredResults,
    total: state.transaction.transactionMonitorFilteredResults.length,
    totalPages: Math.ceil(state.transaction.transactionMonitorFilteredResults.length / 10),
    currentPage: state.transaction.transactionMonitorCurrent,

    allTransactions: state.transaction.transactionMonitorResults,
    filteredAddressesBySearch: state.transaction.grantingMonitorFilteredResultsBySearch,

    loading: state.transaction.getTransactionMonitorTransactionsStatus.loading,
    sendDataToSAPStatus: state.transaction.resendDataToSAPStatus,
    sendDataToSAPResults: state.transaction.lastSendDataToSAPResults,
    downloadStatus: state.transaction.downloadGrantingMonitorAddressesStatus,
    userIsGranter: state.profile.user.groups?.includes('granters'),
});

const mapDispatchToProps = (dispatch) => ({
    getTransactions: (filter) =>
        dispatch(actions.transaction.getTransactionMonitorTransactions(filter)),

    updateFilterValues: (filter) =>
        dispatch(actions.transaction.updateTransactionMonitorFilterValues(filter)),

    updatePage: (pageNumber) =>
        dispatch(actions.transaction.updateTransactionMonitorPage(pageNumber)),
    updatePageSize: (pageSize) =>
        dispatch(actions.transaction.updateTransactionMonitorPageSize(pageSize)),

    searchTransactions: (search) =>
        dispatch(actions.transaction.searchTransactionMonitorTransactions(search)),

    resendDataToSAP: (transactions) =>
        dispatch(actions.transaction.sendDataToSAP(transactions, true)),

    downloadAddresses: () => dispatch(actions.transaction.downloadGrantingMonitorAddresses()),

    clearUI: () => dispatch(actions.transaction.clearUI()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionMonitorScreen);
