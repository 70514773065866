import React, { useState } from 'react';
import TextArea from '../../../../common/components/TextArea/TextArea';
import Button from '../../../../common/components/Button';
import './MintBurnNex.scss';
import { I18n } from 'react-redux-i18n';
import Input from '../../../../common/components/Input';

const MintBurnNex = ({ action, handleClick, status, activeAction, disableBtn }) => {
    const [value, setValue] = useState('');

    const sendImport = (action) => {
        handleClick(action, value);
        setValue('');
    };
    return (
        <div className='body-min-burn'>
            <div className='wrapper-child'>
                <span className='mint-burn-title'>
                    {action === 'Mint'
                        ? I18n.t('wallet.monetaryBase.mint')
                        : I18n.t('wallet.monetaryBase.burn')}
                </span>
            </div>
            <div className='wrapper-child'>
                <Input
                    type='number'
                    value={value}
                    handleChange={(e) => setValue(e)}
                    className='text-area-wrapper'
                    inputClassname='text-area-resize'
                />
            </div>

            <div className='wrapper-child'>
                <Button
                    className={`tiny ${
                        action === 'Mint' ? ' primary with-gradient no-shadow' : 'decline'
                    }`}
                    text={
                        action === 'Mint'
                            ? I18n.t('wallet.monetaryBase.emit')
                            : I18n.t('wallet.monetaryBase.remove')
                    }
                    onClick={() => sendImport(action)}
                    loading={status?.loading && activeAction === action}
                    disabled={disableBtn}
                />
            </div>
        </div>
    );
};
export default MintBurnNex;
