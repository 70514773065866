import React, { Component } from 'react';
import './SideBarScreen.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { I18n } from 'react-redux-i18n';
import { UserTypes } from '../../../constants';
import actions from '../../../actions';
import helpCycle from '../../../assets/icons/help-circle.svg';
import settings from '../../../settings/settings';
import WalletNavBar from '../Icon/WalletNavBar';
import { CUSTOM_USER_GROUPS } from './const';

class SideBarScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hamburgerExpanded: false,
        };
    }

    render() {
        const currentLocation = (link) => this.props.location.pathname === link;

        const createItemsBasedOnUserType = () => {
            switch (this.props.userType) {
                case UserTypes.OS_USER: {
                    let walletSideBar = [
                        {
                            key: 'patients',
                            text: I18n.t('sidebar.abmPatients'),
                            link: '/patients',
                            icon: (
                                <i className='icon-people'>
                                    {this.props.notifications?.pacientes >= 1 && (
                                        <div className='ic-notif'>
                                            {this.props.notifications.pacientes}
                                        </div>
                                    )}
                                </i>
                            ),
                        },
                        {
                            key: 'transactions',
                            text: I18n.t('sidebar.transactions'),
                            link: '/transactions',
                            icon: (
                                <i className='icon-list'>
                                    {this.props.notifications?.transacciones >= 1 && (
                                        <div className='ic-notif'>
                                            {this.props.notifications.transacciones}
                                        </div>
                                    )}
                                </i>
                            ),
                        },
                    ];
                    if (this.props.userGroups?.includes('registradores')) {
                        walletSideBar.push(
                            {
                                key: 'registration',
                                text: I18n.t('sidebar.registration'),
                                link: '/registration',
                                icon: <i className='icon-note' />,
                            },
                            {
                                key: 'OSAuditTransactions',
                                text: I18n.t('sidebar.audit'),
                                link: '/auditTransactions',
                                icon: (
                                    <i className='icon-magnifier'>
                                        {this.props.notifications?.auditorias >= 1 && (
                                            <div className='ic-notif'>
                                                {this.props.notifications.auditorias}
                                            </div>
                                        )}
                                    </i>
                                ),
                            },
                        );
                    }
                    if (this.props.userGroups?.includes('wallet') && !this.props.isDirectPayment) {
                        walletSideBar.push({
                            key: 'walletHomeMonitor',
                            text: I18n.t('sidebar.wallet'),
                            link: '/wallet',
                            icon: <WalletNavBar />,
                        });
                    }
                    return walletSideBar;
                }
                case UserTypes.REGISTER_USER:
                    return [
                        {
                            key: 'registration',
                            text: I18n.t('sidebar.registration'),
                            link: '/registration',
                            icon: <i className='icon-note' />,
                        },
                    ];
                case UserTypes.ROCHE_USER:
                case UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY: {
                    let constantSideBar = [
                        {
                            key: 'rocheTransactions',
                            text: I18n.t('sidebar.transactions'),
                            link: '/rocheTransactions',
                            icon: (
                                <i className='icon-list'>
                                    {this.props.notifications?.transacciones >= 1 && (
                                        <div className='ic-notif'>
                                            {this.props.notifications.transacciones}
                                        </div>
                                    )}
                                </i>
                            ),
                        },
                        {
                            key: 'nexMonitor',
                            text: I18n.t('sidebar.nexMonitor'),
                            link: '/nexMonitor',
                            icon: (
                                <i className='icon-docs'>
                                    {this.props.notifications?.tokens >= 1 && (
                                        <div className='ic-notif'>
                                            {this.props.notifications.tokens}
                                        </div>
                                    )}
                                </i>
                            ),
                        },
                        {
                            key: 'bonifications',
                            text: I18n.t('sidebar.bonifications'),
                            link: '/bonifications',
                            // icon: <i className='icon-plus' />,
                            icon: (
                                <i className='icon-plus'>
                                    {this.props.notifications?.bonificaciones >= 1 && (
                                        <div className='ic-notif'>
                                            {this.props.notifications.bonificaciones}
                                        </div>
                                    )}
                                </i>
                            ),
                        },
                        {
                            key: 'indications',
                            text: I18n.t('sidebar.indications'),
                            link: '/indications/list',
                            icon: <i className='icon-screen-tablet' />,
                        },
                        {
                            key: 'contracts',
                            text: I18n.t('sidebar.contracts'),
                            link: '/contracts/list',
                            icon: <i className='icon-screen-tablet' />,
                        },
                    ];
                    if (
                        this.props.userGroups?.includes('granters') ||
                        this.props.userGroups?.includes('pagadores')
                    ) {
                        constantSideBar.push({
                            key: 'transactionMonitor',
                            text: I18n.t('sidebar.transactionMonitor'),
                            link: '/transactionsMonitor',
                            icon: <i className='icon-equalizer' />,
                        });
                    }
                    if (
                        this.props.userGroups?.includes('granters') &&
                        this.props.userGroups?.includes('whitelister')
                    ) {
                        constantSideBar.push({
                            key: 'grantingMonitor',
                            text: I18n.t('sidebar.grantingMonitor'),
                            link: '/grantingMonitor',
                            icon: <i className='icon-like' />,
                        });
                    }
                    if (this.props.userGroups?.includes('medical_expert')) {
                        constantSideBar.push({
                            key: 'sharedRisk',
                            text: I18n.t('sidebar.sharedRisk'),
                            link: '/sharedRisk',
                            icon: <i className='icon-screen-tablet' />,
                        });
                    }
                    if (this.props.userGroups?.includes('nex_owner')) {
                        constantSideBar.push({
                            key: 'walletMonetaryBase',
                            text: I18n.t('sidebar.monetaryBase'),
                            link: '/wallet/monetaryBase',
                            icon: <WalletNavBar color='#afb3ba' />,
                        });
                    }
                    return constantSideBar;
                }
                case UserTypes.AUDITOR:
                    return [
                        {
                            key: 'auditorTransactions',
                            text: I18n.t('sidebar.transactions'),
                            link: '/auditorTransactions',
                            icon: (
                                <i className='icon-layers'>
                                    {this.props.notifications?.auditorias >= 1 && (
                                        <div className='ic-notif'>
                                            {this.props.notifications.auditorias}
                                        </div>
                                    )}
                                </i>
                            ),
                        },
                        {
                            key: 'auditorGuide',
                            text: I18n.t('sidebar.help'),
                            link: '/auditorTransactions',
                            icon: (
                                <i
                                    className='help-button-container'
                                    onClick={() =>
                                        window.open(settings.auditorTutorialPath, '_blank')
                                    }
                                >
                                    <img alt='Ultimo' height='40px' src={helpCycle} />
                                </i>
                            ),
                        },
                    ];
                case UserTypes.DRUGSTORE: {
                    let drugstoreSideBar = [];
                    if (this.props.userGroups?.includes(CUSTOM_USER_GROUPS.HAS_ANY_PATIENT)) {
                        drugstoreSideBar.push(
                            {
                                key: 'patients',
                                text: I18n.t('sidebar.abmPatients'),
                                link: '/patients',
                                icon: (
                                    <i className='icon-people'>
                                        {this.props.notifications?.pacientes >= 1 && (
                                            <div className='ic-notif'>
                                                {this.props.notifications.pacientes}
                                            </div>
                                        )}
                                    </i>
                                ),
                            },
                            {
                                key: 'transactions',
                                text: I18n.t('sidebar.transactions'),
                                link: '/transactions',
                                icon: (
                                    <i className='icon-list'>
                                        {this.props.notifications?.transacciones >= 1 && (
                                            <div className='ic-notif'>
                                                {this.props.notifications.transacciones}
                                            </div>
                                        )}
                                    </i>
                                ),
                            },
                        );
                        if (this.props.userGroups?.includes('registradores')) {
                            drugstoreSideBar.push(
                                {
                                    key: 'registration',
                                    text: I18n.t('sidebar.registration'),
                                    link: '/registration',
                                    icon: <i className='icon-note' />,
                                },
                                {
                                    key: 'OSAuditTransactions',
                                    text: I18n.t('sidebar.audit'),
                                    link: '/auditTransactions',
                                    icon: (
                                        <i className='icon-magnifier'>
                                            {this.props.notifications?.auditorias >= 1 && (
                                                <div className='ic-notif'>
                                                    {this.props.notifications.auditorias}
                                                </div>
                                            )}
                                        </i>
                                    ),
                                },
                            );
                        }
                    }
                    if (this.props.userGroups?.includes('wallet')) {
                        drugstoreSideBar.push({
                            key: 'walletHomeMonitor',
                            text: I18n.t('sidebar.wallet'),
                            link: '/wallet',
                            icon: <WalletNavBar />,
                        });
                    }
                    return drugstoreSideBar;
                }
                default:
                    return [];
            }
        };

        const items = createItemsBasedOnUserType();

        const listItems = items.map((item, i) => (
            <li key={i}>
                <Link
                    to={item.link}
                    style={{ textDecoration: 'none' }}
                    onClick={() => this.props.getNotifications()}
                >
                    <div data-tip={item.text} data-for='sidebarTooltip'>
                        <div className='sidebar-item'>
                            <div
                                className={`indicator ${currentLocation(item.link) ? 'show' : ''}`}
                            />
                            <div className={`icon ${currentLocation(item.link) ? 'selected' : ''}`}>
                                {item.icon}
                            </div>
                        </div>
                    </div>
                    <ReactTooltip
                        id='sidebarTooltip'
                        place='right'
                        backgroundColor='white'
                        effect='solid'
                        className='sidebar-tooltip'
                    />
                </Link>
            </li>
        ));

        // If the user is of type "USER" (basic django user), avoid showing the sidebar.
        if (this.props.userType === UserTypes.USER) return null;

        return [
            <div
                key='hamburger'
                className='hamburger'
                style={!this.props.sidebarOpen ? { display: 'none' } : null}
            >
                {this.props.sidebarOpen && (
                    <div className='sidebar-hb'>
                        <ul className='sidebar-list'>{listItems}</ul>
                    </div>
                )}
            </div>,
            <div key='sidebar' className='sidebar'>
                <ul className='sidebar-list'>{listItems}</ul>
            </div>,
        ];
    }
}

SideBarScreen.defaultProps = {};
SideBarScreen.propTypes = {};

const mapStateToProps = (state) => ({
    sidebarOpen: state.common.sidebarOpen,
    userType: state.profile.user.type,
    userGroups: state.profile.user.groups,
    auditorTransactionsNotifications: state.transaction.auditorTransactionsNotifications,
    notifications: state.session.notifications,
    isWhiteLister: state.transaction.isWhiteLister,
    isDirectPayment: state.session.directPayment,
});

const mapDispatchToProps = (dispatch) => ({
    getNotifications: () => dispatch(actions.session.getNotifications()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBarScreen));
